{
  "name": "fitogest",
  "version": "19.3.0",
  "license": "UNLICENSED",
  "scripts": {
    "compile": "node buildWithInput.mjs",
    "start": "ng serve Fitogest",
    "start-fichajes": "ng serve Fichajes",
    "build-fichajes": "ng build Fichajes --base-href /proyectos/fitogest/fichajes/",
    "angular-update": "ng update @angular/core@19 @angular/cli@19 --allow-dirty --force",
    "lint": "ng lint",
    "test": "jest",
    "test:watch": "jest --watch",
    "test:ci": "jest --runInBand",
    "e2e": "ng e2e",
    "bundle": "npm pack",
    "compodoc": "npx compodoc -p src/tsconfig.app.json && compodoc src -s",
    "ng": "ng"
  },
  "dependencies": {
    "@angular/animations": "19.1.0",
    "@angular/cdk": "19.1.0",
    "@angular/common": "19.1.0",
    "@angular/compiler": "19.1.0",
    "@angular/core": "19.1.0",
    "@angular/forms": "19.1.0",
    "@angular/localize": "19.1.0",
    "@angular/platform-browser": "19.1.0",
    "@angular/platform-browser-dynamic": "19.1.0",
    "@angular/pwa": "19.1.0",
    "@angular/router": "19.1.0",
    "@angular/service-worker": "19.1.0",
    "@babel/core": "7.26.0",
    "@fullcalendar/angular": "6.1.16",
    "@fullcalendar/core": "6.1.15",
    "@fullcalendar/daygrid": "6.1.15",
    "@fullcalendar/interaction": "6.1.15",
    "@fullcalendar/timegrid": "6.1.15",
    "@google/maps": "1.1.3",
    "@ng-bootstrap/ng-bootstrap": "18.0.0",
    "@northpoint-labs/vector-tiles-google-maps": "1.1.0",
    "@popperjs/core": "2.11.8",
    "@primeng/themes": "19.0.6",
    "acorn": "8.14.0",
    "ajv": "8.17.1",
    "bootstrap": "5.3.3",
    "buffer": "6.0.3",
    "canvg": "^4.0.2",
    "chart.js": "4.4.6",
    "core-js": "3.39.0",
    "crypto-js": "4.2.0",
    "dexie": "4.0.9",
    "font-awesome": "4.7.0",
    "fullcalendar": "6.1.15",
    "html2canvas": "1.4.1",
    "html2pdf": "0.0.11",
    "html2pdf-fix-jspdf": "0.0.4",
    "html2pdf.js": "0.10.2",
    "jspdf": "2.5.2",
    "jspdf-fix-png": "1.4.2",
    "jquery": "3.7.1",
    "moment": "2.30.1",
    "node-gyp": "10.2.0",
    "sass": "1.69.5",
    "@primer/octicons": "19.12.0",
    "ora": "^8.0.1",
    "pdf-text-extract": "1.5.0",
    "pdf-to-text": "0.0.7",
    "primeicons": "7.0.0",
    "primeng": "19.0.6",
    "quill": "2.0.2",
    "rxjs": "7.8.1",
    "signature_pad": "5.0.2",
    "stream": "0.0.3",
    "timers": "0.1.1",
    "tippy.js": "6.3.7",
    "ts-md5": "1.3.1",
    "util": "0.12.5",
    "xml2js": "0.6.2",
    "zone.js": "0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.1.0",
    "@angular/cli": "19.1.0",
    "@angular/compiler-cli": "19.1.0",
    "@angular/language-service": "19.1.0",
    "@jest/globals": "29.7.0",
    "@tsconfig/strictest": "2.0.5",
    "@types/jest": "29.5.14",
    "@types/jquery": "3.5.31",
    "@types/primer__octicons": "19.11.0",
    "@types/xml2js": "0.4.14",
    "angular-eslint": "18.1.0",
    "eslint": "9.14.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-html": "8.1.2",
    "eslint-plugin-promise": "7.1.0",
    "globals": "15.12.0",
    "jest": "29.7.0",
    "jest-preset-angular": "14.5.3",
    "process": "0.11.10",
    "protractor": "7.0.0",
    "ts-node": "10.9.2",
    "ts-string-literal-enum-plugin": "0.0.3",
    "typescript": "5.7.3",
    "typescript-eslint": "8.14.0",
    "typescript-todo-or-die-plugin": "0.6.0"
  },
  "engines": {
    "node": ">=18.19.0",
    "npm": ">=10.2.4"
  },
  "jest": {
    "preset": "jest-preset-angular",
    "setupFilesAfterEnv": ["./setup-jest.ts"],
    "testEnvironment": "jsdom",
    "transform": {
      "^.+\\.(ts|js|html)$": [
        "jest-preset-angular",
        {
          "tsconfig": "src/tsconfig.spec.json",
          "stringifyContentPathRegex": "\\.html$"
        }
      ]
    }
  },
  "private": true,
  "bundleDependencies": [
    "@angular/animations",
    "@angular/cdk",
    "@angular/common",
    "@angular/compiler",
    "@angular/core",
    "@angular/forms",
    "@angular/localize",
    "@angular/platform-browser",
    "@angular/platform-browser-dynamic",
    "@angular/pwa",
    "@angular/router",
    "@angular/service-worker",
    "@babel/core",
    "@fullcalendar/angular",
    "@fullcalendar/core",
    "@fullcalendar/daygrid",
    "@fullcalendar/interaction",
    "@fullcalendar/timegrid",
    "@google/maps",
    "@ng-bootstrap/ng-bootstrap",
    "@northpoint-labs/vector-tiles-google-maps",
    "@popperjs/core",
    "acorn",
    "ajv",
    "bootstrap",
    "buffer",
    "chart.js",
    "core-js",
    "crypto-js",
    "dexie",
    "font-awesome",
    "fullcalendar",
    "html2canvas",
    "html2pdf",
    "html2pdf-fix-jspdf",
    "html2pdf.js",
    "jspdf",
    "jspdf-fix-png",
    "moment",
    "node-gyp",
    "sass",
    "@primer/octicons",
    "ora",
    "pdf-text-extract",
    "pdf-to-text",
    "primeicons",
    "primeng",
    "quill",
    "rxjs",
    "signature_pad",
    "stream",
    "timers",
    "tippy.js",
    "ts-md5",
    "util",
    "xml2js",
    "zone.js"
  ]
}
