import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import { environment } from 'src/environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { 
    FilteredValuesInterface, 
    ItemInterface, 
    TypeAheadInterface 
} from '../../../../../../common/components/form-builder/form-builder.component';
import { ab } from '@fullcalendar/core/internal-common';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';

export class FertilizantesFormDefinition {

    public rol = StorageManager.getUser().rol;
    
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = environment.features.userCanSeeClientes ? true : (this.rol === 'admin');
    public hasFincas = environment.features.hasFincas;
    public showSectores = environment.features.showSectores;
    public showFertilizantesFormShort = environment.features.showFertilizantesFormShort;
    public showFertilizantesParcelas = environment.features.showFertilizantesParcelas;
    public showFertilizantesSectores = environment.features.showFertilizantesSectores;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showFertiNombreCuba = environment.features.showFertiNombreCuba;
    public showTareasFertiDias = environment.features.showTareasFertiDias;
    public showTareasFertiObservaciones = environment.features.showTareasFertiObservaciones;
    public showTareasFertiConsumoAgua = environment.features.showTareasFertiConsumoAgua;
    public showTareasFertiGramos = environment.features.showTareasFertiGramos;
    public showTipoFertilizacion = environment.features.showTareasFertilizantesTipoFertilizacion;
    public showTareasProcura = environment.features.showTareasProcura; 
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;
    public showCostes = environment.features.showCostes;
    public showTareasFertiSectorMultiple = environment.features.showTareasFertiSectorMultiple;
  

    public lastFieldEdited = '';

    public requestType: FormRequestTypes = FormRequestTypes.CREATE;

    public fincas = list();
    public sectores = list();
    public parcelas = list();

    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();
    public users: TypeAheadInterface<UserModel> = list();

    public tipo_fertilizacion: TypeAheadInterface<string> = list(
        ['...', null], 
        'Abono foliar', 
        'Abono de cobertera', 
        'Abono de fondo', 
        'Fertirrigación'
    );
    public tipo_fertilizacion_covidai: TypeAheadInterface<string> = list(
        ['...', null], 
        'Foliar', 
        'Abono foliar', 
        'Abono de cobertera', 
        'Abono de fondo', 
        'Fertirrigación', 
        'Otros'
    );
    
    public buenasPracticas: TypeAheadInterface<string> = list(['...', null],
        ['Aplicación de purines mediante sistema de bandas con mangueras. ', '1'],
        ['Aplicación de purines mediante sistema de bandas de discos.', '2'],
        ['Aplicación de purines mediante inyección.', '3'],
        ['Dilución de purines, seguida de técnicas tales como un sistema de riego de baja presión. ', '4'],
        ['Acidificación de los purines.', '5'],
        // eslint-disable-next-line max-len
        ['Dosis y momento de aplicación; el abonado se realizará en aquellos momentos del ciclo del cultivo en los que el aprovechamiento del fertilizante pueda ser más rápido. ', '6'],
        ['Fraccionamiento de los aportes de acuerdo con las necesidades del cultivo, siempre que sea técnicamente posible.', '7'],
        ['Incorporación de los fertilizantes en el suelo por sistemas de inyección en profundidad ', '8'],
        ['Incorporación de los fertilizantes en el suelo mediante mezcla de los gránulos del fertilizante con el suelo.', '9'],
        ['Emplear gránulos de urea recubiertos de un polímero', '10'],
        ['Aplicar un riego inmediatamente después de la fertilización con abonos a base de urea.', '11'],
        ['Realizar la fertilización nitrogenada mediante fertirrigación (Sólo cuando haya necesidades de regadío).', '12'],
        ['En el cultivo de arroz, realizar el abonado nitrogenado con el terreno seco.', '13'],
        ['Empleo de inhibidores de la ureasa.', '14'],
        ['Enterrado de purines y productos materiales líquidos lo antes posible y siempre en las primeras 4 horas tras su aplicación', '15']
    );

    public metodo_aplicacion_fertis: TypeAheadInterface<string> = list(
        ['...', null], 
        ['Esparcido general', '1'], 
        ['Esparcido general y enterrado', '2'], 
        ['Esparcido localizado', '3'], 
        ['Esparcido localizado y enterrado', '4'],
        ['Riego por aspersión (fertirrigación)', '5'], 
        ['Riego localizado (fertirrigación)', '6'], 
        ['Aplicación foliar', '7']
    );

    public tipo_fertilizacion_pacs: TypeAheadInterface<string> = list(
        ['...', null], 
        'Abonado de fondo', 
        'Abonado de cobertera', 
        'Aplicación de enmienda'
    );
    
    public sistema_riego_pacs: TypeAheadInterface<string> = list(['...', null], 
        'Superficie o Gravedad',
        'Aspersión fija', 'Aspersión movil',
        'Microaspersión', 'Goteo',
        'Hidropónico a solución perdida',
        'Hidropónico con recirculación de solución');

    public estadoTareas: TypeAheadInterface<string> = list(['...', null], 'Orden de Trabajo', 'Parte de Trabajo');
    public maquinarias: TypeAheadInterface<MaquinariaModel> = list();

    public weekDays: TypeAheadInterface<string> = list('L', 'M', 'X', 'J', 'V', 'S', 'D');
    public clientes: TypeAheadInterface<ClienteModel> = list();

    public productos1: TypeAheadInterface<ProductosModel> = list();
    public productos2: TypeAheadInterface<ProductosModel> = list();
    public productos3: TypeAheadInterface<ProductosModel> = list();
    public productos4: TypeAheadInterface<ProductosModel> = list();
    public productos5: TypeAheadInterface<ProductosModel> = list();
    public productos6: TypeAheadInterface<ProductosModel> = list();
    public productos7: TypeAheadInterface<ProductosModel> = list();

    public prioridades: TypeAheadInterface<string> = list(['...', null], 'Alta', 'Realizada');
    
    // Para los usuarios de user debes especificar la horas de inicio y fin para el cliente AVA
    public activateRequireHourForRolUser = this.appName === 'ava' ? StorageManager.getUser().rol !== 'admin' ? true : false : false;

    public formFieldsSectorLeft: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true,
            filter: (it: FilteredValuesInterface<FincasModel>) => {
                if (!this.hasClients || (this.requestType === FormRequestTypes.DUPLICATE) || !this.userCanSeeClientes) {
                    return true;
                } else if (StorageManager.getClient()) {
                    return it.value?.id_cliente === (StorageManager.getClient() || {}).id;
                }
                return true;
            },
            visible: this.hasFincas
        },
        {
            field: 'ids_sectores',
            label: this.sectoresName,
            inputType: {type: this.showTareasFertiSectorMultiple ? InputType.DROPDOWN_MULTISELECT_SEARCH : InputType.DROPDOWN_SEARCH},
            multiSelect: this.showTareasFertiSectorMultiple,
            canSelectAll: this.showTareasFertiSectorMultiple,
            values: this.sectores,
            valuePrimaryKey: 'id',
            visible: this.showSectores && this.showFertilizantesSectores,
            filter: (it: FilteredValuesInterface<SectorModel>) => it.value?.id_finca === (this.fincas.selected || {}).id
        },
        {
            field: 'id_parcelas',
            label: this.parcelasName,
            inputType: {type: InputType.DROPDOWN_MULTISELECT_SEARCH},
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            canSelectAll: true,
            visible: this.showFertilizantesParcelas,
            filter: (it: FilteredValuesInterface<ParcelasModel>) => {
                if (it.value?.activo !== '1') {
                    return false;
                }
                
                if (this.showFertilizantesSectores && (this.sectores.selected !== undefined && this.sectores.selected.length > 0)) {
                    if (!this.showTareasFertiSectorMultiple) {
                        return it.value.id_sector === (this.sectores.selected || {}).id;
                    } else {
                        return this.sectores.selected.map((sector: SectorModel) => sector.id).includes(it.value.id_sector);
                    }
                }// if();
                
                if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {}).id;
                }// if();
            }// filter ();
            ,
            required: this.appName !== 'pyf' && this.appName !== 'donjardin'
        },
        {
            field: 'facturado', 
            label: 'Facturado', 
            inputType: {type: InputType.SWITCH}, 
            visible: this.appName === 'agrosalvi'
        },
        {
            field: 'fecha_inicio', 
            label: 'Fecha Inicio', 
            visible: !environment.features.showTareasMto, 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'fecha_fin', 
            label: environment.features.showTareasMto ? 'Fecha' : 'Fecha Fin', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'hora_inicio', 
            label: 'Hora Inicio', 
            inputType: {type: InputType.TIME}, 
            visible: environment.features.timeStartStopTareas || environment.features.showTareasFertiHoras, 
            required: this.activateRequireHourForRolUser
        },
        {
            field: 'hora_fin', 
            label: 'Hora Fin', 
            inputType: {type: InputType.TIME}, 
            visible: environment.features.timeStartStopTareas || environment.features.showTareasFertiHoras, 
            required: this.activateRequireHourForRolUser
        },
        {
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.ONLY_TEXT}, 
            visible: environment.features.showTareasMto || 
                environment.features.tareasHorasTotales || 
                environment.features.showTareasFertiHoras 
        },
        { 
            field: 'horas_totales', 
            label: 'Horas', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.appName === 'fotoagricultura' 
        },
        {
            field: 'tipo_fertilizacion', 
            label: 'Tipo Fertilización', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.appName === 'covidai' ? this.tipo_fertilizacion_covidai : 
                this.applicationType === 'cropgest' ? this.tipo_fertilizacion_pacs : 
                    this.tipo_fertilizacion
        },
        {
            field: 'albaran_factura', 
            label: this.appName === 'agrosalvi' ? 'Factura/Albarán' : 'Albarán/factura', 
            inputType: {type: InputType.EDIT_TEXT} 
        },
        {
            field: 'prioridad', 
            label: 'Prioridad/Realizada', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.prioridades,
            visible: this.appName === 'ava'
        },
        {
            field: 'procura',
            label: 'Procura',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Procura', 'Media procura', 'Libre'),
            visible: this.showTareasProcura
        },        

        {
            field: 'horas_dia', 
            label: 'Horas/día', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: !this.showFertilizantesFormShort
        },
        {
            field: 'numero_dias', 
            label: 'Días/semana', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: !this.showFertilizantesFormShort
        },
        {
            field: 'dias_semana',
            label: 'Días',
            inputType: {type: InputType.SELECT_BUTTON},
            values: this.weekDays,
            multiSelect: true,
            visible: this.showTareasFertiDias && !this.showFertilizantesFormShort
        },
        {
            field: 'nombre_cuba', 
            label: 'Nombre/Cuba', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showFertiNombreCuba
        },
        {
            field: 'consumo_agua', 
            label: 'Consumo Agua m³', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.showTareasFertiConsumoAgua
        },
        {
            field: 'notas_cliente', 
            label: 'Notas cliente', 
            inputType: {type: InputType.TEXTAREA}, 
            visible: this.showClientesNotas
        },
        {
            field: 'ids_trabajadores', 
            label: 'Profesionales', 
            inputType: {type: InputType.MULTISELECT}, 
            visible: environment.features.showTareasMto || 
                this.appName === 'fotoagricultura' || 
                environment.features.showTrabajadoresFertilizantes,
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: FilteredValuesInterface<MantenimientoModel>) => {
                if (it.value?.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
        {
            field: 'aplicado', 
            label: 'Aplicado', 
            inputType: {type: InputType.SWITCH}, 
            visible: environment.features.showTareasMto && environment.features.showTareasAplicados 
        },
        {
            field: 'ids_asignados',
            label: 'Asignador',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            canSelectAll: true,
            valuePrimaryKey: 'id',
            values: this.users,
            visible: 
                (environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks) && 
                StorageManager.getUser().rol === 'admin',
            required: true
        },
        {
            field: 'grupo', 
            label: 'Grupo', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'tipo_riego', 
            label: 'Tipo Riego', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'produccion_estimada', 
            label: 'Producción Estimada', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'necesidad_maxima', 
            label: 'Necesidad Máxima', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'aporte', 
            label: 'Aporte', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'cosecha_obtenida', 
            label: 'Cosecha Obtenida', 
            inputType: {type: InputType.EDIT_TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'estado_tarea', 
            label: 'Estado de la Tarea', 
            inputType: {type: InputType.DROPDOWN},
            values: this.estadoTareas,
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora', 
            label: 'Empresa Pagadora', 
            inputType: {type: InputType.EDIT_TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'recomendaciones_riego',
            label: 'Recomendaciones de Riego',
            inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.appName === 'agufert'
        },
        {
            field: 'observaciones',
            label: 'Observaciones',
            audio: true,
            inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.showTareasFertiObservaciones
        }
    ];

    public formFieldsPac: ItemInterface<object>[] = [
        {
            field: 'fecha_riego',
            label: 'Fecha Riego',
            inputType: {type: InputType.CALENDAR},
        },
        { 
            field: 'sistema_riego',
            label: 'Sistema de Riegos', 
            inputType: {type: InputType.DROPDOWN}, 
            values: this.sistema_riego_pacs
        },
        {
            field: 'declara_buenas_practicas', 
            label: 'Buenas Prácticas',
            inputType: { type: InputType.SWITCH},
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'codigos_buena_practica', 
            label: 'Buenas Prácticas:',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true, 
            values: this.buenasPracticas
        },
        {
            field: 'dosis_riego',
            label: 'Dosis',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'medida_riego',
            label: 'Medida',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'], 'm3', 'l/Ha')
        },
        {
            field: 'n_agua',
            label: 'N nítrico del agua',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'medida_n_agua',
            label: 'Medida',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'], 'mg/l', 'ppm')
        },
        {
            field: 'p205',
            label: 'P205 soluble en agua',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'medida_p205',
            label: 'Medida',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'], 'mg/l', 'ppm')
        },
        {
            field: 'id_maquinaria',
            label: 'Equipo Aplicación',
            inputType: { type: InputType.DROPDOWN },
            values: this.maquinarias,
            valuePrimaryKey: 'id'
        },
        {
            field: 'aplicacion_residuos_r10',
            label: 'Aplicación de residuos R10',
            inputType: { type: InputType.EDIT_NUMERIC },
        },
        {
            field: 'documento_aplicacion',
            label: 'Documento de Aplicación',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'], 'SI', 'NO')
        },        
    ];

    public formFieldsAbonado: ItemInterface<object>[] = [
        {
            field: 'plan_abonado',
            label: 'Plan Abonado',
            inputType: { type: InputType.SWITCH },
        },
        {
            field: 'necesidad_uf_n',
            label: 'Necesidad UF N (Kg/Ha)',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'necesidad_uf_p205',
            label: 'Necesidad UF P205 (Kg/Ha)',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'necesidad_uf_k20',
            label: 'Necesidad UF K20 (Kg/Ha)',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'objetivo_produccion',
            label: 'Objetivo Producción (Kg/Ha)',
            inputType: { type: InputType.EDIT_TEXT }
        },
        {
            field: 'id_cultivos',
            label: 'Producto Precedente',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'])
        },
        {
            field: 'uso_herramienta',
            label: 'Uso de Herramienta',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...'], 'SI', 'NO')
        },
        {
            field: 'codigo_regfer',
            label: 'Código REGFER',
            inputType: { type: InputType.EDIT_TEXT },
        },
        {
            field: 'fecha_asesoramiento',
            label: 'Fecha Asesoramiento',
            inputType: { type: InputType.CALENDAR },
        }
    ]; 

    public formFieldsBottomLeft: ItemInterface<object>[] = [
        {
            field: 'id_producto1',
            label: 'Producto Ferti',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos1,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis1', 
            label: this.appName === 'ingenia' ? 'Litros/Kg Totales' : 
                ((this.appName === 'sequiador' || this.appName === 'SIEX') ? 'Kg/Ha' : 'Kg Totales') , 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos1', 
            label: (this.appName === 'prosanzcu') ? 'Kg/' + this.textoSuperficie : 
                ['abonosfolques', 'pyf'].includes(this.appName) ?  'Gr./árbol' : 
                    this.appName === 'ferticinca' ? '(Dosis/' + this.textoSuperficie + ')' : 
                        this.appName === 'ingenia' ? 'Gr./cc por Árbol' :  
                            'Gr./árbol (dosis/' + this.textoSuperficie + ')', 
            inputType: {type: InputType.EDIT_NUMERIC}, visible: this.showTareasFertiGramos},
        {
            field: 'kg_ha1', label: 'Kg/' + this.textoSuperficie,
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT},
            style: 'background-color: transparent'
        },
        {
            field: 'id_producto2',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos2,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis2', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos2', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha2',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            field: 'id_producto3',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos3,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis3', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos3', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos},
        {
            field: 'kg_ha3',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            field: 'id_producto4',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos4,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis4', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos4', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha4',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            field: 'id_producto5',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos5,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis5', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos5', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha5',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            field: 'id_producto6',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos6,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis6', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos6', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha6',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
        {
            field: 'id_producto7',
            inputType: this.appName === 'pyf' ? {type: InputType.DROPDOWN_SEARCH} : {type: InputType.DROPDOWN_SEARCH},
            values: this.productos7,
            filter: (it: FilteredValuesInterface<ProductosModel>) => it.value?.tipo === 'fertilizante',
            valuePrimaryKey: 'id'
        },
        {
            field: 'dosis7', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'gramos7', 
            inputType: {type: InputType.EDIT_NUMERIC}, 
            visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha7',
            visible: (this.appName === 'pyf'), 
            inputType: {type: InputType.ONLY_TEXT}
        },
    ];

    public formFieldsBottomRight: ItemInterface<object>[] = [
        {
            field: 'dosis1', 
            label: 'Total kilos', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis2', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis3', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis4', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis5', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis6', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            field: 'dosis7', 
            inputType: {type: InputType.EDIT_NUMERIC}
        }
    ];

    public formFieldsOrdenTrabajo: ItemInterface<ab>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];
}