import { environment } from './../../../../environments/environment';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { firstValueFrom, Subject } from 'rxjs';
import { FormRequest } from './form-request';
import type { Form, FormField } from './interfaces/form.interface';
import { FileUploadAnswer } from '../../utils';
import { HttpClient } from '@angular/common/http';
import { DropdownData, InputType } from './interfaces/input.interface';
import { RequestHandler } from '../../../../../src/app/service/OffService/request-handler';
import { FormOperations } from './utils/form-operations';
import { FormCustomValidators } from './utils/form-custom-validators';

@Component({
    selector: 'app-reactive-form-builder',
    standalone: false,
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveFormBuilderComponent<T> extends FormRequest implements OnInit {

    @Input() form!: Form<T>;
    @Input() serverUrl = '';
    @Input() request!: RequestHandler<any>;
    @Input() undoEnabled = false;
    @Input() previousData: T | null = null;
    @Input() dropdownLoadTrigger!: Subject<void>;    
    @Output() formChanges: EventEmitter<[[object, string], string]> = new EventEmitter<[[object, string], string]>();
    @Output() goBack: EventEmitter<T> = new EventEmitter<T>();

    public appName = environment.appName;
    public filterInputParcelas = environment.features.filterInputParcelas;
    public INPUT = InputType;
    public model!: FormGroup;
    public originalModel: Record<string, string> = {};

    private previousModel: Record<string, string> = {};
    private formOperations!: FormOperations<T>;
    private formCustomValidators = new FormCustomValidators();


    /**
     * 
     * @param http 
     */
    constructor(private http: HttpClient) {
        super();        
    }// ();


    /**
     * Populates the view with form fields.
     */
    ngOnInit(): void {
        this.initializeForm();
    }// ();
    

    /**
     * Returns the user to the location from which they arrived at the form.
     */
    public returnToMainView(): void {
        this.goBack.emit();
    }// ();


    /**
     * Uploads signature image to the server.
     * @param event Signature file.
     */
    public async getSignature(event: File) {
        this.model.patchValue({ ['signature_img']: event });

        const signatureName = this.model.get('signature_img')?.value;

        if (signatureName == null) { return; }
        
        const formData = new FormData();
        formData.append('fileToUpload', signatureName);
        formData.append('database', 'tareas_fitosanitarias');
        formData.append('folder', 'fitosanitarios');
        formData.append('field', 'signature_img');

        const signature = 
            await firstValueFrom(
                this.http.post<FileUploadAnswer>( this.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData));
            
        this.model.patchValue({ ['signature_img']: signature.target_file.replace('../../images/fitosanitarios/', '') });
    }// ();
    

    /**
     * Displays the image of the signature uploaded to the form.
     * @param event Signature file name.
     */
    public updateFirma(event: string) {
        this.model.patchValue({ ['signature_img']: event });
    }// ();


    /**
     * Initializes the form in two runs: the input run, where all the form fields are loaded, 
     * and the dropdown run, where the dropdowns are populated with data 
     * so that the form is always displayed to the user.
     * @returns void
     */
    private initializeForm(): void {
        this.formOperations = new FormOperations(this.form, this.model);
        this.initializeModel();
        
        if (this.form.type <= 2 && this.dropdownLoadTrigger) {
            const subscription = this.dropdownLoadTrigger.subscribe(() => {
                this.initializeModel();
                this.subscribeToFormChanges();
                this.originalModel = { ...this.model.value };
                this.formOperations.executeFieldsLogic(null);

                subscription.unsubscribe();
            });
        }// if();
    }// ();


    /**
     * Converts form fields to form group.
     */
    private initializeModel(): void {
        this.model = this.toFormGroup(this.form);
        this.formOperations = new FormOperations(this.form, this.model);
    }// ();
    

    /**
     * Updates form fields based on a change made by the user.
     */
    private subscribeToFormChanges(): void {
        this.model.valueChanges.subscribe(newValues => {
            const changedFields = Object.keys(newValues).filter(
                control => newValues[control] !== this.previousModel?.[control]
            );
    
            if (changedFields.length > 0) {
                changedFields.forEach(changedField => {
                    this.formChanges.emit([newValues, changedField]);
                    this.formOperations.applySelected(
                        this.formOperations.getFieldByControlName(changedField),
                        newValues[changedField]
                    );
                    this.formOperations.executeFieldsLogic(changedField);
                });
            }// if();

            this.previousModel = { ...newValues };
        });
    }// ();


    /**
     * Converts form to FormGroup.
     * @param form 
     * @returns FormGroup
     */
    private toFormGroup(form: Form<T>): FormGroup {
        const controls = form.sections
            .filter(section => section.isVisible)
            .flatMap(section => section.fields)
            .filter(field => field.conditions.isVisible && !field.conditions.isRetained)
            .reduce((group, field) => {
                const defaultValue = this.setDefaultValues(field);
                group[field.name ?? ''] = new FormControl(
                    defaultValue,
                    this.setValidators(field)
                );
                this.formOperations.applySelected(this.formOperations.getFieldByControlName(field.name ?? ''), defaultValue as T);
                return group;
            }, {} as Record<string, FormControl>);
        return new FormGroup(controls);
    }// ();


    /**
     * 
     * @param field 
     * @returns Field default value.
     */
    private setDefaultValues(field: FormField<T>): 
        (T | null)[] | NonNullable<T> | T | string | number | boolean | Date | never[] | void | null | undefined{
        if (this.form.type === 2 && field.conditions.isRememberedOnDuplicate === false) {
            return;   
        }

        const previousData = this.previousData as Record<string,string>;
        
        if (previousData?.[field.name ?? ''] && previousData?.[field.name ?? ''] !== 'null') {
            const previousValue = previousData[field.name ?? ''];
            
            if (field.input?.dropdownConfiguration?.valuePrimaryKey && this.form.type < 3) {
                return this.formOperations.getFilteredValues(previousValue ?? '', field);
            }// if();
            
            return previousValue;
        }// if();

        return field.input.defaultValue ?? (
            (field?.input?.data as DropdownData<T>)?.filtered?.length === 1 ?
                (field?.input?.data as DropdownData<T>)?.filtered?.[1]?.value : 
                ((
                    field?.input?.type === InputType.MULTISELECT_SEARCH || 
                    field?.input?.type === InputType.MULTISELECT
                ) ? [] : ''));
    }// ();


    /**
     * 
     * @param field 
     * @returns Validators
     */
    private setValidators(field: FormField<T>): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
    
        if (field.conditions.isRequired) { validators.push(Validators.required); }
        if (field.input.maxLength) { validators.push(Validators.maxLength(field.input.maxLength)); }
        if (field.name?.includes('hora')) { validators.push(this.formCustomValidators.timeValidator); }
    
        return validators;
    }// ();
}// class;


export interface DropdownInterface<T> {
    label: string;
    value: T | null;
}