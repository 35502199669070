<div class="w-100" id="text-right">
  
  <div>
    {{transcriptionLabel}}
  </div>
  
  <div>
    <button 
      class="btn mx-1"
      id="recording-button" 
      type="button" 
      (click)="start()" 
      [disabled]="isRecording">
      <i aria-hidden="true" class="fa fa-microphone"></i>
    </button>

    <button 
      class="btn btn-warning mx-1" 
      type="button"  
      (click)="stop()"
      [disabled]="!isRecording">
      <i aria-hidden="true" class="fa fa-pause"></i>
    </button>

    <button 
      class="btn btn-danger mx-1" 
      type="button" 
      (click)="deleteTranscription()">
      <i aria-hidden="true" class="fa fa-trash-o"></i>
    </button>

    @if (isRecording) {
      <div style="font-size: 1.2em;">
          {{speechRecognitionService.transcriptionInfo}}
      </div>
    }

    @if (speechRecognitionService.transcriptionError) {
      <div style="font-size: 1.2em;">
        <span id="error-message">{{this.speechRecognitionService.transcriptionError}}</span>
      </div>
    }
  </div>

</div>