import { ProductosApiService } from '../../../../../service/api/productos-api';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {TratamientosApiService} from '../../../../../service/api/tratamientos-api.service';
import {FitosanitariosFormDefinition} from './form-control/form-definition';
import {build, pool, buildParams} from '../../../../../common/classes/request-builder';
import {EventPhase, FormPhase, FormRequestTypes} from '../../../../../common/components/reactive-form-builder/form-request';
import {ClientesApiService} from '../../../../../service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { WeatherService } from '../../../../../service/api/weather/weather';
import { RecuperarReferenciaRecetaService } from '../../../../../service/recuperar.service';
import { HttpClient } from '@angular/common/http';
import { SiexApiService } from '../../../../../service/api/siex-api.service';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';
import { Subject } from 'rxjs';

import type { OnInit } from '@angular/core';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { TratamientosControlModel } from 'src/app/models/tratamientos/tratamientos-control.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { ReactiveFormBuilderComponent } from 'src/app/common/components/reactive-form-builder/form-builder.component';
import { FitosanitariosEnvironmentDefinition } from '../environment-variables';
import { ClienteModel } from 'src/app/models/cliente.model';

@Component({
    selector: 'app-formulario-pulverizacion',
    standalone: false,
    templateUrl: './fitosanitarios-form.component.html',
    styleUrls: ['./fitosanitarios-form.component.scss']
})
export class FitosanitariosFormComponent extends BaseForm<TareaFitoModel> implements OnInit {

    public form = new FitosanitariosFormDefinition(
        this.getType(), 
        localStorage.getItem('tratamiento_temp') ? JSON.parse(localStorage.getItem('tratamiento_temp') ?? '') : this.getModel(), null);
    public formRequest = new ReactiveFormBuilderComponent(this.http);
    public env: FitosanitariosEnvironmentDefinition = new FitosanitariosEnvironmentDefinition();

    public rol = StorageManager.getUser().rol;

    public loadingData = true;
    public dropdownLoadTrigger = new Subject<void>(); 


    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public usuariosApi: UsuariosApiService,
        public tareasApi: TareasApiService,
        public fincasApi: FincasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tratamientosApi: TratamientosApiService,
        public clientesApi: ClientesApiService,
        public productosApi: ProductosApiService,
        public siexApi: SiexApiService,
        public override router: Router,
        public dashboardEvent: DashboardService,
        private weatherService: WeatherService,
        private recuperarService: RecuperarReferenciaRecetaService,
        private http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.fitosanitariosPUT,
            tareasApi.fitosanitariosPOST,
            'fitosanitarios',
            'Actualizar Tarea Fitosanitaria',
            'Realizar Tarea Fitosanitaria',
            'Duplicar Tarea Fitosanitaria'
        );
    }

    ngOnInit() {
        this.dashboard.clientChanges$.subscribe(() => {
            this.fincasApi.fincasGET.unsuscribe();
            this.fincasApi.sectoresGET.unsuscribe();
            this.fincasApi.parcelasGET.unsuscribe();
            this.tratamientosApi.tratamientosList.unsuscribe();
            if (this.env.filterAplicadoresByClient) {
                this.trabajadoresApi.trabajadoresGET.unsuscribe();
                this.trabajadoresApi.maquinariaGET.unsuscribe();
            }
        });
        this.initFormData();
        
        this.expandFormRequest();       
       
        this.formRequest.executePhase(FormPhase.LOAD);

        this.softInit(this.getType());
        //this.form.formType = this.getType();
        this.form.model = this.model;
        
    }
   
    public formChanges(formModel: [object, string], tag: string) {
        this.form.model = formModel as unknown as TareaFitoModel;
        this.form.tag = tag;
        
        if (this.env.appName === 'pyf') {
            this.form.recuperarService = this.recuperarService;
        }
        if (this.env.showClimatologia) {
            this.form.weatherService = this.weatherService;
        }
        if (tag === 'id_cliente' && this.env.appName !== 'agricar') {
            if (this.form.lists.clientes.selected) {
                StorageManager.saveClient(this.form.lists.clientes.selected);
            }
            this.dashboardEvent.emitClientChange();

            const builds = [
                buildParams(this.fincasApi.fincasGET, 
                    {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                    this.form.lists.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, 
                    {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                    this.form.lists.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, 
                    {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                    this.form.lists.parcelas,
                    ['nombre','municipio'], true),
                buildParams(this.tratamientosApi.tratamientosList, 
                    {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                    this.form.lists.tratamientos,
                    'nombre', true),
            ];

            if(this.env.appName === 'prosanzcu'){
                builds.push(
                    buildParams(this.fincasApi.parcelasGET, 
                        {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                        this.form.lists.parcelas,
                        ['nombre','municipio','poligono'], true)
                );
            } else {
                builds.push(
                    buildParams(this.fincasApi.parcelasGET, 
                        {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                        this.form.lists.parcelas,
                        'nombre', true)
                );
            } 

            if (this.env.filterAplicadoresByClient) {
                builds.push(
                    buildParams(
                        this.trabajadoresApi.trabajadoresGET, 
                        {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id},
                        this.form.lists.trabajadores, 'nombre', true),
                    buildParams(this.trabajadoresApi.maquinariaGET, 
                        {id_cliente: (this.form.model.id_cliente as unknown as ClienteModel).id}, 
                        this.form.lists.maquinarias, 'nombre', true)
                );
            }

            pool(builds, () => {
                this.form.lists.fincas.filtered = this.form.lists.fincas.values;
                this.form.lists.parcelas.values = this.form.lists.parcelas.values.filter(it => (it.label !== '...'));
                this.form.lists.tratamientos.filtered = this.form.lists.tratamientos.values;
                
                if (this.env.filterAplicadoresByClient) {
                    this.form.lists.trabajadores.filtered = this.form.lists.trabajadores.values.filter(it => (it.label !== '...'));
                    this.form.lists.maquinarias.filtered = this.form.lists.maquinarias.values;
                }
            }).then(() => {
                this.formRequest.executePhase(FormPhase.UPDATE);
                return;
            }).catch (e => {
                console.log('catch en pool: ' + e);
            }
            );  
        }
    }

    public async submit() {
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    private expandFormRequest() {    
        this.formRequest.addEvent(FormPhase.LOAD, EventPhase.AFTER, (resolve) => {
            localStorage.removeItem('tratamiento_temp');
            resolve(true);
        });

        this.formRequest.addEvent(FormPhase.SEND, EventPhase.AFTER, (resolve) => {
            StorageManager.saveSessionDataDonJardin(
                this.form.model.id_finca ?? '', 
                this.form.model.hora_inicio ?? '', 
                this.form.model.hora_fin?.toString() ?? ''
            );
            resolve(true);
        });

        let isControl = false;
        let controlObtenido: TratamientosControlModel[] = [];
        this.formRequest.addEvent(FormPhase.SEND, EventPhase.AFTER, (resolve) => {
            if (this.env.appName === 'hernandorena') {
                this.tratamientosApi.tratamientosControl.GET.toPromise({
                    id_tarea: this.form.model.id
                } as never).then(control => {
                    if (control && control[0]) {
                        controlObtenido = control;
                        isControl = true;
                    } else {
                        resolve(true);
                    }
                    return;
                }).catch (e => {
                    console.log('catch en formRequest: ' + e);
                }
                );
            } else {
                resolve(true);
            }
        });

        if (isControl) { 
            this.tratamientosApi.tratamientosControl.PUT.toPromise({
                id: controlObtenido[0]?.id,
                estado: this.form.model.aplicado ? 'Aplicado' : 'Planificado'
            } as never).then(() => {
                console.log('Control Obtenido');
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
    }

    private initFormData(): Promise<boolean> {
        this.loadingData = true;

        const buildsRequest = this.getBuildsRequest();

        return pool( buildsRequest, () => {
            this.formRequest.executePhase(FormPhase.UPDATE);
            this.loadingData = false;
        }).then(() => {
            this.dropdownLoadTrigger.next();
            return true;
        });
    }

    private getBuildsRequest() {
        const buildsRequest = [
            build(this.clientesApi.clientesGET, this.form.lists.clientes, { or: ['razon_social', 'nombre'] }),
            build(this.tratamientosApi.tratamientosList, this.form.lists.tratamientos, 'nombre', true),
            build(this.fincasApi.fincasGET, this.form.lists.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.lists.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.lists.parcelas, 'nombre', true),
            build(this.siexApi.justificacionesActuacionGET, this.form.lists.justificaciones, 'justificacion'),
            build(this.trabajadoresApi.maquinariaGET, this.form.lists.maquinarias, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.form.lists.trabajadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || '') + (it.maquinaria
                    ? ' - (' + (it.maquinaria || '...') + ')'
                    : ''),
                true, true)

        ];

        if (this.env.showAssignUserTasksFitosanitario || this.env.showAssignUserInAllTasks) {
            buildsRequest.push(build(this.usuariosApi.usuariosGET, this.form.lists.usuarios, 'nombre'));
        }
        
        if (this.env.appName === 'innovia') {
            buildsRequest.push(build(this.productosApi.productos.GET, this.form.lists.productos, 'nombre'));
        }
        
        return buildsRequest;
    }
}