import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import type { Form, FormField } from '../interfaces/form.interface';
import { DropdownData, FilteredValue, InputType } from '../interfaces/input.interface';

@Component({
    selector: 'app-field',
    standalone: false,
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
})
export class FieldComponent<T> {
    @Input() field!: FormField<T>;
    @Input() model!: FormGroup;
    @Input() form!: Form<T>;
    @Input() originalModel: Record<string, string> = {};
    @Input() filterInputParcelas!: boolean;
    @Input() undoEnabled = false;

    INPUT = InputType;
    
    get filteredOptions(): FilteredValue<T>[] {
        return (this.field?.input?.data as DropdownData<T>).filtered ?? [];
    }
    
    public isFieldEdited(field: FormField<T>): boolean {
        const originalValue = this.originalModel[field.name ?? ''];
        const currentValue = this.model.get(field.name ?? '')?.value;
        return (field.conditions.hasUndoEnabled || this.undoEnabled) && currentValue !== originalValue;
    }
          
    public getEditedClass(field: FormField<T>): string {
        return this.isFieldEdited(field) ? 'edited' : '';
    }

    public isEditable(): boolean {
        return this.form.type <= 2 && this.field.input.isEditable !== false;
    }

    public sum(field: FormField<T>): string {
        if (this.form.type < 3) {
            const selectedItems = ((field.input.data as DropdownData<T>)?.selected ?? []) as Record<string, string>[];
    
            return `(${selectedItems.reduce(
                (sum, value) => sum + parseFloat(value?.[field.input?.dropdownConfiguration?.summation?.summand ?? '0'] || '0.00'),
                0
            )} ${field.input?.dropdownConfiguration?.summation?.uds})`;
        }
        return '';
    }
    
    public undoField(field: FormField<T>): void {
        const originalValue = this.originalModel[field.name ?? ''];
        this.model.patchValue({ [field.name ?? '']: originalValue });
        this.applySelected(field, originalValue);
    }

    public getAudio(field: string | number, event: string ) {
        this.model.patchValue({ [field]: event });
    }

    public updateModel(field: FormField<T>) {
        if (field.input?.selectButtonConfiguration?.onClick) {
            const onClick: Promise<object> = field.input.selectButtonConfiguration.onClick(field as T);

            
            onClick.then((data) => {
                Object.entries(data).forEach((element) => {
                    this.model.patchValue({[element[0]] : element[1]});
                });
                return;
            }).catch((error: string) => {
                console.log(error);
            });
        }
    }

    public updateImageModel(event: string, fieldName: string  ){
        this.model.patchValue({[fieldName]: event});
    }

    private applySelected(field: FormField<T>, value: string | undefined): void {
        if (field.input.data) {
            (field.input.data as DropdownData<T>).selected = value as T;
        }
    }
}
  