import { Component } from '@angular/core';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import { BaseView } from 'src/app/view/base-view';
import { CamposApiService } from 'src/app/service/api/campos-api.service';
import { StorageManager } from '../../../../common/storage-manager.class';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'app-campos',
    standalone: false,
    templateUrl: './campos.component.html',
    styleUrls: ['./campos.component.scss']
})
export class CamposComponent extends BaseView<ParcelasModel> implements OnInit {

    public uds_medida = StorageManager.getUser().uds_medida;
    public rol = StorageManager.getUser().rol;
    public getRequest: RequestHandler<ParcelasModel[]> = this.camposApi.campos.GET;
    public deleteRequest: RequestHandler<ParcelasModel> = this.camposApi.campos.DELETE;
    public menuItemId = 'campos';

    public filter: Filtering<ParcelasModel> = new Filtering<ParcelasModel>();
    public showAllCampos = false;

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model = {} as ParcelasModel;

    public btnAnadir = 'Añadir Campo';

    public formFieldsDatosParcela: ItemInterface<object>[] = [
        {
            field: 'usuario_nombre', 
            label: 'Usuario', 
            inputType: {type: InputType.TEXT}, 
            visible: this.rol === 'admin'
        },
        {
            field: 'nombre', 
            label: 'Nombre del Campo', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'superficie_sigpac', 
            label: 'Superficie Sigpac ' + this.uds_medida, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'superficie_cultivada', 
            label: 'Superficie Cultivada ' + this.uds_medida, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cultivo', 
            label: 'Cultivo', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'variedad', 
            label: 'Variedad', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'marco', 
            label: 'Marco Plantación', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'anyo_plantacion', 
            label: 'Año Plantación', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'plantas', 
            label: 'Nº Árboles/Plantas', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'patron', 
            label: 'Patrón', 
            inputType: {type: InputType.TEXT}
        }
    ];

    public formFieldsDatosSigpac: ItemInterface<object>[] = [
        {
            field: 'cod_provincia', 
            label: 'Cód. Provincia', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'municipio', 
            label: 'Municipio', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'poligono', 
            label: 'Polígono', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'numero', 
            label: 'Nº parcela', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'recinto', 
            label: 'Recinto', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'nombre', header: 'Nombre', filter: ''},
        {field: 'cultivo', header: 'Cultivo', filter: ''},
        {field: 'variedad', header: 'Variedad', filter: ''},
        {field: 'superficie_sigpac', header: 'Sup.\xa0Sigpac', filter: ''},
        {field: 'superficie_cultivada', header: 'Sup.\xa0Cultivada', filter: ''},
        {field: 'poligono', header: 'Polígono', filter: ''},
        {field: 'numero', header: 'Nº\xa0parcela', filter: ''},
        {field: 'plantas', header: 'Nº\xa0Árboles/Plantas', filter: ''},
        {field: 'usuario_nombre', header: 'Usuario', filter: '', visible: this.rol === 'admin'},
    ];

    constructor(public fincasApi: FincasApiService,
        public camposApi: CamposApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, camposApi.campos.GET, camposApi.campos.DELETE);
    }

    ngOnInit() {
        this.showAllCampos = Utils.initShowAll('showAllcampos');
        this.initFilter();
        this.dashboard.setMenuItem(this.menuItemId);
    }

    public add() {
        this.router.navigate(['dashboard', 'crear-campo']);
    }

    public edit(data: FincasModel) {
        this.router.navigate(['dashboard', 'editar-campo', data.id]);
    }

    public override show(modal: string, data: ParcelasModel) {
        super.show(modal, data);
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);
    }

    public globalConditionalStyle(value: string): ColStylesInterface {
        if (Utils.isNumber(value)) {
            return {
                textAlign: 'right',
                paddingRight: '40px',
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value)) {
            if (col.field === 'superficie') {
                return Utils.decimalFormat(value.replace(',', '.'), 2).replace('.', ',');
            } else if (col.field === 'plantas') {
                return Utils.decimalFormat(value, 0);
            }
        }
        return value;
    }

    public filterCampos() {
        this.filter.filter();
    }
    
    public changeShowAll(){
        sessionStorage.setItem('showAllcampos', this.showAllCampos === true ? 'true' : 'false' );
    }
    
    private initFilter() {
        this.filter.addFilter((data: TreeNode<ParcelasModel>[] | void) => (data || [])
            .filter((it: TreeNode<ParcelasModel>) => it)
            .filter((it: TreeNode<ParcelasModel>) => !this.showAllCampos 
                ? [(it as ParcelasModel).id_usuario].includes(StorageManager.getUser().id)
                : true)
        );
    }
}
