import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {RecogidasApiService} from 'src/app/service/api/recogidas-api.service';
import {DashboardService} from '../../../service/events/dashboard.service';
import {RequestButtonTypes} from '../../../common/components/request-button/request-button.component';
import {InputType} from '../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {Utils} from '../../../common/utils';
import {Filtering} from '../../../service/filtering/filtering';
import {BaseView} from '../../base-view';
import {StorageManager} from '../../../common/storage-manager.class';
import { environment } from 'src/environments/environment';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { ParcelasModel } from '../../../models/form-common/parcelas.model';
import { createCsv } from 'src/app/common/createCsv';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { RecogidaModel } from 'src/app/models/recogida.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-cargas',
    standalone: false,
    templateUrl: './recogidas.component.html',
    styleUrls: ['./recogidas.component.scss']
})

export class RecogidasComponent extends BaseView<RecogidaModel> implements OnInit, AfterViewInit {
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));

    public btnAnadir = 'Añadir Recogida';

    public esUsuario: boolean = StorageManager.getUser().rol !== 'admin';
    public filter: Filtering<RecogidaModel> = new Filtering<RecogidaModel>();
    public getRequest: RequestHandler<RecogidaModel[]> = this.recogidasApi.recogidasGET;
    public deleteRequest: RequestHandler<RecogidaModel> = this.recogidasApi.recogidasDELETE;
    public pathToForm = 'recogidas-form';
    public menuItemId = 'recogidas';
    public parcelas: ParcelasModel[] = [];
    public parcelasFilter: ParcelasModel[] = [];
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: RecogidaModel = {};

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            name: this.fincaName, 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha', 
            name: 'Fecha', 
            label: 'Fecha de Recogida', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sector', 
            name: 'Sector', 
            label: 'Sector', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas', 
            name: this.parcelaName, 
            label: this.parcelasName, 
            inputType: {type: InputType.TEXTAREA}, 
            disabled: true
        },
        {
            field: 'albaran', 
            name: 'Nº Albarán', 
            label: 'Nº Albarán', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cultivo', 
            name: 'Cultivo', 
            label: 'Cultivo', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'variedad', 
            name: 'Variedad', 
            label: 'Variedad', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'cantidad', 
            name: 'Cantidad Kg', 
            label: 'Cantidad Kg', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha de Recogida', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'parcelas', header: this.parcelaName, filter: ''},
        {field: 'variedad', header: 'Variedad', filter: ''},
        {field: 'cantidad', header: 'Cantidad\xa0Kg', footer: ''},
        {field: 'albaran', header: 'Nº\xa0Albarán', filter: ''},
        {field: 'producto_nombre', header: 'Fito', filter: ''},
        {field: 'prohibido_hasta', header: 'Prohibido Hasta', filter: ''},
    ];

    constructor(public fincasApi: FincasApiService,
        public recogidasApi: RecogidasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, recogidasApi.recogidasGET, recogidasApi.recogidasDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.fincasApi.parcelasGET.safePerform();
        this.fincasApi.parcelasGET.response(parcelas => {
            this.parcelas = parcelas;
        });
    }

    ngAfterViewInit() {
        this.recogidasApi.recogidasGET.response(() => {
            this.updateFooter();
        });
    }
    
    public override show(modal: string, data: RecogidaModel) {
        super.show(modal, data);

        this.formatParcelas();
    }

    formatParcelas() {
        let parcelasId: string[];
        if (this.model.ids_parcelas) {
            parcelasId = this.model.ids_parcelas.split(';') || [];
        } else {
            parcelasId = [];
        }
        if (parcelasId.length > 0) {
            this.parcelas.forEach(parcela => {
                if (parcelasId.includes(parcela.id.toString())) {
                    this.parcelasFilter.push(parcela);
                }
            });
            this.model.parcelas = '';
            this.parcelasFilter.forEach(parcela => {
                if (parcela) {
                    this.model.parcelas += `- ${parcela.nombre}\n`;
                }
            });
            this.model.parcelas = this.model.parcelas.substring(0, this.model.parcelas.length - 1);
        } else {
            this.model.parcelas = [];
        }
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-recogida']);
        }
    }

    public edit(data: RecogidaModel) {
        this.router.navigate(['dashboard', 'editar-recogida', data.id]);
    }

    public duplicate(data: RecogidaModel) {
        this.router.navigate(['dashboard', 'duplicar-recogida', data.id]);
    }    


    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (Utils.isNumber(value) || col.field === 'cantidad' || col.field === 'albaran' ) {
            return {
                textAlign: 'right',
                paddingRight: '60px',
            };
        }
        if ( col.field !== 'finca') {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value)) {
            if (col.field !== 'albaran') {
                return Utils.decimalFormat(value.replace(',', '.'), 2);
            }
        }
        if (col.field === 'variedad' || col.field === 'cultivo') {
            return (value?.length > 25) ? value.slice(0, 25 - 1) + '…' : value;
        }
        return value;
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            sum += parseFloat(it.data?.[field] as string);
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it['footer'] = Utils.decimalFormat(sum + '', 0);
            }
        });
    }

    public override updateFooter() {
        ['cantidad'].forEach(it => this.sum(it));
    }


    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields
        ];
      
        
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'cantidad' );
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Recogidas.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }// ();
}