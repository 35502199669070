<div id="photo-camera">
  
  @if(imageName !== null) {
    <div class="photo-camera-distribution">
      
      @if (imageModel.imagen || imageModel.image_after) {
        <i class="fa fa-picture-o icon-picture" aria-hidden="true" (click)="viewImage()"></i>
      }
      
      @if (formType < 3) {
        <app-reactive-camera (sendCameraFile)="uploadFile($event)"></app-reactive-camera>
      }

    </div>
  }

</div>