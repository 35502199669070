import { Component } from '@angular/core';
import {DashboardService} from '../../service/events/dashboard.service';

import type { OnInit } from '@angular/core';
@Component({
    selector: 'app-stock',
    standalone: false,
    templateUrl: './stock.component.html',
    styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

    constructor(private dashboardEvent: DashboardService) { }

    ngOnInit() {
        this.dashboardEvent.setMenuItem('stock');
        this.dashboardEvent.setHeaderTitle('Stock');
    }

}
