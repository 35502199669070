import {Component, Input} from '@angular/core';
import {Utils} from '../../../../../../common/utils';
import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import moment from 'moment';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { TareaFertiModel } from 'src/app/models/tareas/tarea-ferti.model';
import { AbonadoInterface } from './abonado.interface';
import * as $ from 'jquery';

@Component({
    selector: 'app-abonado',
    standalone: false,
    templateUrl: './abonado.component.html',
    styleUrls: ['./abonado.component.scss']
})
export class AbonadoComponent implements OnInit {

    @Input() capacidad_cuba = 0;
    @Input() dosis_cuba = 0;
    @Input() caudal_inyectora = 0;
    @Input() numero_arboles = 0;
    @Input() modelSup: TareaFertiModel = {};

    @Input() sectores: SectorModel[] = [];

    @Input() fecha_inicio = '';
    @Input() fecha_fin = '';
    @Input() horas_dia = 0;
    @Input() dias_semana = 0;
    @Input() productos: ProductosModel[] = [];
    @Input() dosis: number[] = [];

    @Input() recalculateEvent: Observable<string> | undefined;
    
    public model: AbonadoInterface = {};
    public sectorName = environment.features.sectorName !== '' ? environment.features.sectorName : 'Sector';
    public errorMessage: string | null = null;

    public fertilizantesFormShort = environment.features.showFertilizantesFormShort;

    public horas_dia_menos_45min = 0;
    public horas_dia_text = 0;
    public porcentaje_bomba: string | number = 0;

    public tiempoPostRiego = '0:30';
    public tiempoPreRiego = '';

    public porcentajesArboles: number[] = [];

    public planArray: AbonadoInterface[] = [];
    public showPlanAbonado = false;
    public appClient: string = environment.appName;
    public finca = '';
    public fecha = '';
    public f_ini = '';
    public f_fin = '';
    public mes = '';
    

    public formFields: ItemInterface<object>[] = [
        {
            field: 'producto1', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis1', 
            label: 'Cantidad', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto2', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis2', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto3', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis3', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto4', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis4', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto5', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis5', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto6', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis6', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'producto7', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'dosis7', 
            inputType: {type: InputType.TEXT}
        },
    ];


    constructor() {

    }

    ngOnInit() {
        this.fecha = moment().format('DD/MM/YYYY');
        this.startCalcating();
    }

    public startCalcating() {
        if (this.validateData()) {
            this.calculate();
        } else {
            // console.log('Abonado Component @ Faltan Datos o son incorrectos');
        }
    }


    reCalcular(){
        this.calculaTiemposRiego();
       
        this.modelSup.dosis_cuba = this.dosis_cuba;
        this.modelSup.capacidad_cuba = this.capacidad_cuba;
    }

    calculaTiemposRiego(){

        let totalArboles = 0;
        let porcentajeArboles = 0;
        let mayorProporcion = 0;
        (this.sectores || []).forEach(sector => { 
            totalArboles += parseInt(sector.plantas ?? '', 10);
            if (mayorProporcion < (sector.proporcionArboles || 0)) { mayorProporcion = sector.proporcionArboles ?? 0; }
        });

        let i = 0;
        let tiempoStr = '';
        let horas, mins = 0;
        let tiempoCalculado = 0;
        let inRiego, enMins = 0;
        let proporcion1: number;
        (this.sectores || []).forEach(sector => {
            //MIO (SALVA)
            //CALCULA Nº ARBOLES DE CADA SECTOR EN %
            porcentajeArboles = (+(sector.plantas || 0) / totalArboles) * 100;           
            this.porcentajesArboles.push(porcentajeArboles);

            if (sector.proporcionArboles === 0) { sector.proporcionArboles = 1; }
            if (i === 0) {
                inRiego = this.timeToNumber(sector.horas_dia_menos_45min?.toString() ?? '');
                enMins = (inRiego.hours * 60 ) + inRiego.minutes;
                proporcion1 = sector.proporcionArboles ?? 0;                
            }
            else{
                if ( this.porcentajesArboles[0]) {
                    tiempoCalculado = ((
                        (enMins * porcentajeArboles) / this.porcentajesArboles[0]) / proporcion1) * (sector.proporcionArboles || 0);
                    horas = Math.floor(tiempoCalculado / 60);
                    mins = Math.floor(tiempoCalculado % 60);
                    tiempoStr = (horas < 10 ? '0' + horas : horas) + ':' + (mins < 10 ? '0' + mins : mins );
                    sector.horas_dia_menos_45min = +tiempoStr;
                }
            }
            i++;
        });
        this.calculate();
    }

    generarInforme(){

        let plan: AbonadoInterface = {};
        let i = 0;

        if (this.planArray) { this.planArray.length = 0; }

        (this.productos || []).forEach(producto => {
            if (producto){
                (this.sectores || []).forEach(sector => { 
                    plan = {};

                    plan.id_producto = producto.id ?? '';
                    plan.producto = producto.nombre ?? '';
                    plan.dosis = this.dosis[i] ?? 0;
                    
                    plan.id_sector = sector.id ?? '';
                    plan.sector = sector.nombre;
                    plan.tiempoPre = sector.tiempoPreRiego ?? 0;
                    plan.tiempoPost = +(sector.tiempoPostRiego || 0);
                    plan.tiempoRiego = sector.horas_dia_menos_45min ?? 0;
                    plan.finca = sector.finca;
                    this.finca = sector.finca;
                    this.planArray.push(plan);
                });
         
            }
            i++;
        });

        this.f_ini = this.fecha_inicio.replace('/20', '/');
        this.f_fin = this.fecha_fin.replace('/20', '/');

        this.showPlanAbonado = true;

        const mesStr = this.fecha_inicio.split('/')[1];
        const mesInt = parseInt(mesStr ?? '', 10);

        const meses = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
        this.mes = meses[mesInt - 1] ?? '';
    }

    public imprimirPlan() {
        let fileName = '';
        fileName = this.modelSup.cliente_nombre ?? '';
        if (fileName == null) { fileName = ''; }

        const o = {
            filename: 'abonado_' + fileName.replace('.', '') + '.doc',
            area: 'div.googoose',
            margins: '0.5in',
            display: 'Print',
            lang: 'es-ES',
            download: true
        };

        // @ts-expect-error gogoose es un plugin de JQuery sin compatiblidad con Angular
        $.default(document)?.googoose(o);
    }

    public calculate() {
        let horasDiaMenosPreYPostRaw;

        (this.sectores || []).forEach(sector => {

            sector.proporcionArboles = sector.proporcionArboles || 1;
            sector.tiempoPostRiego = sector.tiempoPostRiego || '0:30';

            const inRiego = this.timeToNumber(sector.horas_dia_menos_45min?.toString() ?? '');
            const postRiego = this.timeToNumber(sector.tiempoPostRiego);

            const minutosQuitandoPreYPost
                = (this.horas_dia * 60)
                - (inRiego.hours * 60)
                - (inRiego.minutes)
                - (postRiego.hours * 60)
                - (postRiego.minutes);

            horasDiaMenosPreYPostRaw = minutosQuitandoPreYPost / 60;
            const horasDiaMenosPreYPostResto = (horasDiaMenosPreYPostRaw % 1);
            const horasDiaMenosPreYPost = horasDiaMenosPreYPostRaw - horasDiaMenosPreYPostResto;

            const minutos45 = minutosQuitandoPreYPost - (horasDiaMenosPreYPost * 60);

            sector.tiempoPreRiego
                = +((horasDiaMenosPreYPost < 10 ? '0' + horasDiaMenosPreYPost : horasDiaMenosPreYPost)
                + ':'
                + (minutos45 < 10 ? '0' + minutos45 : minutos45));


            sector.horas_dia_text
                = (this.horas_dia < 10 ? '0' + this.horas_dia : this.horas_dia)
                + ':00';
        });

        //CALCULO PORCENTAJE BOMBA
        let calcA = 0;
        let calcB = 0;
        if (this.dosis_cuba > 0) {
            calcA = this.capacidad_cuba / this.caudal_inyectora;
            calcA = calcA / this.dosis_cuba;

            //Calcula tiempos de Fertirrigación
            let inRiego, enMins = 0;
            (this.sectores || []).forEach(sector => {
                //CALCULA Nº ARBOLES DE CADA SECTOR EN %
                inRiego = this.timeToNumber(sector.horas_dia_menos_45min?.toString() ?? '');
                enMins = inRiego.hours + (inRiego.minutes / 60);
                calcB += enMins;
            });

            if (calcB === 0) { this.porcentaje_bomba = ''; }
            else { this.porcentaje_bomba = Utils.decimalFormat((calcA / calcB) * 100) || 0; }
        } 
        else {
            this.porcentaje_bomba = 0;
        }
        //debugger;

        // DURACION TOTAL DE LA CUBA
        const totalHoras = this.capacidad_cuba / this.caudal_inyectora;
        const totalDias = totalHoras / this.horas_dia;
        const totalSemanas = totalDias / this.dias_semana;

        const semanasResto = totalSemanas % 1;
        const semanas = totalSemanas - semanasResto;

        const diasRaw = semanasResto * this.dias_semana;
        const diasResto = diasRaw % 1;
        const dias = diasRaw - diasResto;

        const horasRaw = diasResto * this.horas_dia;
        const horasResto = horasRaw % 1;
        const horas = horasRaw - horasResto;

        const minutosRaw = horasResto * 60;
        const minutosResto = minutosRaw % 1;
        const minutos = minutosRaw - minutosResto;


        this.model.duracion_total = [
            semanas > 0 ? semanas + 'sem' : false,
            dias > 0 ? dias + 'd' : false,
            horas > 0 ? horas + 'h' : false,
            minutos > 0 ? minutos + 'm' : false
        ].filter(it => it).join(' ');


        //FECHA CUBA AGOTADA
        this.model.fecha_cuba_agotada = moment(this.fecha_inicio, 'DD/MM/YYYY')
            .add(semanas, 'weeks')
            .add(dias, 'days')
            .add(horas, 'hours')
            .add(minutos, 'minutes')
            .format('DD/MM/YYYY');

        //LITROS CONSUMIDOS ENTRE FECHA INICIO / FECHA FIN
        const semanasRaw = moment(this.fecha_fin, 'DD/MM/YYYY')
            .diff(moment(this.fecha_inicio, 'DD/MM/YYYY'), 'days') / 7;

        //Preguntar si el último día válido es Fecha Fin o Día Semana después de Fecha Fin, 
        //cuando hay horas sobrantes

        const totalLitros = semanasRaw
            * this.horas_dia
            * this.dias_semana
            * this.caudal_inyectora;

        this.model.litros_consumidos = Utils.decimalFormat(totalLitros) + 'L';
    }


    public decimalFormat(input: string) {
        return Utils.decimalFormat(input);
    }

    private validateData(): boolean {
        return [
            ['Capacidad Cuba', this.capacidad_cuba],
            ['Caudal Inyectora', this.caudal_inyectora],
            ['Fecha Inicio', this.fecha_inicio],
            ['Fecha Fin', this.fecha_fin],
            ['Horas Dia', this.horas_dia],
            ['Dias Semana', this.dias_semana],
            ['Nº Árboles', this.numero_arboles]
        ].every(it => {
            let isValid = it[1] !== null && it[1] !== undefined;

            if (it[0] === 'Nº Árboles') {
                isValid = parseInt(it[1] as string, 10) > 0;
            }

            this.errorMessage = isValid ? null : it[0] as string;
            return isValid;
        });
    }

    private timeToNumber(input: string): { hours: number; minutes: number } {
        if (input !== null && input !== undefined) {
            if (input.indexOf(':') !== -1) {

                const parts = input.split(':');
                const hours = parts[0] && parts[0].length > 0 && Utils.isNumber(parts[0]) ? parseInt(parts[0], 10) : 0;
                const minutes = parts[1] && parts[1].length > 0 && Utils.isNumber(parts[1]) ? parseInt(parts[1], 10) : 0;

                return {
                    hours,
                    minutes
                };
            } else {
                return {
                    hours: 0,
                    minutes: input && input.length > 0 && Utils.isNumber(input) ? parseInt(input, 10) : 0
                };
            }
        } else {
            return {
                hours: 0,
                minutes: 0
            };
        }
    }

}
