import {list} from '../../../../../../common/classes/request-builder';
import {StorageManager} from '../../../../../../common/storage-manager.class';

import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { FilteredValue, InputType } from 'src/app/common/components/reactive-form-builder/interfaces/input.interface';
import { 
    Form, 
    FormSection, 
    FormField,
    FormType,
} from 'src/app/common/components/reactive-form-builder/interfaces/form.interface';
import moment from 'moment';
import { Utils } from 'src/app/common/utils';
import { DropdownInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { firstValueFrom } from 'rxjs';
import { RecuperarReferenciaRecetaService } from 'src/app/service/recuperar.service';
import { FitosanitariosEnvironmentDefinition } from '../../environment-variables';
import { FitosanitariosListsDefinition } from './list-variables';
import { FormRequestTypes } from 'src/app/common/components/reactive-form-builder/form-request';
import { Climatologia } from '../climatologia.interface';
import { WeatherService } from 'src/app/service/api/weather/weather';
import { DropdownData } from 'src/app/common/components/reactive-form-builder/interfaces/input.interface';

export class FitosanitariosFormDefinition {

    public formType!: FormType;
    public env: FitosanitariosEnvironmentDefinition = new FitosanitariosEnvironmentDefinition();
    public lists: FitosanitariosListsDefinition = new FitosanitariosListsDefinition();

    public cliente = StorageManager.getClient();
    public usuario = StorageManager.getUser();
    public activateRequireHourForRolUser = this.env.appName === 'ava' && this.usuario.rol !== 'admin';

    public recuperarService!: RecuperarReferenciaRecetaService;
    public weatherService!: WeatherService;
    public model: TareaFitoModel = {
        id_cliente: this.cliente?.id,
        id_usuario: StorageManager.getUser().id
    };
    public ids_productos: string[] | undefined;
    public horaInicio = '';
    public horaFin = '';

    public referenciaReceta: string | null = null;
    public tag = '';
    
    public formFieldsHidden!: FormSection<object>;
    public formFieldsTiempo!: FormSection<object>;
    public formFieldsKM!: FormSection<object>;
    public formFieldsLugar!: FormSection<object>;
    public formFieldsTratamiento!: FormSection<object>;
    public formFieldsTractor!: FormSection<object>;
    public formFieldsInnovia!: FormSection<object>;
    public formFieldsFinal!: FormSection<object>;
    public formFieldsClima!: FormSection<object>;
    public formFieldsMezclasSobrantes!: FormSection<object>;
    public formFieldsOrdenTrabajo!: FormSection<object>;
    public formFieldsObs!: FormSection<object>;


    public formFields!: Form<TareaFitoModel>;
    public fieldNames: FormField<any>[] = [];
    public fieldLabels: string[] = [];

    public now = new Date();
    public day = this.now.getDate().toString().padStart(2, '0');
    public month = (this.now.getMonth() + 1).toString().padStart(2, '0');
    public year = this.now.getFullYear().toString();
    public hours = this.now.getHours().toString().padStart(2, '0');
    public minutes = this.now.getMinutes().toString().padStart(2, '0');


    constructor(type: FormRequestTypes, model: Record<string,string>, table: (string[] | string[][])[] | null) {
        this.formType = +type;
        this.horaInicio = model['hora_inicio'] ?? '00:00';
        this.horaFin = model['hora_fin'] ?? '00:00';
        
        this.ids_productos = model['ids_productos']?.split(';');
        this.referenciaReceta = this.formType === 2 ? null : model['referencia_receta'] ?? '';
        this.formFields = {
            name: 'fitosanitarios',
            type: this.formType,
            sections: this.getFormSections(),
            table: {data: table?.[0], style: table?.[1]},
            signed: this.env.appName === 'donjardin' || this.env.appName === 'coliberica' || this.env.appName === 'demogardengest'
        };
    }

    public getDefaultPresionValues() {
        switch (this.env.appName) {
        case 'laplana':
            return this.lists.presionLaplana;
        default:
            return this.lists.presionAll;
        }
    }

    public checkIfProductIsValid(fecha_caducidad: string) {
        const diff = moment(new Date()).diff(Utils.toDate(fecha_caducidad), 'months');
    
        if (diff > 12) {
            return '* ';
        } else if (diff > 6) {
            return '* ';
        } else if (diff >= 0) {
            return '* ';
        }
    
        return '';
    }

    public getTiempoFields() {
        const tiempoFields: FormField<any>[] = [
            {
                name: 'fecha_inicio',
                vinculatedFields: undefined,
                label: (this.env.appName === 'armentia') ? 
                    'Fecha inicio' : 
                    'Fecha Receta',
                input: {type: InputType.CALENDAR, isEditable: true},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: !this.env.showTareasMto
                },
                order: 1
            },
        ];
        this.fieldNames = this.fieldNames.concat(tiempoFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(tiempoFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return tiempoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getKMFields(isSectionVisible: boolean) {
        const kmFields: FormField<any>[] = [
            {
                name: 'punto_km_inicio',
                vinculatedFields: undefined,
                label: 'Punto Km inicial',
                input: {type: InputType.TEXT, isEditable: true},
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 1
            },
            {
                name: 'punto_km',
                vinculatedFields: undefined,
                label: 'Punto Km final',
                input: {type: InputType.TEXT, isEditable: true},
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 2
            }
        ];
        this.fieldNames = this.fieldNames.concat(kmFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(kmFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return kmFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getLugarFields() {
        const lugarFields: FormField<any>[] = [
            { 
                name: 'id_cliente',
                vinculatedFields: undefined,
                label: this.env.clienteName,
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.sectores,
                    isEditable: true,
                    dropdownConfiguration: {valuePrimaryKey: 'id'}
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: true,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.hasClients && this.env.userCanSeeClientes && this.formType === FormType.DUPLICATION
                },
                order: 1
            },
            {
                name: this.formType < 3 ? 'id_finca' : 'finca',
                vinculatedFields: ['id_cliente'],
                label: this.env.fincaName,
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.fincas,
                    isEditable: true,
                    dropdownConfiguration: {
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<FincasModel>) => {
                            const storedClient = StorageManager.getClient();
                            return storedClient ? it.value?.id_cliente === storedClient.id : true;
                        },
                    }
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: true,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.hasFincas && this.env.showTareasFincas
                },
                order: 2
            },
            {
                name: this.formType < 3 ? 'id_sector' : 'sectores_nombres',
                vinculatedFields: ['id_finca'],
                label: this.env.sectorName,
                input: {
                    type: this.env.showTareasFitoSectorMultiple ? InputType.MULTISELECT_SEARCH : InputType.DROPDOWN,
                    data: this.lists.sectores,
                    isEditable: true,
                    dropdownConfiguration: {
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<SectorModel>) =>  {
                            if (!it.value?.nombre) {
                                return;
                            }
        
                            return it.value.id_finca === this.lists.fincas?.selected?.id;
                        }
                    }
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasSector
                },
                order: 3
            },
            {
                name: this.formType < 3 ? 'ids_parcelas' : 'parcelas_nombres',
                vinculatedFields: ['id_finca', 'id_sector'],
                label: this.env.parcelasName,
                input: {
                    type: InputType.MULTISELECT_SEARCH,
                    data: this.lists.parcelas,
                    isEditable: true,
                    dropdownConfiguration: {
                        summation: {
                            summand: 'superficie_cultivada', 
                            name: this.env.tipoSuperficieParcelas,
                            uds: this.env.textoSuperficie
                        },
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<ParcelasModel>) => {
                            if (it.value?.activo !== '1') {
                                return false;
                            }
        
                            const sectoresSeleccionados = this.lists.sectores.selected;
                            const fincaSeleccionada = this.lists.fincas.selected;
        
                            if (sectoresSeleccionados && +(sectoresSeleccionados.length) > 0) {
                                if (this.env.showTareasFitoSectorMultiple && Array.isArray(sectoresSeleccionados)) {
                                    return sectoresSeleccionados.some((sector: SectorModel) => sector?.id === it.value?.id_sector);
                                } else {
                                    return it.value.id_sector === (sectoresSeleccionados as SectorModel).id;
                                }
                            }
        
                            return it.value.id_finca === fincaSeleccionada?.id;
                        },
                    }
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: this.env.appName !== 'donjardin',
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasParcela
                },
                order: 4
            }
        ];
        this.fieldNames = this.fieldNames.concat(lugarFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(lugarFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return lugarFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getTratamientoFields() {
        const tratamientoFields: FormField<any>[] = [
            {
                name: this.formType < 3 ? 'ids_trabajadores' : 'trabajadores_nombres',
                vinculatedFields: ['id_cliente'],
                label: 'Profesionales',
                input: {
                    type: InputType.MULTISELECT_SEARCH,
                    data: this.lists.trabajadores,
                    isEditable: true,
                    dropdownConfiguration: {
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<MantenimientoModel>) => {
                            if (it.value?.activo !== '1') {
                                return false;
                            }
                            
                            this.showExpirationIdentifier(it);
                                                
                            
                            return true;
                            
                        },
                    }
                },
                width: 6,
                updateValue: (it: FormField<DropdownInterface<MantenimientoModel>>) => {
                    const FilteredValue = 
                    (it.input.data as DropdownData<DropdownInterface<MantenimientoModel>>)?.filtered ?? [];

                    if (this.env.showTareasMto){
                        const userInfo = StorageManager.getUser();
                        if (userInfo.rol === 'user' && this.formType === FormType.CREATION){
                        
                            return (FilteredValue as DropdownInterface<MantenimientoModel>[])
                                .find(trabajador => trabajador.value?.id === userInfo.id)?.value;
                        }
                    }

                    return (FilteredValue as DropdownInterface<MantenimientoModel>[])
                        .find(trabajador => trabajador.value?.id_maquina === this.model.id_maquinaria)?.value;
                },
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 1
            },
            {
                name: this.formType < 3 ? 'ids_asignados' : 'nombres_asignados',
                vinculatedFields: undefined,
                label: 'Asignador',
                input: {
                    type: InputType.MULTISELECT, 
                    data: this.lists.usuarios,
                    isEditable: true,
                    dropdownConfiguration: {valuePrimaryKey: 'id'}
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: true,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: (this.env.showAssignUserTasksFitosanitario || this.env.showAssignUserInAllTasks) && 
                        this.usuario.rol === 'admin'
                },
                order: 2
            },
            {
                name: 'momento_aplicacion',
                vinculatedFields: undefined,
                label: 'Momento de aplicación',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.momentosAplicacion, 
                    isEditable: true
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showFitoMomentoAplicacion
                },
                order: 3
            },
            {
                name: 'coste_tratamiento',
                vinculatedFields: undefined,
                label: 'Coste (€)',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showFitoCosteTratamiento
                },
                order: 4
            },
            {
                name: 'tipo_tratamiento',
                vinculatedFields: undefined,
                label: 'Tipo de tratamiento',
                input: {
                    type: InputType.DROPDOWN, 
                    data: this.lists.tiposTratamiento,
                    isEditable: true
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showFitoTipoTratamiento
                },
                order: 5
            },
            {
                name: this.formType < 3 ? 'id_tratamiento' : 'tratamiento_nombre',
                vinculatedFields: ['id_cliente'],
                label: 'Tratamientos',
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.tratamientos,
                    isEditable: true,
                    dropdownConfiguration: {
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<TratamientosModel>) => {
                            if (!it?.value || (!it?.value?.nombre || it?.value.nombre === 'null' || it.value.nombre.trim().length === 0)) {
                                return false;
                            }
        
                            this.showExpirationIdentifier(it);
        
                            const allTratamientosAllowed =
                                (this.env.mostrarTodosTratamientos ||
                                (['abonosfolques', 'agricar', 'lluchychulbi'].includes(this.env.appName)) ||
                                (!this.env.hasClients || this.formType === FormType.DUPLICATION || !this.env.userCanSeeClientes));
        
                            const idCliente =
                                this.lists.fincas.selected?.id_cliente ||
                                this.lists.clientes.selected?.id ||
                                StorageManager.getClient()?.id;
        
                            return allTratamientosAllowed ? true : it.value.id_cliente === idCliente;
        
                        },
                    }
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 6
            },
            {
                name: 'ph_caldo',
                vinculatedFields: undefined,
                label: 'pH caldo',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.ph_caldo, 
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showPhCaldo
                },
                order: 7
            },
            {
                name: 'litros',
                vinculatedFields: undefined,
                label: this.env.appName === 'momparler' ? 'Litros/m²' : this.env.appName === 'agrosalvi' ? 'Volumen (gr o litros agua)' :
                    this.env.appName === 'onda' ? 'Litros' : this.env.appName === 'hernandorena' ? 'Caldo (litros)' :
                        this.env.appName !== 'innovia' ? 
                            'Litros/' + this.env.textoSuperficie + ((this.env.appName === 'vginer' || 
                                this.env.appName === 'tomega') ? ' (recomendación)' : '') :
                            'Litros Caldo',
                input: {type: this.env.showTareasMto ? InputType.NUMERIC : InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 8
            },
            {
                name: 'mochila',
                vinculatedFields: undefined,
                label: 'Es mochila',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoMochila
                },
                order: 9
            },
            {
                name: 'sup_tratada',
                vinculatedFields: undefined,
                label: 'Superficie tratada',
                input: {
                    type: InputType.DROPDOWN,
                    data: list(['...', null], 'T', 'P', 'F'),
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'pyf'
                },
                order: 10
            },
            {
                name: 'orden_asesor',
                vinculatedFields: undefined,
                label: 'Orden asesor',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 6,
                updateValue: (it: FormField<object>) => {
                    if (!this.referenciaReceta) {                        
                        if (this.model.fecha_inicio && this.model.orden_asesor) {
                            const fecha = this.parseFecha(this.model.fecha_inicio);
                        
                            firstValueFrom(this.recuperarService.getRecuperarReferencia(fecha.getFullYear()))
                                .then((resp) => {
                                    const numero = this.padZero(3, resp?.data[0]?.numero ?? '');
                                    const referenciaReceta = `${fecha.getFullYear()}-${numero}`;
                                    it.label = `Orden asesor - ${referenciaReceta}`;
                                    it.input.isEditable = false;
                                    this.referenciaReceta = referenciaReceta;
                                    return;
                                })
                                .catch((e) => console.error('Error en getRecuperarReferencia:', e));
                        } else {
                            it.label = 'Orden asesor';
                            this.model.referencia_receta = '';
                        }                    
                    } else {
                        it.input.isEditable = false;
                        it.label = `Orden asesor - ${this.referenciaReceta}`;
                    }
                },
                conditions: {
                    isRememberedOnDuplicate: false,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'pyf'
                },
                order: 11
            },
            {
                name: 'aplicacion',
                vinculatedFields: undefined,
                label: 'Modo de aplicación',
                input: {
                    type: InputType.DROPDOWN, 
                    data: list(['...', null], 'Arcén', 'Medianera'),
                    isEditable: true
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoAplicacion
                },
                order: 12
            },
            {
                name: 'procura',
                vinculatedFields: undefined,
                label: 'Procura',
                input: { 
                    type: InputType.DROPDOWN_SEARCH,
                    data: list(['...', null], 'Procura', 'Media procura', 'Libre'),
                    isEditable: true 
                },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasProcura
                },
                order: 13
            },
            {
                name: 'tipo_plaga',
                vinculatedFields: undefined,
                label: 'Tipo de plaga', 
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.tipo_plaga,
                    isEditable: true
                }, 
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTipoPlaga
                },
                order: 14
            },
            {
                name: 'litros_totales',
                vinculatedFields: undefined,
                label: 'Litros Totales',
                input: { type: InputType.TEXT, isEditable: true },
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'gragon'
                },
                order: 15
            },
            {
                name: 'motivo',
                vinculatedFields: undefined,
                label: 'Motivo', 
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.motivo,
                    isEditable: true
                }, 
                width: 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showMotivoFito && this.env.appName !== 'gragon'
                },
                order: 16,
            },
            {
                name: 'codigo_justificacion',
                vinculatedFields: undefined,
                label: 'Justificación de la Actuación',
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.justificaciones,
                    isEditable: true,
                    dropdownConfiguration: {valuePrimaryKey: 'codigo'}
                },
                width: this.env.appName === 'donjardin' ? 4 : 6,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 17
            },
            {
                name: 'desplazamiento',
                vinculatedFields: undefined,
                label: 'Desplazamiento',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.desplazamiento,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showDesplazamientoObra
                },
                order: 18
            },
            {
                name: 'mano_obra',
                vinculatedFields: undefined,
                label: 'Mano Obra',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.mano_obra,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showDesplazamientoObra
                },
                order: 19
            },
            {
                name: 'observaciones',
                vinculatedFields: undefined,
                label: 'Recomendación',
                input: {
                    type: InputType.TEXTAREA, 
                    isEditable: true,
                    textAreaConfiguration: {
                        hasVoiceTranscription: true, 
                        canTakePhoto: false, 
                        photoField: undefined
                    }
                },
                width: this.env.appName === 'donjardin' ? 6 : 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoObservaciones
                },
                order: 20
            },
            {
                name: 'momento_aplicacion',
                vinculatedFields: undefined,
                label: 'Momento de aplicación',
                input: { type: InputType.TEXT, isEditable: true },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showMomentoAplicacion
                },
                order: 21
            },
            {
                name: 'limpieza_epis',
                vinculatedFields: undefined,
                label: 'Limpieza de EPIs',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'vginer' || this.env.appName === 'tomega'
                },
                order: 22
            },
            {
                name: 'dia_limpieza',
                vinculatedFields: undefined,
                label: 'Día de limpieza',
                input: {type: InputType.CALENDAR, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'vginer' || this.env.appName === 'tomega'
                },
                order: 23
            },
            {
                name: 'it',
                vinculatedFields: undefined,
                label: 'Instrucción técnica',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoIT
                },
                order: 24
            },
            {
                name: 'lote',
                vinculatedFields: undefined,
                label: this.env.appName === 'momparler' ? 'N° Pedido' : 'Lote',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoLote
                },
                order: 25
            },
            {
                name: 'tecnica',
                vinculatedFields: undefined,
                label: 'Técnica',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.tecnica,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'pyf'
                },
                order: 26
            },
            {
                name: 'notas_cliente',
                vinculatedFields: undefined,
                label: 'Notas cliente',
                input: {
                    type: InputType.TEXTAREA,
                    defaultValue: this.formType === FormType.CREATION ? StorageManager.getClient()?.notas : null,
                    isEditable: false,
                },
                width: this.env.appName === 'donjardin' ? 6 : 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: true,
                    hasUndoEnabled: false,
                    isVisible: this.env.showClientesNotas
                },
                order: 27
            },
            {
                name: 'estado_tarea',
                vinculatedFields: undefined,
                label: 'Estado de la Tarea',
                input: {
                    type: InputType.DROPDOWN,
                    data: this.lists.estadoTarea,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'onda' || this.env.appName === 'lacooperativa'
                },
                order: 28
            },
            {
                name: 'empresa_pagadora',
                vinculatedFields: undefined,
                label: 'Empresa Pagadora',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'onda' || this.env.appName === 'lacooperativa'
                },
                order: 29
            },
        ];
        this.fieldNames = this.fieldNames.concat(tratamientoFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(tratamientoFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return tratamientoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getTractorFields() {
        const tractorFields: FormField<any>[] = [
            {
                name: this.formType < 3 ? 'id_maquinaria' : 'maquinaria',
                vinculatedFields: this.env.appName === 'pyf' ? ['ids_cliente'] : undefined,
                label: 'Maquinaria',
                input: {
                    type:  
                        (this.env.appName === 'bravosol' || this.env.appName === 'agrosalvi') ? 
                            InputType.MULTISELECT : InputType.DROPDOWN_SEARCH,
                    data: this.lists.maquinarias,
                    isEditable: true,
                    dropdownConfiguration: {
                        valuePrimaryKey: 'id',
                        filter: (it: FilteredValue<MaquinariaModel>) => {
                            if (it.value?.activo !== '1') {
                                return false;
                            }
        
                            this.showExpirationIdentifier(it);
                            
                            if (this.env.appName === 'pyf' && StorageManager.getClient()?.id) {
                                return it.value.id_cliente === StorageManager.getClient().id;
                            }
                            
                            return true;
                        },
                    }
                },
                width: 4,
                updateValue: () => this.lists.trabajadores.selected?.id_maquina ?? '',
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoMaquinarias
                },
                order: 1
            },
            {
                name: 'velocidad',
                vinculatedFields: undefined,
                label: 'Velocidad\xa0(Km/h)',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorVelocidad
                },
                order: 2
            },
            {
                name: 'marcha',
                vinculatedFields: undefined,
                label: 'Marcha',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorMarcha
                },
                order: 3
            },
            {
                name: 'rpm',
                vinculatedFields: undefined,
                label: 'RPM',
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.rpmValues,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorRPM
                },
                order: 4
            },
            {
                name: 'presion',
                vinculatedFields: undefined,
                label: 'Presión\xa0(atm-bar)',
                input: {
                    type: (['simat', 'laplana'].includes(this.env.appName)) ? InputType.DROPDOWN : InputType.NUMERIC,
                    data: ['simat', 'laplana'].includes(this.env.appName) ? this.getDefaultPresionValues() : {},
                    isEditable: true,
                    dropdownConfiguration: {
                        swap: (it: FormField<object>) => {
                            if (this.env.appName === 'simat') {
                                switch (this.lists.maquinarias.selected?.tipo) {
                                case 'Herbicida':
                                    it.input.data = this.lists.presionHerbicida;
                                    break;
                                case 'Turboatomizador':
                                    it.input.data = this.lists.presionTurbo;
                                    break;
                                case 'Disparador':
                                    it.input.data = this.lists.presionDisparador;
                                    break;
                                default:
                                    it.input.data = this.lists.presionAll;
                                    break;
                                }
                            }
                        },
                    }
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorPresion
                },
                order: 5
            },
            {
                name: 'boquillas',
                vinculatedFields: undefined,
                label: 'Boquillas',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorBoquillas
                },
                order: 6
            },
            {
                name: 'km_recorridos',
                vinculatedFields: undefined,
                label: 'Km recorridos',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTractorKmRecorridos
                },
                order: 7
            }
        ];
        this.fieldNames = this.fieldNames.concat(tractorFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(tractorFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return tractorFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getInnoviaFields(isSectionVisible: boolean) {
        const innoviaFields: FormField<any>[] = [
            {
                name: 'litros1',
                vinculatedFields: undefined,
                label: this.formType === FormType.EXPORT ? 'Litros 1': 'Litros ',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                turnVisible: (it: FormField<object>) => this.changeLabel(this.ids_productos?.[0], it),
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 1
            },
            {
                name: 'litros2',
                vinculatedFields: undefined,
                label: this.formType === FormType.EXPORT ? 'Litros 2': 'Litros ',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                turnVisible: (it: FormField<object>) => this.changeLabel(this.ids_productos?.[1], it),
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 2
            },
            {
                name: 'litros3',
                vinculatedFields: undefined,
                label: this.formType === FormType.EXPORT ? 'Litros 3': 'Litros ',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                turnVisible: (it: FormField<object>) => this.changeLabel(this.ids_productos?.[2], it),
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 3
            },    
        ];
        this.fieldNames = this.fieldNames.concat(innoviaFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(innoviaFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return innoviaFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getFinalFields() {
        const finalFields: FormField<any>[] = [
            {
                name: 'hora_inicio',
                vinculatedFields: undefined,
                label: 'Hora Inicio',
                input: {type: InputType.TIME, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: !this.env.showTareasMto,
                    isRequired: this.activateRequireHourForRolUser,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto || this.env.showTareasFitoHoras || this.env.timeStartStopTareas
                },
                order: 1
            },
            {
                name: 'hora_fin',
                vinculatedFields: undefined,
                label: 'Hora Fin',
                input: {
                    type: InputType.TIME, 
                    defaultValue: 
                    (this.env.horaFechaFinActualPorDefecto || this.env.showTareasMto && this.usuario.rol === 'user') ? 
                        this.formType !== FormType.EDITION ? `${this.hours}:${this.minutes}` : 
                            undefined : undefined,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: !this.env.showTareasMto,
                    isRequired: this.activateRequireHourForRolUser,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto || this.env.showTareasFitoHoras || this.env.timeStartStopTareas
                },
                order: 2
            },
            {
                name: 'horas_totales',
                vinculatedFields: undefined,
                label: 'Horas',
                input: {type: InputType.ONLY_TEXT, isEditable: true},
                width: 4,
                updateValue: () => this.formatoHorasMinutos(
                    (this.model.hora_inicio || this.horaInicio) || '00:00', 
                    (this.model.hora_fin?.toString() || this.horaFin) || '00:00'),
                conditions: {
                    isRememberedOnDuplicate: !this.env.showTareasMto,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto || this.env.tareasHorasTotales || this.env.showTareasFitoHoras,
                },
                order: 3
            },
            {
                name: 'fecha_fin',
                vinculatedFields: undefined,
                label: (this.env.appName === 'armentia') ? 'Fecha fin' : 'Fecha Aplicación',
                input: {
                    type: InputType.CALENDAR,
                    defaultValue: (this.env.horaFechaFinActualPorDefecto) ? 
                        this.formType !== FormType.EDITION  && 
                    (this.env.appName !== 'sirga' && this.env.appName !== 'yagoaznar') ? `${this.day}/${this.month}/${this.year}` : 
                            undefined : undefined,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: false,
                    isRequired: this.env.appName === 'agrosalvi' && this.usuario.rol === 'user',
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 4
            },
            {
                name: 'fin_fecha',
                vinculatedFields: undefined,
                label: 'Fecha fin',
                input: {type: InputType.CALENDAR, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'fotoagricultura',
                },
                order: 5
            },
            {
                name: 'fecha_recoleccion',
                vinculatedFields: undefined,
                label: 'Fecha Recolección',
                input: {type: InputType.CALENDAR, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'vginer' || this.env.appName === 'tomega'
                },
                order: 6
            },
            {
                name: 'eficacia',
                vinculatedFields: undefined,
                label: 'Eficacia del Tratamiento',
                input: {
                    type: InputType.DROPDOWN_SEARCH,
                    data: this.lists.eficacia,
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: false,
                    isRequired: this.env.appName === 'agrosalvi' && this.usuario.rol === 'user',
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true,
                },
                order: 7
            },
            {
                name: 'aplicado',
                vinculatedFields: undefined,
                label: 'Aplicado',
                input: {
                    type: InputType.SWITCH,
                    defaultValue: (this.env.showTareasMto && this.usuario.rol === 'user') ? '1' : '0',
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: false,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: (this.env.appName === 'agrosalvi' && this.usuario.rol !== 'user') || this.env.showAplicadoSwitch
                },
                order: 8
            },
            {
                name: 'horas_totales',
                vinculatedFields: undefined,
                label: 'Horas',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'fotoagricultura'
                },
                order: 9
            },
            {
                name: 'validado',
                vinculatedFields: undefined,
                label: 'Validado',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showFitoValidado && (this.usuario.rol === 'admin')
                },
                order: 10
            },
            {
                name: 'soleado',
                vinculatedFields: undefined,
                label: 'Soleado',
                input: {
                    type: InputType.DROPDOWN,
                    data: list(['...', null], 'Cubierto', 'Semicubierto', 'Despejado'),
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoSoleado
                },
                order: 11
            },
            {
                name: 'cubas_totales',
                vinculatedFields: undefined,
                label: 'Cubas totales',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'vginer' || this.env.appName === 'tomega'
                },
                order: 12
            },
            {
                name: 'litros_aplicados',
                vinculatedFields: undefined,
                label: this.env.appName === 'ava' ? 'Vol. Caldo Gastado' : 'Litros aplicados',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoLitrosAplicados
                },
                order: 13
            },
            {
                name: 'litros_aplicados_ha',
                vinculatedFields: undefined,
                label: 'Litros aplicados/Ha',
                input: {type: InputType.ONLY_TEXT, isEditable: true},
                width: 4,
                updateValue: () => {
                    const superficie = parseFloat((this.model.sup_cultivada || '0').toString().replace(',', '.')) || 0;
                    const litrosAplicados = this.model.litros_aplicados || 0;
                    
                    let litrosAplicadosHa = +Utils.decimalFormat(+litrosAplicados / superficie);

                    if (!litrosAplicadosHa || !isFinite(litrosAplicadosHa)) {
                        litrosAplicadosHa = 0;
                    }

                    return litrosAplicadosHa.toString();
                },
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasFitoLitrosAplicados && !this.env.showCostes
                },
                order: 14
            },
            {
                name: 'facturado',
                vinculatedFields: undefined,
                label: 'Facturado',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'agrosalvi'
                },
                order: 15
            },
            {
                name: 'factura_albaran',
                vinculatedFields: undefined,
                label: 'Factura/Albarán',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'agrosalvi'
                },
                order: 16
            }
        ];
        this.fieldNames = this.fieldNames.concat(finalFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(finalFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return finalFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getClimaFields(isSectionVisible: boolean) {
        const climaFields: FormField<any>[] = [
            {
                name: 'temperatura',
                vinculatedFields: undefined,
                label: 'Temperatura ℃',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 1
            },
            {
                name: 'hr',
                vinculatedFields: undefined,
                label: this.env.appName === 'torrepacheco' ? 'Humedad %' : 'Humedad R.',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 2
            },
            {
                name: 'viento',
                vinculatedFields: undefined,
                label: 'Viento Km/h',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 3
            },
            {
                name: 'precip',
                vinculatedFields: undefined,
                label: 'Precipit. L/m²',
                input: {type: InputType.TEXT, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 4
            },
            {
                name: 'clima',
                vinculatedFields: undefined,
                label: 'Clima',
                input: {
                    type: InputType.DROPDOWN,
                    data: list(['...', null], 'Cubierto', 'Semidespejado', 'Despejado'),
                    isEditable: true
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible && (this.env.appName === 'vginer' || this.env.appName === 'tomega')
                },
                order: 5
            },
            {
                name: 'climatologia',
                vinculatedFields: undefined,
                label: '',
                input: {
                    type: InputType.SELECT_BUTTON,
                    data: list( ['Obtener clima', null] ),
                    isEditable: true,
                    selectButtonConfiguration: {
                        isDisabled: false,
                        isMultiSelect: false,
                        onClick: (it: FormField<any>) => this.checkWeatherVariables(it)
                    }
                },
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: true,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 6
            },
        ];
        this.fieldNames = this.fieldNames.concat(climaFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(climaFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return climaFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getMezclasSobrantesFields(isSectionVisible: boolean) {
        const mezcalsSobrantesFields: FormField<any>[] = [
            {
                name: 'cubeto_evaporacion',
                vinculatedFields: undefined,
                label: 'Cubeto evaporación',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 1
            },
            {
                name: 'punto_carga',
                vinculatedFields: undefined,
                label: 'Punto carga',
                input: {type: InputType.SWITCH, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 2
            },
            {
                name: 'litros_sobrante',
                vinculatedFields: undefined,
                label: 'Litros caldo sobrante',
                input: {type: InputType.NUMERIC, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible,
                },
                order: 3
            },
            {
                name: 'dia_tratamiento',
                vinculatedFields: undefined,
                label: 'Día tratamiento',
                input: {type: InputType.CALENDAR, isEditable: true},
                width: 4,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible
                },
                order: 4
            },
        ];
        this.fieldNames = this.fieldNames.concat(mezcalsSobrantesFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(mezcalsSobrantesFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return mezcalsSobrantesFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getOrdenTrabajoFields(isSectionVisible: boolean) {
        const ordenTrabajoFields: FormField<any>[] = [
            {
                name: 'orden_trabajo',
                vinculatedFields: undefined,
                label: 'Orden de trabajo',
                input: { 
                    type: InputType.TEXTAREA, 
                    isEditable: this.usuario.rol === 'admin',
                    textAreaConfiguration: {
                        hasVoiceTranscription: true,
                        canTakePhoto: true,
                        photoField: 'imagen'
                    },
                },
                width: 12,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible,
                },
                order: 1
            },
        ];
        this.fieldNames = this.fieldNames.concat(ordenTrabajoFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(ordenTrabajoFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return ordenTrabajoFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    public getObsFields(isSectionVisible: boolean) {
        const obsFields: FormField<any>[] = [
            {
                name: 'observaciones',
                vinculatedFields: undefined,
                label: 'Observaciones',
                input: { 
                    type: InputType.TEXTAREA, 
                    isEditable: true,
                    textAreaConfiguration: {
                        hasVoiceTranscription: true,
                        canTakePhoto: true,
                        photoField: 'image_after'
                    }
                },
                width: 12,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: isSectionVisible,
                },
                order: 1
            }
        ];
        this.fieldNames = this.fieldNames.concat(obsFields.filter((it) => it.conditions.isVisible));
        this.fieldLabels = this.fieldLabels.concat(obsFields.filter((it) => it.conditions.isVisible).map(it => it.label));
        return obsFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }
    
    private formatoHorasMinutos(horaEntrada: string, horaSalida: string): string {

        if (!this.isValidTime(horaEntrada) || !this.isValidTime(horaSalida)) {
            console.error('El formato de hora no es válido. Asegúrese de que el formato sea HH:mm');
            return '00:00';
        }
    
        const duracion = moment.duration(
            moment(horaSalida, 'HH:mm').diff(moment(horaEntrada, 'HH:mm'))
        );
    
        return `${this.padZero(2, Math.floor(duracion.asHours()).toString())}:${this.padZero(2, duracion.minutes().toString())}`;
    }
    
    private isValidTime(hora: string): boolean {
        return moment(hora, 'HH:mm', true).isValid();
    }

    private showExpirationIdentifier(it: FilteredValue<MaquinariaModel | MantenimientoModel | TratamientosModel>) {
        if (it.label.includes('*')) {
            return;
        }
    
        // Si no está activo, marcar como ** (doble asterisco)
        if (!it.value?.activo) {
            it.label = `** ${it.label}`;
            return;
        }
    
        // Verificar fecha de caducidad general (fecha_caducidad o fecha_ultima_inspeccion)
        const fechaCaducidad = this.obtenerFechaCaducidad(it.value);
        if (!fechaCaducidad) {
            return;
        }
    
        const hoy = moment();
        const tresMeses = moment().add(3, 'months');
        const tiempoAntes = this.comprobarTiempoAntes(fechaCaducidad);
    
        // Evaluar estado de la fecha de caducidad
        if (fechaCaducidad.isBefore(hoy)) {
            it.label = `** ${it.label}`; // Expirado
        } else if (fechaCaducidad.isBefore(tresMeses) || tiempoAntes) {
            it.label = `* ${it.label}`; // Próximo a caducar
        }
    }
    
    private obtenerFechaCaducidad(value: MaquinariaModel): moment.Moment | null {
        if (value?.fecha_caducidad) {
            return moment(Utils.toDate(value.fecha_caducidad));
        } 
    
        if (value?.fecha_ultima_inspeccion) {
            const auxFechaCad = (value.fecha_ultima_inspeccion as string).split('/');
            const edadMaquinaria = moment().year() - moment(value.fecha_adquisicion, 'DD/MM/YYYY').year();
    
            const incremento = edadMaquinaria > 10 || isNaN(edadMaquinaria) 
                ? parseInt(auxFechaCad[2] ?? '', 10) >= 2020 ? 3 : 5 
                : 10;
    
            auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + incremento).toString();
    
            return moment(`${auxFechaCad[0]}/${auxFechaCad[1]}/${auxFechaCad[2]}`, 'DD/MM/YYYY');
        }
    
        return null;
    }
    
    private comprobarTiempoAntes(fechaCaducidad: moment.Moment): boolean {
        const margen = this.env.appName === 'prodalbar' ? '1 years' : '3 months';
        return fechaCaducidad.isBefore(moment().add(moment.duration(margen)));
    }
    

    private parseFecha(fechaInicio: string | Date): Date {
        if (fechaInicio.toString().length === 10) {
            const [dia, mes, anio] = fechaInicio.toString().split('/').map(Number);
            return new Date(anio ?? 0, (mes ?? 0) - 1, dia);
        }
        return new Date(fechaInicio);
    }
    
    private padZero(maxLength: number, numero: string): string {
        return numero.padStart(maxLength, '0');
    }  

    private changeLabel(producto: string | undefined, it: FormField<object>) {
        if (producto === undefined || producto === '') {
            it.conditions.isVisible = false;
            return;
        }

        it.label = 'Litros ' + this.lists.productos.values.find(
            product => product.value?.id === producto
        )?.value?.['nombre'] || 'no localizado';
        it.conditions.isVisible = true;
    }

    private checkWeatherVariables(field: FormField<any>): any {
        const fecha = this.getFecha() ?? '';
        const finca = this.getFinca();
        const parcela = this.getParcela();
        const alertMessage = this.getAlertMessage(fecha, finca);
        const selectButton = field.input?.selectButtonConfiguration;
                
        if (alertMessage) {
            alert(alertMessage);
            return null;
        }
    
        if (selectButton) {
            selectButton.isDisabled = true;   
        }    
        return firstValueFrom(this.weatherService.getWeatherInfo(fecha, finca, parcela))
            .then((resp: { status: number; mensaje: string; data?: Climatologia }) => {
                if (resp.status === 400) {
                    alert(resp.mensaje);
                    if (selectButton) {
                        selectButton.isDisabled = false;   
                    }                    
                    return null;
                }
    
                if (resp.data?.prevision) {
                    const hourlyData = resp.data.prevision.hourly[0];
                    return {
                        temperatura: hourlyData?.temperature,
                        precip: hourlyData?.precip,
                        hr: hourlyData?.humidity,
                        viento: hourlyData?.wind_speed,
                    };
                } else {
                    alert('No se han encontrado datos');
                    if (selectButton) {
                        selectButton.isDisabled = true;   
                    }                    
                    return null;
                }
            })
            .catch((error: string) => {
                alert('No hay datos en esta fecha');
                console.error('Error:', error);
                if (selectButton) {
                    selectButton.isDisabled = true;   
                }
                return null;
            });
    }
    
    // Función auxiliar para obtener la fecha
    private getFecha(): string | null {
        let fecha = this.model.fecha_inicio || this.model.fecha_fin || null;
    
        if (fecha && typeof fecha === 'object') {
            fecha = moment(fecha).format('DD/MM/YYYY');
        }
    
        return fecha;
    }
    
    // Función auxiliar para obtener la finca
    private getFinca(): string {
        return this.model.id_finca
            ? (this.model.id_finca as unknown as FincasModel)?.id ?? ''
            : '';
    }
    
    // Función auxiliar para obtener la parcela
    private getParcela(): string {
        console.log(this.model.ids_parcelas);
    
        if (Array.isArray(this.model.ids_parcelas) && this.model.ids_parcelas.length > 0) {
            const primeraParcela = this.model.ids_parcelas[0];
            return primeraParcela?.id ?? '0'; // Assegurem que 'id' existeix
        }
    
        return '0';
    }
    
    // Función auxiliar para generar el mensaje de alerta
    private getAlertMessage(fecha: string | null, finca: string): string {
        let message = '';
    
        if (!fecha) {
            message += 'No hay seleccionadas ni Fecha de inicio ni Fecha de fin.\n';
        }
    
        if (!finca) {
            message += 'No hay finca seleccionada.';
        }
    
        return message;
    }
    
    private getFormSections() {
        const sections: FormSection<any>[] = [
            {
                name: '',
                width: 12,
                isVisible: true,
                fields: this.getTiempoFields(),
                order: 1
            },
            {
                name: '',
                width: 12,
                isVisible: this.env.showTareasFitoPuntoKM,
                fields: this.getKMFields(this.env.showTareasFitoPuntoKM),
                order: 2
            }, 
            {
                name: '',
                width: 12,
                isVisible: true,
                fields: this.getLugarFields(),
                order: 3
            },
            {
                name: '',
                width: 12,
                isVisible: true,
                fields: this.getTratamientoFields(),
                order: 4
            },
            {
                name: 'Condiciones meteorológicas',
                width: 6,
                isVisible: this.env.showClimatologia,
                fields: this.getClimaFields(this.env.showClimatologia),
                order: 5
            },
            {
                name: 'Mezclas sobrantes',
                width: 6,
                isVisible: this.env.appName === 'vginer' || this.env.appName === 'tomega',
                fields: this.getMezclasSobrantesFields(this.env.appName === 'vginer' || this.env.appName === 'tomega'),
                order: 6
            },
            {
                name: 'Datos de la maquinaria',
                width: 6,
                isVisible: true,
                fields: this.getTractorFields(),
                order: 7
            },
            {
                name: 'Tarea finalizada',
                width: 6,
                isVisible: true,
                fields: this.getFinalFields(),
                order: 8
            },
            {
                name: 'Productos aplicados',
                width: 12,
                isVisible: this.env.appName === 'innovia' && this.ids_productos !== undefined,
                fields: this.getInnoviaFields(this.env.appName === 'innovia' && this.ids_productos !== undefined),
                order: 9
            },
            {
                name: '',
                width: 12,
                isVisible: this.env.appName === 'donjardin',
                fields: this.getOrdenTrabajoFields(this.env.appName === 'donjardin'),
                order: 10
            },
            {
                name: '',
                width: 12,
                isVisible: this.env.appName === 'donjardin',
                fields: this.getObsFields(this.env.appName === 'donjardin'),
                order: 11
            },
            {
                name: '',
                width: 12,
                isVisible: true,
                fields: this.getHiddenFields(),
                order: 12
            }
        ];
        return sections.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }

    private getHiddenFields() {
        const hiddenFields: FormField<any>[] = [
            {
                name: 'id',
                vinculatedFields: undefined,
                label: '',
                input: {
                    type: InputType.HIDDEN,
                    defaultValue: this.model.id,
                    isEditable: false
                },
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true,
                },
                order: 1
            },
            {
                name: 'id_cliente',
                vinculatedFields: undefined,
                label: '',
                input: {
                    type: InputType.HIDDEN,
                    defaultValue: this.cliente?.id ? this.cliente?.id : this.lists.fincas.selected?.id_cliente,
                    isEditable: false
                },
                updateValue: () => this.cliente?.id ? this.cliente?.id : this.lists.fincas.selected?.id_cliente,
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.hasClients && this.env.userCanSeeClientes && this.formType !== FormType.DUPLICATION
                },
                order: 2
            },
            {
                name: 'id_usuario',
                vinculatedFields: undefined,
                label: '',
                input: {
                    type: InputType.HIDDEN,
                    defaultValue: !this.env.showTareasMto ? 
                        this.usuario ? this.usuario.id :
                            (this.lists.fincas.selected || {})?.id_usuario : 
                        !FormType.EDITION ? this.usuario.id : '',
                    isEditable: false
                },
                updateValue: () => !this.env.showTareasMto ? 
                    this.usuario ? this.usuario.id :
                        (this.lists.fincas.selected || {})?.id_usuario : 
                    !FormType.EDITION ? this.usuario.id : '',
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                order: 3
            },
            {
                name: 'cultivo',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                updateValue: () => 
                    (this.model.ids_parcelas as unknown as ParcelasModel[])?.map((parcela: ParcelasModel) => parcela.cultivo).join(';'),
                order: 4
            },
            {
                name: 'variedad',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                updateValue: () => (this.model.ids_parcelas as unknown as ParcelasModel[])?.map(
                    (parcela: ParcelasModel) => parcela.variedad).join(';'),
                order: 5
            },
            {
                name: 'sup_cultivada',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: true
                },
                updateValue: () => (this.model.ids_parcelas as unknown as ParcelasModel[])?.map(
                    (parcela: ParcelasModel) => parcela.superficie_cultivada).join(';'),
                order: 6
            },
            {
                name: 'referencia_receta',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: false,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'pyf'
                },
                updateValue: () => this.referenciaReceta,
                order: 7
            },
            {
                name: 'signature_img',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto,
                },
                order: 8
            },
            {
                name: 'id_ref_usuario',
                vinculatedFields: undefined,
                label: '',
                input: {
                    type: InputType.HIDDEN,
                    defaultValue: this.usuario.id,
                    isEditable: false
                },
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: true,
                    hasUndoEnabled: false,
                    isVisible: this.env.appName === 'donjardin' && this.formType !== FormType.CREATION
                },
                order: 9
            },
            {
                name: 'imagen',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto
                },
                order: 10
            },
            {
                name: 'image_after',
                vinculatedFields: undefined,
                label: '',
                input: {type: InputType.HIDDEN, isEditable: false},
                width: 2,
                conditions: {
                    isRememberedOnDuplicate: true,
                    isRequired: false,
                    isRetained: false,
                    hasUndoEnabled: false,
                    isVisible: this.env.showTareasMto
                },
                order: 11
            },
        ];

        if (this.formType === FormType.DUPLICATION) {
            hiddenFields.splice(1, 1);
        }

        return hiddenFields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
    }
}