import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { FullMapModel } from 'src/app/models/form-common/full-map.model';


@Injectable({
    providedIn: 'root'
})
export class MapaParcelasApiService {

    public mapaParcelasGET = new RequestHandler<FullMapModel[]>();
    public mapaParcelasPUT = new RequestHandler<FullMapModel>();
    public mapaParcelasPOST = new RequestHandler<FullMapModel>();
    public mapaParcelasDELETE = new RequestHandler<FullMapModel>();

    constructor(
        private syncService: SyncService<FullMapModel[]>,
        private syncServicePut: SyncService<FullMapModel>,
        private syncServiceDelete: SyncService<FullMapModel>,
        private api: ApiRequestService<FullMapModel[]>,
        private apiPut: ApiRequestService<FullMapModel>, 
        private apiDelete: ApiRequestService<FullMapModel>
    ) {

        this.defineMapaParcelasGET();
        this.defineMapaParcelasPUT();
        this.defineMapaParcelasPOST();
        this.defineMapaParcelasDELETE();
    }

    private defineMapaParcelasGET() {
        this.mapaParcelasGET
            .fetch(HttpRequestType.GET, 'mapas/mapa_parcelas');

        this.api
            .registerRequest(this.mapaParcelasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.mapaParcelasGET));
    }

    private defineMapaParcelasPUT() {
        this.mapaParcelasPUT
            .fetch(HttpRequestType.PUT, 'generic/mapa_parcelas');

        this.apiPut
            .registerRequest(this.mapaParcelasPUT);

        this.syncServicePut
            .register(this.syncServicePut.baseSyncPolicy(this.mapaParcelasPUT));
    }

    private defineMapaParcelasPOST() {
        this.mapaParcelasPOST
            .fetch(HttpRequestType.POST, 'generic/mapa_parcelas');

        this.apiPut
            .registerRequest(this.mapaParcelasPOST);

        this.syncServicePut
            .register(this.syncServicePut.baseSyncPolicy(this.mapaParcelasPOST));
    }

    private defineMapaParcelasDELETE() {
        this.mapaParcelasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/mapa_parcelas');

        this.apiDelete
            .registerRequest(this.mapaParcelasDELETE);

        this.syncServiceDelete
            .register(this.syncServiceDelete.baseSyncPolicy(this.mapaParcelasDELETE));
    }

}


