import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardComponent} from '../../view/dashboard/dashboard.component';
import {LoginApiService} from '../../service/api/login-api.service';
import {UserModel} from '../../models/usuarios/user.model';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UsuariosApiService } from '../../service/api/usuarios-api.service';
import { StorageManager } from '../../common/storage-manager.class';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import { DropdownInterface, ValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { AlbaranesComponent } from '../main/3_ventas/albaranes/albaranes.component';

@Component({
    selector: 'app-login',
    standalone: false,
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @ViewChild('passport', {static: true}) passport: ElementRef = new ElementRef('');
    
    public env = environment.appName;
    public logoUrl = environment.logoUrl;
    public serverUrl = environment.serverUrl;
    public canRegister = environment.features.canRegister;
    public canRecoverPassword = environment.features.canRecoverPassword;
    public urlLogoLocatec = environment.serverUrl + 'assets/logo-locatec-footer.png';
    public showWatermark = environment.features.showWatermark;
    public hasUsuarios = environment.features.hasUsuarios;

    public showRecuperarContrasena = false;
    public showCode = false;
  
    public error = false;
    public success = false;
    public password = '';
    public isDoLoginLoading = false;
    public loginWithDropdownUsers = [
        'agromainsa', 
        'agrogest', 
        'oliverfutur', 
        'palomares', 
        'prosanzcu',
        'brio', 
        'ortizlavado', 
        'simat', 
        'lafast', 
        'icod', 
        'ebro', 
        'hernandorena',
        'pascualcabedo', 
        'saviatropical', 
        'previlsa', 
        'buitech',
        'laplana', 
        'esparragosgranada', 
        'vginer', 
        'naturfrut', 
        'innovia',
        'upm', 
        'abonosfolques', 
        'armentia', 
        'ava', 
        'avafitogest', 
        'amoros', 
        'torrepacheco',
        'almassora', 
        'burriana', 
        'lacooperativa', 
        'onda', 
        'pyf', 
        'viverosblanco', 
        'agricar', 
        'covidai', 
        'momparler',
        'ctl', 
        'malvesia', 
        'gardengest', 
        'viverogest', 
        'gestata', 
        'donjardin', 
        'ferticinca', 
        'jobarma', 
        'fotoagricultura',
        'lluchychulbi', 
        'agropecuariaramirogruas', 
        'viverospereira', 
        'viverospereirafitosgest', 
        'pedrosaez', 
        'campanaragricola', 
        'campanaragricolafitosgest', 
        'valencianagestionagraria', 
        'valencianaagrogest', 
        'hopsteiner', 
        'cartagenafresh', 
        'plantanova', 
        'bambunova', 
        'jardinpn', 
        'verdurasbelloch', 
        'yagoaznar', 
        'agufert', 
        'agropedbel', 
        'prodalbar', 
        'gragon', 
        'gfcartera', 
        'brimelfitosgest', 
        'brimelagrogest', 
        'ingenia', 
        'agrosol', 
        'bravosolfitosgest', 
        'intasa', 
        'coliberica', 
        'mantengarden', 
        'agrosalvi', 
        'demogardengest', 
        'mbcpomelos', 
        'seteo', 
        'salf', 
        'servalbert', 
        'sirga', 
        'tousagricola', 
        'SIEX', 
        'valdepalma', 
        'valdepalmafitosgest',
        'democropgest',
        'herbesdelmoli',
        'tomega'
    ];

    public userList: {
        selected: null | DropdownInterface<UserModel>,
        values: null | ValuesInterface<UserModel>[]
    } = {
            selected: null,
            values: [{label: 'Cargando...', value: {} as UserModel}]
        };

    /**
     * Recuperar contraseña (de FitoCoop)
     */
    public isSendingEmail = false;
    public hasSentEmail = false;
    public isCheckingCode = false;
    public codeHasError = false;
    public codeHasBeenChecked = false;
    public isSendingPassword = false;
    public userRecovery: ValuesInterface<UserModel> | undefined = {label: 'Cargando...', value: {} as UserModel};
    public email = '';
    public username = '';
    public passwordRepeat = '';


    constructor(private router: Router,
        private usuariosApi: UsuariosApiService,
        private http: HttpClient,
        private loginApi: LoginApiService) {
    }


    public static createNavigation(router: Router) {
        router.navigate(['login']);
    }


    ngOnInit() {
        this.loginApi.getUsers()
            .then((userList: UserModel[]) => {
                this.userList.values = [];
                this.userList.values.push({
                    label: 'Seleccionar usuario...',
                    value: null
                });

                userList.sort((a, b) => (a.nombre || '').toLowerCase().localeCompare((b.nombre || '').toLowerCase())).forEach(user => {
                    this.userList.values?.push({
                        label: (user.nombre || '') + ' ' + (user.apellidos || ''),
                        value: user
                    });
                });
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );

        this.loginApi.checkCurrentSession()
            .then((exists) => {
                if (exists) {
                    if(environment.appName !== 'gruesnadal'){
                        DashboardComponent.createNavigation(this.router);
                    }else{
                        AlbaranesComponent.createNavigation(this.router);
                    }
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );

        if (!this.hasUsuarios && !StorageManager.getUser()) {
            StorageManager.saveUser({
                id: '0',
                nombre: 'Usuario',
                apellidos: '',
                password: '',
                rol: 'demo',
                username: 'usuario'
            });
            window.location.reload();
        }
    }
    
    public setUser() {
        if (this.userList.selected) {
            this.userList.selected.value = this.userList.values?.[+this.userList.selected]?.value ?? {} as UserModel;
        }
    }


    public doLogin() {
        if (this.userList.selected) {
            this.isDoLoginLoading = true;
            let values: { user: UserModel; password: string; };

            if (typeof this.userList.selected === 'string') {
                values = {
                    user: this.userList.selected ?? {} as UserModel,
                    password: this.password
                };
            } else {
                if (this.userList.selected.value) {
                    this.userList.selected.value.password = this.password;
                }
            }
                    
            setTimeout(() => {
                this.loginApi.doLogin((this.userList.selected?.value || values))
                    .then((isValid: boolean) => {
                        if (isValid) {
                            this.success = true;
                            window.location.reload();
                        } else {
                            this.error = true;
                        }
                        this.isDoLoginLoading = false;
                        return;
                    })
                    .catch(() => {
                        this.error = true;
                        this.isDoLoginLoading = false;
                    });
            }, 700);
        }
    }

    public sendPasswordRecovery(email: string) {
        const usuario = this.userList.values?.find((it) => it.value && it.value.email === email);
        const idUsuario = usuario ? usuario.value?.id : '';

        this.isSendingEmail = true;
        setTimeout(() => {
            this.isSendingEmail = false;
            this.hasSentEmail = true;
        }, 700);
    
        firstValueFrom(this.http.get(this.serverUrl + 'ws/user/sendPasswordRecovery.php?idUsuario=' + idUsuario + '&email=' + email))
            .then((res) => {
                console.log(res);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    }
    
    public sendCode(code: string) {
        this.isCheckingCode = true;
        firstValueFrom(this.http.get<{result: number; id_usuario: string}>(this.serverUrl + 'ws/user/checkCode.php?code=' + code))
            .then((res) => {
                if (res && res.result === 1) {
                    this.codeHasError = false;
                    this.codeHasBeenChecked = true;
                    this.isCheckingCode = false;
            
                    this.userRecovery = 
                    this.userList.values?.find((it: ValuesInterface<UserModel> ) => it && it.value && it.value.id === res.id_usuario) 
                    ?? {} as ValuesInterface<UserModel>;
                } else {
                    this.codeHasError = true;
                    this.isCheckingCode = false;
                }
                return;
            }, (error: string) => {
                console.log(error);
                this.codeHasError = true;
                this.isCheckingCode = false;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    }
    
    public crearCuenta() {
        this.router.navigate(['register']);
    }

    public changePassword(pass1: string, pass2: string) {
        if (pass1 === pass2) {
            this.isSendingPassword = true;
            this.usuariosApi.usuariosPUT.toPromise({
                id: this.userRecovery?.value?.id,
                password: pass1
            } as never).then(res => {
                this.isSendingPassword = false;
        
                if (res) {
                    alert('Se ha cambiado la contraseña.');
                    window.location.reload();
                }
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        } else {
            alert('Las contraseñas no coinciden.');
        }
    }
    

    onkeyup(e: { key: string; }) {
        if (e.key === 'Enter') {
            this.doLogin();
        }
    }

}
