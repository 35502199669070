import { FormGroup } from '@angular/forms';
import { Form, FormField } from '../interfaces/form.interface';
import { DropdownData, FilteredValue, InputType } from '../interfaces/input.interface';

export class FormOperations<T> {
    private isProgrammaticChange = false;
    private form!: Form<T>;
    private model!: FormGroup;

    constructor(form: Form<T>, model: FormGroup){
        this.form = form;
        this.model = model;
    }// ();

    /**
     * 
     * @param controlName 
     * @returns Field with the passed control name
     */
    public getFieldByControlName(controlName: string | undefined): FormField<T> | undefined {
        return this.form.sections
            .flatMap(section => section.fields)
            .find(field => field.name === controlName);
    }// ();

    /**
     * Attaches passed values to the 'selected' property of the DropdownData interface 
     * @param field 
     * @param value 
     */
    public applySelected(field: FormField<T> | undefined, value: T | null): void {
        if (field?.input.data) {
            (field.input.data as DropdownData<T>).selected = value;
        }// if();
    }// ();

    /**
     * 
     * @param changedField 
     */
    public executeFieldsLogic(changedField: string | null) {
        this.form.sections.forEach((section) => {
            section.fields
                .filter((field) => 
                    (field.input?.dropdownConfiguration?.filter && field.input.data && 
                        (changedField === null || field.vinculatedFields === undefined ||
                            field.vinculatedFields.includes(changedField))) || 
                    (field.input?.dropdownConfiguration?.transform && field.input.data) || 
                    (field.updateValue && !this.isProgrammaticChange) ||
                    (field.input?.dropdownConfiguration?.swap) || 
                    (field.turnVisible)
                )
                .forEach((field) => {
                    if (field.input?.dropdownConfiguration?.transform) { this.applyTransforms(field); }
                    if (field.input?.dropdownConfiguration?.filter) { this.applyFilter(field, changedField);}
                    if (field.input?.dropdownConfiguration?.swap) { field.input.dropdownConfiguration.swap(field); }
                    if (field.turnVisible) { field.turnVisible(field); }

                    if (field.updateValue && !this.isProgrammaticChange) {
                        const value = field.updateValue(field);

                        if(value) {
                            this.isProgrammaticChange = true;
                            this.model.patchValue({ [field.name ?? '']: (
                                field.input?.type === InputType.MULTISELECT_SEARCH || 
                                field.input?.type === InputType.MULTISELECT
                            ) ? [value] : value });
                            this.applySelected(field, null);
                            this.isProgrammaticChange = false;
                        }// if();
                    }// if();
                });
        });
    }// ();

    /**
    * Filter dropdown values by the id of another dropdown
    * @param previousValue 
    * @param field 
    * @returns Filtered values.
    */
    public getFilteredValues(previousValue: string, field: FormField<T>): (T | null)[] | NonNullable<T> | string {
        const ids = previousValue.split(';');
        const filteredValues = (field.input.data as DropdownData<T>).filtered
            .filter((value: FilteredValue<T>) => ids.includes((value.value as Record<string,string>)?.['id'] ?? ''))
            .map((value: FilteredValue<T>) => value.value);
        
        return (
            field.input?.type === InputType.MULTISELECT_SEARCH || 
                field.input?.type === InputType.MULTISELECT
        ) ? filteredValues : filteredValues[0] ?? '';
    }// ();

    /**
     * Executes the transform logic attached to the form field
     * @param field 
     */
    private applyTransforms(field: FormField<T>): void {
        field.input?.dropdownConfiguration?.transform?.forEach(transform => transform(field, this.model));
    }// ();


    /**
     * 
     * @param field 
     * @param changedField 
     */
    private applyFilter(field: FormField<T>, changedField: string | null): void {
        if (typeof field.input?.dropdownConfiguration?.filter === 'function') {
            (field.input.data as DropdownData<T>).filtered = [
                { label: field.input.placeholder || '...', value: null },
                ...((field.input.data as DropdownData<T>).values || []).filter(field.input?.dropdownConfiguration?.filter),
            ];
        }// if();
        
        if (
            changedField && 
            field.vinculatedFields?.includes(changedField) && 
            ((field.input.data as DropdownData<T>).selected as T[])?.includes
        ) {
            const selected: T[] = [];

            (field.input.data as DropdownData<T>).filtered.forEach((value) => {
                if (((field.input.data as DropdownData<T>).selected as T[]).includes(value.value as T)) {
                    selected.push(value.value as T);
                }
            });
            
            this.applySelected(field, selected as T);
            if (!this.isProgrammaticChange) {
                this.isProgrammaticChange = true;
                this.model.patchValue({ [field.name ?? '']: selected }, {emitEvent: false});
                this.model.get(field.name ?? '')?.markAsUntouched();
                this.model.get(field.name ?? '')?.markAsPristine();
                this.isProgrammaticChange = false;   
            }// if();
        }// if();
    }// ();
}