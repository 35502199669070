<form [formGroup]="model">
    <div class="row">
        
        <!-- FORM FIELDS -->
        @for (section of form.sections; track section) {

            @if (section.isVisible) {
                <div class="col-sm-{{ section.width }} mb-3">
                    <h5>{{ section.name }}</h5>
                    <div class="row">

                        @for (field of section.fields; track field) {
                                
                            @if (field.conditions.isVisible) {
                                <div class="col-sm-{{ field.width }} mb-3">
                                    <app-field 
                                        [form]="form"
                                        [field]="field" 
                                        [model]="model" 
                                        [undoEnabled]="undoEnabled"
                                        [originalModel]="originalModel"
                                        [filterInputParcelas]="filterInputParcelas">
                                    </app-field>
                                </div>
                            }
                        }
                    </div>
                </div>
            }
        }
            
        <!-- TABLE -->
        @if (form.table) {
            <div class="d-flex justify-content-center">
                <table class="table-style">
                    @for (row of form.table.data; track row; let rowNumber = $index) {
                        <tr [style.background]="form.table.style?.[rowNumber]"
                        [style.color]="form.table.style?.[rowNumber] === '#222222' ? 'white' : 'black'">
                        
                        @for (column of row; track column; let colNumber = $index) {
                            @if (rowNumber === 0) {
                                
                                @if (colNumber === 0) {
                                    <th style="width: 15px; border: none"></th>
                                }

                                <th>{{column}}</th>
                            } @else {
                                
                                @if (colNumber === 0) {
                                    <td style="border: none; background: #f8f9fa !important; color: black !important;">{{rowNumber}}
                                    </td>                                
                                } 
                                
                                <td>{{column}}</td>
                            }
                        }
                    </tr>
                    }
                </table>
            </div>    
        }

        <br>

        <!-- SIGNATURE -->
        @if (form.signed) {

            @if (model.get('signature_img')?.value){
                <div 
                    class="distribution_camera_signature" 
                    style="display: flex; gap: 50px; align-items: center;">
                    <div>
                        <div style="display: flex; flex-direction: column;">
                            <p>Firma</p>
                            
                            @if (!model.get('signature_img')?.value.includes('base64')) {
                                <img 
                                    alt="Firma"
                                    [src]="serverUrl + 
                                        'images/fitosanitarios/' + 
                                        model.get('signature_img')?.value" 
                                    class="info-firma-img">
                            } @else {
                                <img alt="Firma" [src]="model.get('signature_img')?.value" class="info-firma-img">
                            }
                        </div>
                    </div>  
                </div>
    
            }

            @if(form.type < 3) {
                <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
            }
        }

        <!-- ACTION BUTTONS -->
        @if(form.type < 3) {
            <div class="d-flex justify-content-center">
                <div>
                    <button class="btn btn-dark mx-2" (click)="returnToMainView()">Volver</button>
                </div>
                
                <app-reactive-request-button
                    [form]="form"
                    [model]="model"
                    [request]="request"
                    [lockOnSuccess]="true"
                    (goBack)="returnToMainView()">
                </app-reactive-request-button>
            </div>
        } 
    </div>
</form>