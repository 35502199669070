import { FormField } from '../interfaces/form.interface';
import { InputType } from '../interfaces/input.interface';

/**
* Creates a CSV using the module's form as a template.
* @param fields Template for creating CSV, providing context on how to process the data.
* @param fieldsLabel Label of the fields, used here as the CSV's column names.
* @param filteredData Filtered data as retrieved by the user in the module's table before pressing the export button.
* @returns Content of the CSV.
 */
export function createCsv<T>( fields: FormField<T>[], fieldsLabel: string[], filteredData: T[] | void ) {
    const charset = 'windows-1252';
    let csvContent = 'data:text/csv;charset=' + charset + ',\ufeff' + fieldsLabel.join(';') + '\n';

    (filteredData as Record<string,string>[] || []).forEach((data) => {

        fields.forEach( (field) => {

            if (field.input.type === InputType.SWITCH) {
                csvContent += data[field.name] === '1' ? 'Sí;' : 'No;';
            } else {
                /* 
                    The following characters are likely to break the CSV structure:
                        1. If the value starts with "-" or "+" the spreadsheet treats it as a formula.
                        2. Line breaks are interpreted as such by the spreadsheet.
                        3. The ';' cause a column break.
                        4. The null type is interpreted as text instead of an empty cell.
                */ 
                csvContent += '"' + (data[field.name]?.replace(/^[-+]|(\n-)|\n|;|null/g, (_match, p1) => 
                    p1 ? ', ' : ''
                ) ?? '') + '";';
            }// if();

        }); // forEach();

        csvContent += '\n';
    }); // forEach();

    return csvContent;
}// ();