import { EntidadesApiService } from './../../../../../service/api/entidades-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { environment } from 'src/environments/environment';
import { list, pool } from 'src/app/common/classes/request-builder';

import paises from 'src/app/common/country';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';
import { EntidadModel } from 'src/app/models/reto/entidad.model';
import { Utils } from 'src/app/common/utils';

@Component({
    selector: 'app-entidades-form',
    standalone: false,
    templateUrl: './entidades-form.html',
    styleUrls: ['./entidades-form.scss']
})
export class EntidadesFormComponent extends BaseForm<EntidadModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<EntidadModel>();

    public hasClients = environment.features.hasClients;
 

    public formRequest = new AppFormRequest<EntidadModel>();
    public override model!: EntidadModel;
    public error = '';

    public productos = list();

    public lista_paises = list( [ '...', null ] );
    public lista_provincias = list( [ '...', null ] );
    public lista_clientes: ClienteModel[] = [];


    public formFields: ItemInterface<object>[] = [      
        {
            field: 'nombre', 
            label: 'Nombre o Razón Social', 
            inputType: {type: InputType.EDIT_TEXT}, 
            required: true
        },
        {
            field: 'apellidos', 
            label: 'Apellidos', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'nif', 
            label: 'NIF / CIF', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'pais', 
            label: 'País', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_paises
        },
     
        {
            field: 'provincia', 
            label: 'Provincia', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.lista_provincias,
            visible: true
        
        },
        { 
            field: 'localidad', 
            label: 'Localidad (Código INE)', 
            inputType: {type: InputType.EDIT_TEXT}
        },
    ];

    public formFieldsDireccion: ItemInterface<object>[] = [    
        {
            field: 'cp', 
            label: 'Código postal ', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'direccion', 
            label: 'Dirección ', 
            inputType: {type: InputType.EDIT_TEXT}
        },
    ];
    
    public formFieldsDatos: ItemInterface<object>[] = [    
        {
            field: 'email', 
            label: 'Email', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'telefono', 
            label: 'Teléfono',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'fax', 
            label: 'Fax', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'roposs', 
            label: 'ROPO SS', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'ropost', 
            label: 'ROPO ST', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'roposu', 
            label: 'ROPO SU', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'caducidadss', 
            label: 'Fecha caducidad SS', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'caducidadst', 
            label: 'Fecha caducidad ST', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'caducidadsu', 
            label: 'Fecha caducidad SU', 
            inputType: {type: InputType.CALENDAR}
        },
    ];
    
    
   

    constructor(
        public override router: Router,
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public entidadesApi: EntidadesApiService,
        public clientesApi: ClientesApiService
    ) {
        super(
            route,
            router,
            dashboard,
            entidadesApi.EntidadesPUT,
            entidadesApi.EntidadesPOST,
            'entidades',
            'Editar ' ,
            'Crear ' ,
            'Duplicar ' 
        );
    }

    async ngOnInit() {
        
        this.initFormData();
        this.lista_clientes = await this.clientesApi.clientesGET.toPromise();

        const fieldsToSend = (['id', 'id_usuario'] as string[])
            .concat(
                this.formFields.map(it => it.field ?? ''),
                this.formFieldsDireccion.map(it => it.field ?? ''),
                this.formFieldsDatos.map(it => it.field ?? '')
            );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');   
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.entidadesApi.EntidadesGET)
            .setPostRequest(this.entidadesApi.EntidadesPOST)
            .setPutRequest(this.entidadesApi.EntidadesPUT)
            .setFormFields(this.formFields.concat(this.formFieldsDireccion).concat(this.formFieldsDatos))
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());

        if (this.getType() === 0 ) {
            this.model['pais'] = 'ES';
        }
        
        
    }

    /**
     * Rellenamos el listado de países.
     * @returns 
     */
    fillCountry() {
        return new Promise( (resolve)  => {
            for (let index = 0; index < paises.length; index++) {
                const pais = { label: paises[index]?.label, value: paises[index]?.value} ;
                this.lista_paises.filtered.push( pais );
                if ( (index + 1 ) >= paises.length  ) { 
                    resolve(true);
                }
            }
        });
    }

    async fillProvincias() {
        const provincias: ProvinciasInterface[] = await this.entidadesApi.provinciasGET.toPromise();
        // console.log('provincias', provincias);
        return new Promise( (resolve)  => {
            for (let index = 0; index < provincias.length; index++) {
               
                
                const pais = { label: provincias[index]?.nombre, value: (provincias[index]?.id)?.toString()} ;
                this.lista_provincias.filtered.push( pais );
                if ( (index + 1 ) >= paises.length  ) { 
                    resolve(true);
                }
            }
        });
        
    }

    
    public submit() {
        if (this.model.caducidadss) {
            this.model.caducidadss = Utils.formatDate(this.model.caducidadss ?? '');
        }

        if (this.model.caducidadsu) {
            this.model.caducidadsu = Utils.formatDate(this.model.caducidadsu ?? '');
        }

        if (this.model.caducidadst) {
            this.model.caducidadst = Utils.formatDate(this.model.caducidadst ?? '');
        }

        
        delete this.model['provincia_nombre'];
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        if ( ([null, '', undefined]).includes(this.model['id_cliente']) && this.lista_clientes.length > 0 ) {
            this.model.id_cliente = this.lista_clientes[0]?.id ?? '';
        }
        this.formRequest.send();
    }

    public async formChanges() {
        
        /* if ( this.model['localidad'] ) {
            let localidad: string =  this.model['localidad'].toString();
          if ( localidad.length > 3 ){
            this.model['localidad'] = parseInt(localidad.substring(0,3));
          };
        } */

        if ( this.model['pais'] != null && this.model['pais'] === 'ES' ) {
            this.lista_provincias = list(['...', null]);
        } else {
            this.lista_provincias = list(['...', null]);
            await this.fillProvincias();    
            
        }
        this.requestButton.error = '';
    }

    public override goBack() {
        this.request.reset();
        this.router.navigate(['dashboard', 'reto-entidades']);
    }

    private async initFormData(): Promise<unknown> {
        const builds: unknown[] = [];
        await this.fillCountry();
        await this.fillProvincias();

        return pool(builds, () => {
            this.formRequest.update();
        });
        
    }
}


export interface ProvinciasInterface{
    id: string;
    codigo: string;
    nombre: string; 
    eliminado: string;
}