import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StorageManager} from '../../../storage-manager.class';

import type { OnInit } from '@angular/core';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { Form, FormType } from '../interfaces/form.interface';
import { FormGroup } from '@angular/forms';
import { EventPhase, FormPhase, FormRequest } from '../form-request';
import { FormOperations } from '../utils/form-operations';


@Component({
    selector: 'app-reactive-request-button',
    standalone: false,
    templateUrl: './request-button.component.html',
    styleUrls: ['./request-button.component.scss']
})


export class ReactiveRequestButtonComponent<T> extends FormRequest implements OnInit {

    @Input() form!: Form<T>;
    @Input() model!: FormGroup;
    @Input() request = new RequestHandler<any>();
    @Input() lockOnSuccess = false;
    @Input() warning: string | null = null;
    @Output() goBack: EventEmitter<T> = new EventEmitter<T>();

    public validationError: string | null = null;
    public requestError: string[] = [];
    public REQUEST_BUTTON_TYPES = FormType;

    private formOperations = new FormOperations(this.form, this.model);
    
    constructor() {
        super();
    }

    /**
     *
     * @returns form submission info.
     */
    get buttonLabel(): string {
        if (this.validationError) {
            return this.validationError;
        }// if();
    
        if (this.warning) {
            return this.warning;
        }// if();
    
        if (this.request.hasError) {
            return this.request.hasErrorCliExists ? 'Cliente ya existe...' : this.requestError[0] ?? '';
        }// if();
        
        const stateLabels: Record<FormType, [string, string, string]> = {
            [this.REQUEST_BUTTON_TYPES.CREATION]: ['Guardar', 'Guardando...', 'Guardado'],
            [this.REQUEST_BUTTON_TYPES.EDITION]: ['Actualizar', 'Actualizando...', 'Actualizado'],
            [this.REQUEST_BUTTON_TYPES.DUPLICATION]: ['Duplicar', 'Duplicando...', 'Duplicado'],
            [this.REQUEST_BUTTON_TYPES.DELETION]: ['Eliminar', 'Eliminando...', 'Eliminado'],
            [this.REQUEST_BUTTON_TYPES.INFORMATIVE]: ['', '', ''],
            [this.REQUEST_BUTTON_TYPES.EXPORT]: ['', '', ''],
        };
    
        const [defaultLabel, loadingLabel, doneLabel] = stateLabels[this.form.type];
    
        return this.request.hasError === undefined ? defaultLabel :
            this.request.isLoading ? loadingLabel :
                doneLabel;
    }// ();

    /**
     * Sets generic errors for requests
     */
    ngOnInit() {
        const isDemo = (StorageManager.getUser() || {}).rol === 'demo';

        this.requestError[0] = isDemo ? 'Operación no permitida' : 'Ha ocurrido un error...';
        this.requestError[1] = isDemo ? '' : 'Volver a intentar';
    }// ();


    /**
     * Evaluates the validity of form data in order to perform the most appropiate action.
     */
    public submit(): void {
        if (!this.model.valid) {
            this.displayErrorsOnButton();
            return;
        }// if();
        
        const payload = this.updateObjectWithIds(this.model.getRawValue());
            
        if (this.form.type === 2) {
            delete payload['id'];
        }// if();
            
        this.sendRequest(payload as T); 
    }// ();


    /**
     * Replaces the objects that are part of the form submission with their id.
     * @param data A JSON with the data as submitted by the user
     * @returns A JSON with ids instead of the full object
     */
    private updateObjectWithIds(data: T): Record<string, unknown> {
        return Object.fromEntries(
            Object.entries(data as ArrayLike<unknown>).map(([key, value]) => {
                if (Array.isArray(value)) {
                    return [key, value.map((item: Record<string,string>) => item['id']).filter(Boolean).join(';')];
                } else if ((value as Record<string,string>)?.['id']) {
                    return [key, (value as Record<string,string>)['id']];
                }// if();
                return [key, value];
            })
        );
    }// ();
    

    /**
     * Performs the request, returning the user to the previous location on success.
     * @param payload A JSON used as the payload of a POST/PUT request
     */
    private sendRequest(payload: T): void {
        this.executePhase(FormPhase.SEND);
        this.addEvent(FormPhase.SEND, EventPhase.ON, (resolve) => {
            this.request.safePerform(payload);
            this.request.response(() => resolve(true));
        });

        this.executePhase(FormPhase.FINISH);
        this.addEvent(FormPhase.FINISH, EventPhase.ON, (resolve) => {
            setTimeout(() => this.request.unsuscribe(), 1000);
            this.returnToMainView();
            resolve(true);
        });
    }// ();

    /**
     * Returns the user to the previous location. 
     */
    private returnToMainView(): void {
        this.goBack.emit();
    }// ();

    /**
     * Displays on the button errors related with invalid fields.
     */
    private displayErrorsOnButton() {
        const errorMessages: Record<string, (errors: Record<string,string>) => string> = {
            required: () => 'es obligatorio.',
            maxlength: () => 'ha excedido la longitud máxima.',
            invalidTime: (errors) => errors['invalidTime'] ?? ''
        };
    
        const invalidFields = Object.entries(this.model.controls).reduce<string[]>((acc, [field, control]) => {
            if (!control?.invalid) {
                return acc;
            }

            this.formOperations = new FormOperations(this.form, this.model);

            const fieldInfo = this.formOperations.getFieldByControlName(field);
            const errors = control?.errors ?? {};

            const messages = Object.keys(errors)
                .filter(key => key in errorMessages)
                .map(key => errorMessages[key]?.(errors))
                .join(' ');
    
            acc.push(`${fieldInfo?.label} ${messages || ': Error desconocido.'}`);
            return acc;
        }, []);
    
        this.validationError = `El formulario no es válido. Revisa los campos:<br>- ${invalidFields.join('<br>- ')}`;
        this.model.markAllAsTouched();
    }// ();
}// class;
