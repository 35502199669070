import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { FormRequestTypes } from "src/app/common/classes/form-request";
import { ItemInterface } from "src/app/common/components/form-builder/form-builder.component";
import { RequestButtonComponent } from "src/app/common/components/request-button/request-button.component";
import { FormCommonApiService } from "src/app/service/api/formcommon-api.service";
import { UsuariosApiService } from "src/app/service/api/usuarios-api.service";
import { DashboardService } from "src/app/service/events/dashboard.service";
import { AppFormRequest } from "src/app/view/app-common/form-request/app-form-request";
import { BaseForm } from "src/app/view/base-form";
import { environment } from "src/environments/environment";
import { MaquinariaFormDefinition } from "./form-control/form-definition";
import { StorageManager } from "src/app/common/storage-manager.class";
import { MaquinariaApiService } from "src/app/service/api/tablas-maestras-api.service";
import { MaquinariaAlbaranModel } from "src/app/models/ventas/tablas-maestras.model";

@Component({
  selector: "app-formulario-pulverizacion",
  standalone: false,
  templateUrl: "./maquinaria-form.component.html",
  styleUrls: ["./maquinaria-form.component.scss"],
})
export class AlbaranesMaquinariaFormComponent extends BaseForm<MaquinariaAlbaranModel> implements OnInit {
  @ViewChild(RequestButtonComponent, {
    read: RequestButtonComponent,
    static: true,
  })
  requestButton: RequestButtonComponent<MaquinariaAlbaranModel> = new RequestButtonComponent();
  public form: MaquinariaFormDefinition = new MaquinariaFormDefinition();
  public formRequest: AppFormRequest<MaquinariaAlbaranModel> = new AppFormRequest();
  public override model: any = {
    fecha_inicio: moment().toDate(),
  };

  public distributionOrderFormTratamientos = [2, 3, 2];

  public rol = StorageManager.getUser().rol;

  public appName = environment.appName;
  public showTareasFitoObservaciones =
    environment.features.showTareasFitoObservaciones;

  public horaFechaFinActualPorDefecto =
    environment.features.horaFechaFinActualPorDefecto;
  public showClimatologia = environment.features.showClimatologia;
  public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
    ? "Sup.\xa0Cultivada"
    : "S.\xa0Sigpac";
  public serverUrl =
    window.location.hostname === "localhost" ? "" : environment.serverUrl;

  public auxServerUrl = environment.serverUrl;
  public hasClients = environment.features.hasClients;
  public userCanSeeClientes = environment.features.userCanSeeClientes
    ? true
    : this.rol === "admin";
  public filterAplicadoresByClient =
    environment.features.filterAplicadoresByClient;
  public clienteName = environment.features.clienteName;
  public showTareasFitoHoras = environment.features.showTareasFitoHoras;
  public timeStartStopTareas = environment.features.timeStartStopTareas;
  public showFitoValidado = environment.features.showFitoValidado
    ? this.rol === "admin"
    : false;
  public parcelasName = environment.features.parcelasName;
  public showTareasFitoMochila = environment.features.showTareasFitoMochila;
  public showTareasFitoAplicacion =
    environment.features.showTareasFitoAplicacion;
  public textoSuperficie = environment.features.textoSuperficie;
  public showClientesNotas = environment.features.showClientesNotas;

  public getRequest =
    this.getType() === FormRequestTypes.DUPLICATE
      ? this.maquinariaApi.maquinariaGET
      : this.maquinariaApi.maquinariaGET;

  public updatingTratamientos = false;
  public loadingData = true;

  /*** innovia litros aplicados */  
  public showLitros = false;

  /*** TRACTOR CONFIG ***/
  public idxMaquinaria: any;

  /*** Firma DonJardin ***/
  public signatureFile: File = new File([], "");

  constructor(
    public override route: ActivatedRoute,
    public override dashboard: DashboardService,
    public formApi: FormCommonApiService,
    public usuariosApi: UsuariosApiService,
    public override router: Router,
    public dashboardEvent: DashboardService,
    public maquinariaApi: MaquinariaApiService
  ) {
    super(
      route,
      router,
      dashboard,
      maquinariaApi.maquinariaPUT,
      maquinariaApi.maquinariaPOST,
      "albaran-maquinaria",
      "Actualizar Maquinaria",
      "Generar Maquinaria",
      "Duplicar Maquinaria"
    );
  }

  ngOnInit() {
    if (environment.features.horaFechaFinActualPorDefecto) {
      if (this.getType() === 0 || this.getType() === 2) {
        const date = new Date();
        this.model.hora_fin = date;
      }
    }
    const fieldsToSend = (["id"] as any).concat(
      this.form.formFieldsDatos
        .filter((it) => it && it.visible !== false)
        .map((it) => it.field),
    );

    this.expandFormFields();

    this.expandFormRequest();

    this.formRequest
      .setType(this.getType())
      .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
      .setRegisterId(this.getRegisterId())
      .setModel(this.model)
      .setGetRequest(this.getRequest)
      .setPostRequest(this.maquinariaApi.maquinariaPOST)
      .setPutRequest(this.maquinariaApi.maquinariaPUT)
      .setFormFields(
        Array.prototype.concat(
          this.form.formFieldsDatos.map((it) => it.field),
        )
      )
      .setFieldsToSend(fieldsToSend);

    this.formRequest.load();

    this.softInit(this.getType());
    this.form.requestType = this.getType();
    this.form.model = this.model;
    if (this.showClientesNotas && this.getType() === FormRequestTypes.CREATE) {
      this.model["notas_cliente"] = StorageManager.getClient()["notas"];
    }
    if (localStorage.getItem("tratamiento_temp")) {
      this.model = JSON.parse(localStorage.getItem("tratamiento_temp") ?? "");
      localStorage.removeItem("tratamiento_temp");
    }

    setTimeout(() => {
      this.loadingData = false;
    }, 2000);
  }

  convertDate(date: string) {
    if (date != undefined) {
      this.model.fecha = moment(date, "DD/MM/YYYY").toDate();
    }
  }

  updateImageModel(event: string) {
    this.model["imagen"] = event;
  }

  updateAfterImageModel(event: string) {
    this.model["image_after"] = event;
  }

  getSignature(event: File) {
    this.signatureFile = event;
  }

  updateFirma(event: any) {
    this.model["signature_img"] = event;
    this.formRequest.update();
  }

  public override getItemPosition(
    formFields: ItemInterface<any>[],
    field: string
  ) {
    let position = -1;
    formFields.forEach((item, index) => {
      if (item.field === field) {
        position = index;
      }
    });
    return position;
  }

  public formChanges(_tag: string) {
  }

  public async submit() {
    if (!this.formRequest.checkIfValid()) {
      this.requestButton.error = "Hay campos obligatorios";
    }

    this.formRequest.send();

    if (this.getType() === FormRequestTypes.DUPLICATE) {
      this.router.navigate(["dashboard", this.pathToGoBack]);
    }
  }

  private expandFormFields() {}

  private expandFormRequest() {
    this.formRequest.afterLoad((resolve) => {
      resolve(true);
    });

    this.formRequest.beforeSend((resolve) => {
      resolve(true);
    });

    this.formRequest.afterSend((resolve) => {
      StorageManager.saveSessionDataDonJardin(
        this.model.id_finca,
        this.model.hora_inicio,
        this.model.hora_fin
      );
      resolve(true);
    });

    this.formRequest.afterFinish((resolve) => {
      resolve(true);
    });
  }
}

export interface Datum {
  year: string;
  numero: string;
}

export interface RespReferencia {
  statusCode: number;
  statusMessage: string;
  data: Datum[];
}
