import { DatePickerTypeView } from 'primeng/datepicker';
import { FormField } from './form.interface';
import { FormGroup } from '@angular/forms';

export interface Input<T> {
    type: InputType;
    isEditable: boolean;
    placeholder?: string;
    data?: T | DropdownData<T>;
    defaultValue?: string | boolean | number | Date | T | null | undefined,
    maxLength?: number;
    style?: string;

    // Input-specific properties
    calendarConfiguration?: CalendarConfiguration;
    selectButtonConfiguration?: SelectButtonConfiguration<T>;
    dropdownConfiguration?: DropdownConfiguration<T>;
    textMaskConfiguration?: TextMaskConfiguration;
    twoFieldsConcatConfiguration?: TwoFieldsConcatConfiguration;
    textAreaConfiguration?: TextAreaConfiguration;
}

export interface DropdownData<T> {
    values: Value<T>[];
    filtered: FilteredValue<T>[];
    selected: T | null;
}

export interface Value<T> {
    value: T | null; 
    label: string;
}

export interface FilteredValue<T> {
    value: T | null;
    label: string;
    tipo?: string;
    unidades?: number;
}

export interface CalendarConfiguration {
    calendarView: DatePickerTypeView;
}

export interface SelectButtonConfiguration<T> {
    isDisabled: boolean;
    isMultiSelect: boolean;
    onClick: (arg0: T) => Promise<object>;
}

export interface DropdownConfiguration<T> {
    summation?: {
        summand: string,
        name: string,
        uds: string,
    };
    hasMaxHeight?: boolean;
    valuePrimaryKey?: string;
    filter?: (it: FilteredValue<T>) => boolean | undefined;
    swap?: (it: FormField<T>) => void;
    transform?: ((value: FormField<T>, model: FormGroup) => T)[];
}

export interface TextMaskConfiguration {
    mask: string;
}

export interface TwoFieldsConcatConfiguration {
    subField1: string;
    separator: string;
    subField2: string;
}

export interface TextAreaConfiguration {
    rows?: number;
    cols?: number;
    hasVoiceTranscription: boolean;
    canTakePhoto: boolean;
    photoField: string | undefined
}

export enum InputType {
    HIDDEN,
    EMPTY,
    EXTERNAL,
    ONLY_TEXT,
    TEXT,
    NUMERIC,
    PASSWORD,
    TEXT_MASK,
    TWO_FIELDS_CONCAT,
    TEXTAREA,
    DROPDOWN,
    DROPDOWN_SEARCH,
    MULTISELECT,
    MULTISELECT_SEARCH,
    TIME,
    CALENDAR,
    SWITCH,
    SELECT_BUTTON
}