import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../base-form';
import {RecogidasApiService} from '../../../../service/api/recogidas-api.service';
import {FincasApiService} from '../../../../service/api/fincas-api.service';
import {FormCommonApiService} from '../../../../service/api/formcommon-api.service';
import {AppFormRequest} from '../../../app-common/form-request/app-form-request';
import {build, list, pool} from '../../../../common/classes/request-builder';
import {Utils} from '../../../../common/utils';
import {StorageManager} from '../../../../common/storage-manager.class';
import { environment } from 'src/environments/environment';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { SiexApiService } from 'src/app/service/api/siex-api.service';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { FilteredValuesInterface, ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { RecogidaModel } from 'src/app/models/recogida.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Component({
    selector: 'app-nueva-carga',
    standalone: false,
    templateUrl: './recogidas-form.component.html',
    styleUrls: ['./recogidas-form.component.scss']
})
export class RecogidasFormComponent extends BaseForm<RecogidaModel> implements OnInit {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<RecogidaModel>();

    public appName = environment.appName;
    public sectorName = environment.features.sectorName;
    public parcelasName = environment.features.parcelasName;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
        ? 'S.\xa0C.'
        : 'S.\xa0Sigpac';
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public textoSuperficie = environment.features.textoSuperficie;

    public formRequest = new AppFormRequest<RecogidaModel>();
    public override model: RecogidaModel = {};

    public distributionOrder = [
        3, 3, 3
    ];

    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel | ParcelasModel[]> = list();
    public variedades: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public compradores: TypeAheadInterface<string> = list();
    public clientes: TypeAheadInterface<ClienteModel> = list();
    public clientes_comercios: TypeAheadInterface<ClienteModel> = list();
    public cooperativas: TypeAheadInterface<string> = list();
    public alerta = false;

    public formFields: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: 'Finca',
            inputType: {type: InputType.DROPDOWN},
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id',
        },
        {
            field: 'id_sector',
            label: this.sectorName,
            inputType: {type: InputType.DROPDOWN},
            values: this.sectores,
            valuePrimaryKey: 'id',
            filter: (it: FilteredValuesInterface<SectorModel>) => it.value?.id_finca === (this.fincas.selected as FincasModel).id
        },
        {
            field: 'ids_parcelas',
            label: this.parcelasName,
            inputType: {type: InputType.MULTISELECT},
            canSelectAll: true,
            required: this.appName === 'pyf' ? false : true,
            values: this.parcelas,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: FilteredValuesInterface<ParcelasModel>) => {
                const recogida = it.value;
                if (this.sectores.selected) {
                    return recogida?.id_sector === this.sectores.selected.id
                        && recogida?.id_finca === this.fincas.selected?.id;
                } else {
                    return recogida?.id_finca === this.fincas.selected?.id;
                }
            }
        },
        {
            field: 'fecha',
            label: 'Fecha de Recogida',
            inputType: {type: InputType.CALENDAR}
        },
        {
            field: 'albaran',
            label: 'Nº Albarán',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'lote',
            label: 'Nº Lote',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'id_variedad',
            label: 'Variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            visible: this.appName !== 'pyf',
            filter: (it: FilteredValuesInterface<VisitasVariedadMesModel>) => this.parcelas.filtered.some(p => 
                (p.value as ParcelasModel).id_variedad === it.value?.id),
        },
        {
            field: 'cantidad',
            label: 'Cantidad Kg',
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
    ];



    constructor(
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public clientesApi: ClientesApiService,
        public recogidasApi: RecogidasApiService,
        public fincasApi: FincasApiService,
        public formApi: FormCommonApiService,
        public siexApi: SiexApiService,
        public override router: Router
    ) {
        super(
            route,
            router,
            dashboard,
            recogidasApi.recogidasPUT,
            recogidasApi.recogidasPOST,
            'recogidas',
            'Actualizar Recogida',
            'Crear Recogida'
        );
    }

    ngOnInit() {
        this.initFormData();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.recogidasApi.recogidasGET)
            .setPostRequest(this.recogidasApi.recogidasPOST)
            .setPutRequest(this.recogidasApi.recogidasPUT)
            .setFormFields(this.formFields)
            .setFieldsToSend(
                ([
                    'id',
                    'cultivo',
                    'variedad',
                    'producto_nombre',
                    'plazo_seguridad',
                    'fecha_aplicacion',
                    'prohibido_hasta'
                ] as string[])
                    .concat(
                        this.formFields.map(it => it.field ?? '')
                    )
            );

        this.formRequest.beforeSend(resolve => {
            this.model.id_cultivo = this.model.id_variedad ?? '';

            this.model.cantidad = this.model.cantidad
                ? this.model.cantidad
                : '0';

            this.model.cultivo = (this.parcelas.selected as ParcelasModel[]).map((it: ParcelasModel) => it.cultivo).join(';');
            this.model.variedad = (this.parcelas.selected as ParcelasModel[]).map((it: ParcelasModel) => it.variedad).join(';');

            const clientId = (StorageManager.getClient() as ClienteModel).id;
            const clientIdFromFinca = (this.fincas.selected as FincasModel).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() as UserModel).id;
            const userIdFromFinca = (this.fincas.selected as FincasModel).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            resolve(true);
        });

        this.formRequest.load();
        this.softInit(this.getType());
        this.expandFormRequest();
    }

    public expandFormRequest(){
        this.formRequest.afterLoad((resolve) => {
            const fincaInterval = setInterval(() => {


                if ( this.fincas.filtered.length === 2  && this.getType() === 0 ){

                    this.model.id_finca = (this.fincas.filtered[1]?.value as FincasModel).id;
                    clearInterval(fincaInterval);
                }

            }, 1000);

            resolve(true);
        });

        setTimeout(() => {
            this.countSuperficieParcelas();
        }, 1000);
    }

    public submit() {

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.model.cantidad = this.model.cantidad?.replace(',', '.') ?? '';
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public formChanges(tag: string) {


        if (tag === 'id_finca') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
            this.model.id_variedad = null;
        }
        if (tag === 'id_sector') {
            this.model.ids_parcelas = null;
            this.model.id_variedad = null;
        }
        if (tag === 'ids_parcelas') {
            this.model.id_variedad = null;
        }
        if (tag === 'id_variedad') {
            this.recogidasApi.plazoSeguridadGET.toPromise({
                ids_parcelas: this.model.ids_parcelas,
                id_variedad: this.model.id_variedad} as never).then(res => {
                const unknown = res as unknown;
                const producto = unknown as ProductosModel;
                if ('nombre' in producto) {

                    this.model.producto_nombre = producto.nombre ?? '';
                    this.model.plazo_seguridad = producto.ps ?? '';
                    this.model.fecha_aplicacion = moment(producto.fecha_inicio, 'DD/MM/YYYY').format('DD/MM/YYYY');
                    if (this.model.plazo_seguridad !== 'NP') {
                        this.model.prohibido_hasta =
                        moment(producto.fecha_inicio, 'DD/MM/YYYY').add(this.model.plazo_seguridad, 'days').format('DD/MM/YYYY');
    
                        const fechaRecogida = moment(this.model.fecha, 'DD/MM/YYYY');
                        const prohibidoHasta =  moment(this.model.prohibido_hasta, 'DD/MM/YYYY');
                        this.alerta = fechaRecogida.diff(
                            prohibidoHasta, 'days') < 0 &&
                            fechaRecogida.diff(prohibidoHasta, 'days') >= -(this.model.plazo_seguridad ?? 0);
                    }
                }

                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }

        this.countSuperficieParcelas();
        this.requestButton.error = '';
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = '0';

        (this.parcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes((parcela.value as ParcelasModel).id) && this.model.parcelas_superficie) {
                this.model.parcelas_superficie +=
                    parseFloat(((parcela.value as ParcelasModel).superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.formFields, 'ids_parcelas');
        (this.formFields[idxParcelas] as ItemInterface<object>).label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
    }

    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = -1;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    private initFormData() {
        this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
            if (resp_fincas.length > 0 ) {
                if (resp_fincas.length === 1) {
                    this.model.id_finca = resp_fincas[0]?.id ?? '';
                }
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true, true),
            build(
                environment.features.activateSIEX ? this.siexApi.variedadesClienteGET : this.formApi.variedadesGET,
                this.variedades,
                'variedad',
                true
            ),
            build(this.clientesApi.clientesGET, this.clientes, 'nombre'),
        ];
        pool(builds, () => {
            setTimeout(() => {
                this.countSuperficieParcelas();
                this.formRequest.update();
            }, 3000);
        });
    }
}
