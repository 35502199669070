<div class="d-flex justify-content-center fs-1">
  <button class="border-0 bg-transparent" (click)="cameraIsVisible = true; photoIsDisplayed = false; getStream()">
    <i class="fa fa-camera cursor-pointer"></i>
  </button>
</div>

<p-dialog header="Cámara" [(visible)]="cameraIsVisible">
  <div class="w-80 mx-auto vh-100">
    
    <p-panel>
      @if (cameraIsLoading) {
        <div class="d-inline-block me-2 position-relative top-1">
          <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s"></p-progressSpinner>
        </div>
      }

      @if (photoIsDisplayed) {
        <p>Volver a la cámara, pulsa sobre la pantalla</p>
        <div class="d-flex justify-content-center">
          <button class="border-0" (click)="getStream()">
            <img #imageTag class="img-fluid" alt="cámara">
          </button>
        </div>
      } @else {
        <p>Pulsa sobre la pantalla para hacer una captura</p>
        <div class="d-flex justify-content-center">
          <video autoplay class="img-fluid"></video>
        </div>
      }
    </p-panel>

    <div class="d-flex justify-content-center mt-4">
      <button class="btn btn-dark mx-2" (click)="cameraIsVisible = false">Cerrar</button>
      @if (photoIsDisplayed) {
        <button class="btn btn-danger mx-2" (click)="getStream()">
          <i class="fa fa-trash fs-4"></i>
        </button>
      } @else {
        <button class="btn btn-success mx-2" (click)="takePhoto()">
          <i class="fa fa-camera fs-4"></i>
        </button>
      }
    </div>

  </div>
</p-dialog>