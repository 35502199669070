import { list } from "src/app/common/classes/request-builder";
import {
  ItemInterface,
  InputType,
} from "src/app/common/components/form-builder/form-builder.component";
import { Filtering } from "src/app/service/filtering/filtering";
import { environment } from "src/environments/environment";

export class ObraFormDefinition {
  public appName = environment.appName;
  public lastFieldChanged: string = "";

  public requestType: any;
  public model: any = {};
  public clientes: TypeAheadInterface = list();

  public formFieldsDatos: ItemInterface<any>[] = [
    {
      field: "nombre",
      label: "Nombre",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: 'cliente',
      label: 'Cliente',
      values: this.clientes,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
      required: true,
    }
  ];

  public formChanges(tag: string) {
    this.lastFieldChanged = tag;
  }
}

interface TypeAheadInterface {
  selected: any;
  values: any[];
  filtered: any[];
  filterCallback?: Filtering<any>;
}
