import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {ActivatedRoute} from '@angular/router';
import {FormCommonApiService} from '../../../../service/api/formcommon-api.service';
import {ExcepcionalesApiService} from '../../../../service/api/excepcionales-api.service';
import {OtrasDenominacionesApiService} from '../../../../service/api/otrasdenominaciones-api.service';
import {Filtering} from '../../../../service/filtering/filtering';
import {Utils} from '../../../../common/utils';
import moment, { MomentInput } from 'moment';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserActivityApiService } from '../../../../service/api/user_activity-api.service';
import { StorageManager } from '../../../../common/storage-manager.class';
import { BaseView } from '../../../../view/base-view';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { list } from '../../../../common/classes/request-builder';

import type { OnInit } from '@angular/core';
import type { Params } from '@angular/router';
import type { ItemInterface, ValuesInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { ProductosList } from 'src/app/models/stock/productos-list.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { firstValueFrom } from 'rxjs';
import { Uso } from 'src/app/models/productos/uso.model';
import { TreeNode } from 'primeng/api';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-productos-consultas',
    standalone: false,
    templateUrl: './productos-consultas.component.html',
    styleUrls: ['./productos-consultas.component.scss']
})
export class ProductosConsultasComponent extends BaseView<ProductosModel> implements OnInit {

    public showVademecumMenu = environment.features.showVademecumMenu;
    public showVademecumActivity = environment.features.showVademecumActivity;
    public showHistoricoFT = environment.features.showHistoricoFT;
    public showExepcionales = environment.features.showExepcionales;
    public showOtrasDenominaciones = environment.features.showOtrasDenominaciones;
    public showInfoCambios = environment.features.showInfoCambios;
    public showVademecumMenuBotones = environment.features.showVademecumMenuBotones;
    public showOrdenAplicacionProductos = environment.features.showOrdenAplicacionProductos;

    public dateValue = '';

    public es = {
        closeText: 'Cerrar',
        prevText: '<Ant',
        nextText: 'Sig>',
        currentText: 'Hoy',
        monthNames: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: [
            'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        weekHeader: 'Sm',
        dateFormat: 'DD/MM/YYYY',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',

        timeOnlyTitle: 'Elegir una hora',
        timeText: 'Hora',
        hourText: 'Horas',
        minuteText: 'Minutos',
        secondText: 'Segundos',
        millisecText: 'Milisegundos',
        microsecText: 'Microsegundos',
        timezoneText: 'Uso horario',
        timeFormat: 'HH:mm',
        timeSuffix: '',
        amNames: ['a.m.', 'AM', 'A'],
        pmNames: ['p.m.', 'PM', 'P'],
    };

    public currentUser = StorageManager.getUser();
    public actionTypes = {
        verInfo: 'VER_INFO',
        verFicha: 'VER_FICHA',
        verHistorico: 'VER_HISTORICO',
        descargarHistorico: 'DESCARGAR_HISTORICO'
    };
    public searchText = '';
    public forma_epoca = '';
    public show_forma_epoca = false;

    public dropdownStyle = (this.showExepcionales && this.showOtrasDenominaciones && this.showInfoCambios) ? 
        null :
        {width: '300px'};

    public headerCal = {
        editable: true,
        eventLimit: false,
        left: 'title',
        center: 'Locatec',
        right: 'today prev,next'
    };

    public options = {
        selectable: true,
        height: 400,
        width: 280,
        aspectRatio: 0.8,
        weekNumbers: true,
        weekNumberTitle: 'Semana'
    };

    public fechaAutorizExcep = '--/--/----';
    public fechaOtrasDenom = '--/--/----';
    public fechaActualizacionMagrama: string | null = null;
    public weekClicked = '';
    public referenciaSel = '';
    public nombreComercial = '';

    public mostrarHistorico = false;
    public mostrarOrdenAplicacion = false;
    public isProductosVisible = true;
    public isExcepcionalesVisible = false;
    public isOtrasDenominacionesVisible = false;

    public isDownloading = false;
    public fichaNoExiste = false;
    public numRegistroIsSet = false;
    public numRegistro = '';
    public override model: ProductosModel = {nombre: '', materia_activa: ''};
    public override canShow: Record<string, boolean> = {};
    public productosChanged = [];

    public historicoModel: ProductosModel = {nombre: '', materia_activa: ''};
    public anyosList = list('2020', '2019', '2018');
    public mesesList = list('enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre',
        'noviembre', 'diciembre');

    public historicoStatus: Record<string, string[]> = {};
    public hasHistorico = false;

    public formFieldsHistorico: ItemInterface<object>[] = [
        {
            field: 'anyo',
            label: 'Año',
            inputType: {type: InputType.DROPDOWN},
            values: this.anyosList,
            disabled: !this.hasHistorico
        },
        {
            field: 'mes',
            label: 'Mes',
            inputType: {type: InputType.DROPDOWN},
            values: this.mesesList,
            disabled: !this.hasHistorico
        }
    ];

    public override filtering: Filtering<ProductosModel> = new Filtering<ProductosModel>();

    public first = 0;

    public searchValue = {
        term: ''
    };

    public searchValueExcepcionales = {
        term: ''
    };

    public searchValueDenominaciones = {
        term: ''
    };

    public usos: TypeAheadInterface<ProductosList[]> = {
        selected: null,
        values: [],
        filtered: [{label: 'Usos...', value: null}],
        label: 'Usos...'
    };

    public agentes: TypeAheadInterface<ProductosList> = {
        selected: null,
        values: [],
        filtered: [{label: 'Agentes...', value: null}],
        label: 'Agentes...'
    };

    public agentesFilter: Filtering<{value: null | ProductosList; label: string;}> = 
        new Filtering<{value: null | ProductosList; label: string;}>();

    public cols: ColInterface[] = [
        {field: 'referencia', header: 'Nº\xa0Reg.', filter: '', width: '90px'},
        {field: 'nombre_comercial', header: 'Nombre', filter: '', width: '100px'},
        {field: 'titular', header: 'Titular', filter: '', width: '100px'},
        {field: 'fecha_inscripcion', header: 'Inscripción', filter: '', width: '100px'},
        {field: 'fecha_caducidad', header: 'Caducidad', filter: '', width: '100px'},
        {field: 'composicion', header: 'Composición', width: '300px' , overflow: 'hidden'},
    ];

    public colsExcep: ColInterface[] = [
        {field: 'cultivo', header: 'Cultivo', filter: '', width: '120px'},
        {field: 'plaga_funcion', header: 'Plaga/Función', filter: '', width: '290px'},
        {field: 'sustancia_activa', header: 'Sustancia Activa', filter: '', width: '200px'},
        {field: 'producto_comercial', header: 'Producto Comercial', filter: '', width: '290px'},
        {field: 'fecha_inicio', header: 'Fecha Inicio', filter: '', width: '130px'},
        {field: 'fecha_fin', header: 'Fecha Fin', filter: '', width: '130px'},
    ];

    public colsOtrasDenom: ColInterface[] = [
        {field: 'num_registro', header: 'Nº Registro', filter: '', width: '100px'},
        {field: 'nombre_comercial', header: 'Nombre Comercial', filter: '', width: '150px'},
        {field: 'materia_activa', header: 'Materia Activa', filter: ''},
        {field: 'concesionario', header: 'Concesionario', filter: ''},
        {field: 'denominaciones_comunes', header: 'Denominación Común', filter: '', width: '170px'},
        {field: 'fecha_aceptacion', header: 'Fecha Aceptación', filter: '', width: '120px'},
    ];


    public typeFilter: {type: string, mes1: number; mes2: number} = {
        type: '',
        mes1: 0,
        mes2: 0
    };
    public filterUltimosCambios = false;

    public autorizFiltering: Filtering<ProductosModel> = new Filtering<ProductosModel>();

    public aplicado = {
        selected: 'vigente'
    };

    public mostrarTratamientosAplicados = true;
    public updateVademecum = false;
    public alertVademecum = true;

    constructor(public formApi: FormCommonApiService,
        private dashboardEvents: DashboardService,
        public excepcionalesApi: ExcepcionalesApiService,
        public otrasDenominacionesApi: OtrasDenominacionesApiService,
        public userActivityApi: UserActivityApiService,
        private http: HttpClient,
        private route: ActivatedRoute) {
        super(dashboardEvents, formApi.magramaAll);

        this.route.params.forEach((params: Params) => {
            if (params['num_reg']) {
                this.numRegistroIsSet = true;
                this.numRegistro = params['num_reg'];
            }
        });
    }

    ngOnInit() {
        this.defineMagramaResponse();
        this.formApi.data.toPromise().then(res => {
            if (res && res[0]) {
                this.fechaAutorizExcep = res[0].excepcionales_update ?? '';
                this.fechaActualizacionMagrama = res[0].fecha_actualizacion_magrama ?? '';
                this.fechaOtrasDenom = res[0].otras_denominaciones_update ?? '';
                this.updateVademecum = TRUTHY_VALUES.includes(res[0].actualizando ?? false);
            }
            return;
        }).catch (e => {
            console.log('catch en formApi.data: ' + e);
        }
        );

        if (this.showVademecumMenu) {
            this.dashboardEvents.setMenuItem('consultas');
        } else {
            this.dashboardEvents.setSubmenuItem('consultas', !this.numRegistroIsSet);
        }
        
        this.dashboardEvents.changeClientActive = true;

        this.autorizFiltering.addFilter((copy: TreeNode<ProductosModel>[] | void) => (copy as TreeNode<ProductosModel>[]).filter(row => {
            const producto = row as ProductosModel;

            switch (this.aplicado.selected) {
            case 'vigente':
                return producto.activo === '1';
            case 'no-vigente':
                return producto.activo !== '1';
            default:
                return true;
            }
        }));

        this.autorizFiltering.filter();

        this.initData();
        this.initFilter();
    }
    
    public filtrarTratamientos(value: string) {
        this.aplicado.selected = (this.aplicado.selected === value) ? '' : value;
        this.autorizFiltering.filter();
    }

    public toggleUltimosCambiosFilter() {
        this.filterUltimosCambios = !this.filterUltimosCambios;

        if (this.filterUltimosCambios) {
            this.filtering.addFilter((data: TreeNode<ProductosModel>[] | void) => 
                (data as TreeNode<ProductosModel>[]).filter(it => it.data?.actualizacion_reciente));
            this.filtering.filter();
        } else {
            this.filtering.value = this.formApi.magramaAll.value as TreeNode<ProductosModel>[];
        }
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (col.field === 'producto_comercial' ||
            col.field === 'cultivo' ||
            col.field === 'plaga_funcion' ||
            col.field === 'sustancia_activa' ||
            col.field === 'fecha_inicio' ||
            col.field === 'fecha_fin') {
            return (value?.length > 25) ? value.slice(0, 25 - 1) + '…' : value;
        }
        if (col.field === 'materia_activa') {
            return (value?.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }
        return value;
    }

    public globalConditionalStyle(
        _cellValue: string, 
        colDefinition: ColInterface, 
        _colGroup: ColInterface[], 
        rowValue: TreeNode<ProductosModel>): ColStylesInterface {
        const producto = rowValue.data as ProductosModel;
        let estiloVademecum: ColStylesInterface = {};
        if (colDefinition.field === 'composicion') {
            estiloVademecum = {
                rowStyle: {
                    color: 'black',
                    backgroundColor: '#ffffff',
                    borderBottom: '1px solid #dddddd'
                },
                style: {
                    'max-width': '30px',
                }
            };

            const diffInsc = moment(new Date()).diff(Utils.toDate(producto.fecha_inscripcion ?? ''), 'months');
            if (diffInsc < 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#cbdecb',
                        borderBottom: '1px solid #dddddd'
                    },
                    style: {
                        'max-width': '30px',
                    }
                };
            } else {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffffff',
                        borderBottom: '1px solid #dddddd'
                    },
                    style: {
                        'max-width': '30px',
                    }
                };
            }


            const diff = moment(new Date()).diff(Utils.toDate(producto.fecha_caducidad ?? ''), 'months');
            if (diff > 12) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'white',
                        backgroundColor: '#222222',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff >= 6) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ff7f7f',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            } else if (diff > 0) {
                estiloVademecum = {
                    rowStyle: {
                        color: 'black',
                        backgroundColor: '#ffcf78',
                        borderBottom: '1px solid #dddddd'
                    }
                };
            }
            
        }

        if (colDefinition.field === 'plaga_funcion') {
            estiloVademecum = {
                style: {
                    'max-width': '300px',
                }
            };
        }
        if (colDefinition.field === 'sustancia_activa') {
            estiloVademecum = {
                style: {
                    'max-width': '300px',
                }
            };
        }
        return estiloVademecum;
    }

    public initData() {
        this.formApi.mgUsos.safePerform();
        this.formApi.mgUsos.response(data => {
            this.usos.filtered =
                (data instanceof Array ? data : [])
                    .filter(it =>
                        (it.uso || '').length > 0
                        && it.uso !== '(incluidas lasnectarinas e'
                    ).map(it =>
                        ({label: it.uso, value: it})
                    );

            this.usos.label = 'Usos...';
        });

        this.formApi.mgAgentes.response(data => {
            this.agentes.values =
                (data instanceof Array ? data : [])
                    .filter(it =>
                        (it.agente || '').length > 0
                        && (it.uso || '').length > 0)
                    .map(it => ({
                        label: it.agente,
                        value: it
                    }));
            this.agentesFilter.setValue(this.agentes.values);
        });
        this.formApi.mgAgentes.safePerform();
    }

    public initFilter() {
        this.filtering.addFilter((data: TreeNode<ProductosModel>[] | void) => (data as TreeNode<ProductosModel>[])
            .filter(it =>
                Utils.toDate(it.data?.fecha_caducidad ?? '') !== null
                    && Utils.toDate(it.data?.fecha_inscripcion ?? '') !== null
            )
            .sort(
                Filtering.sort('nombre_comercial', 1)
            ));

        this.filtering.addFilter((data: TreeNode<ProductosModel>[] | void) => this.typeFilter.type
            ? (data as TreeNode<ProductosModel>[])
                .filter(it =>
                    (moment(new Date()).diff(Utils.toDate(it.data?.[this.typeFilter.type] as string), 'months') > this.typeFilter.mes1) &&
                    (moment(new Date()).diff(Utils.toDate(it.data?.[this.typeFilter.type] as string), 'months') < this.typeFilter.mes2)
                )
                .sort(
                    Filtering.sort(this.typeFilter.type, 1)
                )
            : data);

        this.filtering.addFilter((data: TreeNode<ProductosModel>[] | void) => {
            this.first = 0;
            return data;
        });


        this.agentesFilter.addFilter(() => {
            if (this.usos.selected) {
                this.agentes.filtered = this.agentes.values
                    .filter((agente) => (this.usos.selected || []).map((it: ProductosList) => it.uso)
                        .indexOf(agente.value?.uso) !== -1);

                const agentesAux: ValuesInterface<ProductosList>[] = [];
                this.agentes.filtered.forEach((agente) => {
                    const labels = agentesAux.map(it => it.label);
                    if (labels.includes(agente.label)) {
                        agentesAux[labels.indexOf(agente.label)]?.value?.usos?.push(agente.value?.uso ?? '');
                    } else {
                        if (agente.value) {
                            agente.value.usos = [agente.value.uso ?? ''];
                            agentesAux.push(agente);
                        }
                    }
                });

                this.agentes.filtered = agentesAux;
                this.agentes.label = this.agentes.filtered.length > 0 ?
                    'Agentes...' : 'Sin agentes...';

            }
        });
    }


    public myFilter(type: string, mes1: number, mes2: number) {
        this.typeFilter.type = type;
        this.typeFilter.mes1 = mes1;
        this.typeFilter.mes2 = mes2;
        this.filtering.filter();
    }

    public resetSearch() {
        this.typeFilter.type = '';
        this.typeFilter.mes1 = 0;
        this.typeFilter.mes2 = 0;
        this.usos.selected = null;
        this.agentes.selected = null;
        this.searchValue.term = '';
        this.searchValueExcepcionales.term = '';
        this.searchValueDenominaciones.term = '';
        this.numRegistroIsSet = false;
        this.numRegistro = '';
        this.search();
    }

    public search() {
        this.agentesFilter.filter();
        const payload: ProductosList = {};

        const usoSelected = (this.usos.selected || []).length > 0,
            agentesSelected = (this.agentes.selected as ProductosList[] || []).length > 0,
            textAdded = (this.searchValue.term || []).length > 0;

        if (usoSelected) {
            if ((this.usos.selected || []).length >= 6) {
                this.usos.selected?.shift();
            }
            payload.usos = (this.usos.selected || []).map((it: ProductosList) => (it || {}).uso) as string[];
            this.searchValue.term = '';
        }
        if (agentesSelected) {
            if ((this.agentes.selected as ProductosList[] || []).length >= 6) {
                (this.agentes.selected as ProductosList[])?.shift();
            }
            payload.agentes = (this.agentes.selected as ProductosList[]).map((it: ProductosList) => (it || {}).agente) as string[];
            this.searchValue.term = '';
        }

        if (textAdded) {
            payload.producto = this.searchValue.term;
        }

        this.formApi.magramaAll.cancelablePerform(payload as ProductosModel[]);
        this.formApi.magramaAll.response(data => {
            this.filtering.setValue((data as TreeNode<ProductosModel>[]).filter((it: TreeNode<ProductosModel>) =>
                Utils.toDate(it.data?.fecha_caducidad ?? '') !== null
                && Utils.toDate(it.data?.fecha_inscripcion ?? '') !== null
            ));
        });
    }

    public onSearchText(term: string) {
        this.searchText = term;
        if ((this.searchValue.term || '').length > 0
            && (this.usos.selected || []).length === 0
            && (this.agentes.selected as ProductosList[] || []).length === 0) {
        }
    }

    public showProductos() {
        this.resetSearch();
        this.isProductosVisible = true;
        this.isExcepcionalesVisible = false;
        this.isOtrasDenominacionesVisible = false;
    }

    public showExcepcionales() {
        this.resetSearch();
        this.isProductosVisible = false;
        this.isExcepcionalesVisible = true;
        this.isOtrasDenominacionesVisible = false;
    }

    public showOtras() {
        this.resetSearch();
        this.isProductosVisible = false;
        this.isExcepcionalesVisible = false;
        this.isOtrasDenominacionesVisible = true;
    }

    public showInfo(data: ProductosModel) {
        this.saveActivity(this.actionTypes.verInfo, data);
        this.show('info', data);

        this.formApi.magramaDetalleProducto.toPromise({p3: '"' + (this.model.referencia) + '"'} as never).then(usos => {
            this.model.last_fecha_actualizacion = 
                this.model.fecha_actualizacion ? 
                    moment(this.model.fecha_actualizacion, 'DD/MM/YYYY') : 
                    moment('01/01/0001', 'DD/MM/YYYY');

            this.model.usos = (usos || []).map((uso: Uso) => {
                if (moment(uso.fecha_actualizacion, 'DD/MM/YYYY').isSameOrAfter(this.model.last_fecha_actualizacion)) {
                    this.model.last_fecha_actualizacion = moment(uso.fecha_actualizacion, 'DD/MM/YYYY');
                }
                if (uso.fecha_actualizacion === this.fechaActualizacionMagrama) {
                    this.model.actualizacion_reciente = true;
                } else {
                    uso.codigo_actualizacion_old = uso.codigo_actualizacion ?? '';
                    uso.fecha_actualizacion_old = uso.fecha_actualizacion;
                    uso.codigo_actualizacion = null;
                    uso.fecha_actualizacion = null;
                }

                if (uso.codigo_actualizacion_old === 'USOAGENTE_DEL') {
                    return {} as Uso;
                }
                return uso;
            }).filter((it: Uso | null) => it !== null);

            this.model.fecha_actualizacion = this.model.last_fecha_actualizacion.format('DD/MM/YYYY');
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    public override show(modal: string, data: ProductosModel) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {nombre: '', materia_activa: ''};
        this.canShow[modal] = false;
    }

    public muestraFormaEpoca(data: string) {
        this.forma_epoca = '';



        setTimeout(() => {
            this.forma_epoca = data;
            this.show_forma_epoca = true;
            window.scrollTo(0, 0);
        }, 100);
    }

    public muestraHistorico(data: ProductosModel) {
        this.formApi.historicoCheckGET.toPromise({ref: data.referencia} as never).then(result => {
            this.historicoStatus = result;

            const anyos = Object.keys(this.historicoStatus);
            if (anyos.length === 0) {
                this.hasHistorico = false;
                this.historicoModel = {nombre: '', materia_activa: ''};

                if (this.formFieldsHistorico[0]) {
                    this.formFieldsHistorico[0].values = list(['-', null]);
                }

                if (this.formFieldsHistorico[1]) {
                    this.formFieldsHistorico[1].values = list(['-', null]);
                }
            } else {
                this.hasHistorico = true;

                if (this.formFieldsHistorico[0]) {
                    this.formFieldsHistorico[0].values = list(...anyos);   
                }

                this.historicoModel.anyo = anyos[0] ?? '';
    
                const meses = this.historicoStatus[this.historicoModel.anyo];
                
                if (this.formFieldsHistorico[1]) {
                    this.formFieldsHistorico[1].values = list(...meses ?? []);
                }
                this.historicoModel.mes = meses?.[0] ?? '';
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        this.saveActivity(this.actionTypes.verHistorico, data);
        this.mostrarHistorico = true;

        window.scrollTo(0, 0);
        const body = document.getElementsByTagName('body')[0];

        if (body) {
            body.style.overflow = 'hidden';
        }

        this.referenciaSel = data.referencia?.toString() ?? '';
        this.nombreComercial = data.nombre_comercial ?? '';
    }

    public ocultaHistorico(event: boolean) {
        if (event === false) {
            const body = document.getElementsByTagName('body')[0];

            if (body) {
                body.style.overflow = 'visible';
            }
        }
    }

    public deleteHistoricoData() {
        this.historicoModel = {nombre: '', materia_activa: ''};
        this.historicoStatus = {};
    }

    public historicoFormChanges(tag: string) {
        if (tag === 'anyo') {
            const meses = this.historicoStatus[this.historicoModel.anyo ?? ''];

            if(this.formFieldsHistorico[1]) {
                this.formFieldsHistorico[1].values = list(...meses ?? []);
            }

            this.historicoModel.mes = meses?.[0] ?? '';
        }
    }

    public async descargaFichaHistorico() {
        this.saveActivity(this.actionTypes.descargarHistorico, {
            nombre: '',
            materia_activa: '',
            referencia: +this.referenciaSel,
            nombre_comercial: this.nombreComercial
        });

        this.isDownloading = true;

        const urlPDF = 'https://fichastecnicasfitos.s3.eu-west-3.amazonaws.com';
        const final = urlPDF + '/' + this.historicoModel.anyo + '/' + this.historicoModel.mes + '/' + this.referenciaSel + '.pdf';

        await firstValueFrom(this.http.get<{status: number}>(final))
            .then((res: {status: number}) => {
                console.log(res);
                return (res.status === 200);
            })
            .catch(error => {
                if (error.status === 200) {
                    this.isDownloading = false;
                    this.fichaNoExiste = false;
                    window.open(final, '_blank');
                } else if (error.status === 403) {
                    this.fichaNoExiste = true;
                    setTimeout(() => {
                        this.fichaNoExiste = false;
                    }, 2000);
                    this.isDownloading = false;
                } else {
                    console.log(error);
                }
            });

    }

    public y2k(number: number) {
        return (number < 1000) ? number + 1900 : number;
    }

    public getWeek(year: number, month: number, day: number | undefined) {
        const when = new Date(year, month, day);
        const newYear = new Date(year, 0, 1);
        let offset = 7 + 1 - newYear.getDay();

        if (offset === 8) {
            offset = 1;
        }

        const daynum = (
            (
                Date.UTC(this.y2k(year), when.getMonth(), when.getDate(), 0, 0, 0)
                - Date.UTC(this.y2k(year), 0, 1, 0, 0, 0)
            ) / 1000 / 60 / 60 / 24
        ) + 1;

        let weeknum = Math.floor((daynum - offset + 7) / 7);

        if (weeknum === 0) {
            year--;
            const prevNewYear = new Date(year, 0, 1);
            const prevOffset = 7 + 1 - prevNewYear.getDay();
            if (prevOffset === 2 || prevOffset === 8) {
                weeknum = 53;
            } else {
                weeknum = 52;
            }
        }
        return weeknum;
    }

    public saveActivity(accion: string, producto: ProductosModel) {
        if (!this.showVademecumActivity) {
            return;
        }

        const obj = {
            accion,
            id_usuario: this.currentUser.id,
            nombre_usuario: this.currentUser.nombre + ' ' + this.currentUser.apellidos,
            nombre_producto: producto ? producto.nombre_comercial : '', 
            referencia: producto ? producto.referencia : '', 
            texto_buscar: this.searchText
        };

        this.userActivityApi.vademecumActivityPOST.safePerform(obj as never);
    }

    private defineMagramaResponse() {
        this.formApi.magramaAll.response((data: ProductosModel[]) => {
            // Eliminar duplicados
            const deletedArr: ProductosModel[] = [];
            const fechasAct: Record<string, MomentInput> = {};
            for (const producto of data) {
                const repes = (data || []).filter(it => it.referencia === producto.referencia);
                if (repes.length > 1) {
                    const index = data.map(it => it.referencia).indexOf(repes[0]?.referencia);
                    const deleted = data.splice(index, (repes.length - 1));
                    deletedArr.push(deleted?.[0] ?? {nombre: '', materia_activa: ''});
                }
                if (fechasAct[producto.referencia ?? '']) {
                    if (moment(producto.fecha_actualizacion, 'DD/MM/YYYY')
                        .isSameOrAfter(moment(fechasAct[producto.referencia ?? ''], 'DD/MM/YYYY'))) {
                        fechasAct[producto.referencia ?? ''] = producto.fecha_actualizacion;
                    }
                } else {
                    fechasAct[producto.referencia ?? ''] = producto.fecha_actualizacion;
                }
            }

            const filteredData = data
                .filter(it =>
                    (Utils.toDate(it.fecha_caducidad ?? '') !== null) &&
                    (Utils.toDate(it.fecha_inscripcion ?? '') !== null)
                ).map(it => {
                    it.fecha_actualizacion = fechasAct[it.referencia ?? ''];
                    it.actualizacion_reciente = (it.fecha_actualizacion === this.fechaActualizacionMagrama);
                    return it;
                });

            this.filtering.setValue(filteredData as TreeNode<ProductosModel>[]);

            if (this.numRegistroIsSet) {
                this.searchValue = {
                    term: this.numRegistro
                };
            }
        });
    }


    

}
