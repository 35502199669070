export const features = {
  /**
   * PROPIO
   */
  enableLegalMode: false,
  applicationType: 'fitogest', // 'agrogest', etc.
  hasPlantas: false,
  formacionUrl: '',

  /**
   * FUNCIONAMIENTO BÁSICO
   */
  /** Determina si los usuarios no administradores pueden ver todos los registros, incluyendo los de otros usuarios. */
  userCanSeeAllRecords: false,
  userCanDeleteRecords: true,
  /** Determina si los usuarios administradores pueden ver todos los registros, incluyendo los de otros usuarios. */
  adminCanSeeAllRecords: true,

  userSoloCanSeeInfo: false,

  /** 
   * MÓDULOS Y/O MEJORAS
   * 
   * Aquí van todos los módulos y/o partes de la aplicación que se tienen que mostrar 
   * o no en función del cliente.
   * 
   */

  // -1. Login
  canRecoverPassword: false, // DQVademecum: true
  canRegister: false, // Sequiador: true
  hasUsuarios: true, // Citrusvol: false
  showUsuariosLogs: false, // Mostrar lista del log de los usuarios

  // 0. Dashboard
  showEstadisticas: false, // Fitogest: true
  showVisitas: true,
  showMapas: true,
  showMapaParcelas: true,
  showMapaRutas: false,
  showMapaTratamientos: false,
  showAnalisis: false, // Default: false; Simat: true
  showClientesCrm: false,
  showVisitasSeguimiento: false,

  /**
   * Muestra Nº analisi en el formulario de analisis para poco despues ser mostrada en el CC 
   */
  showAnalisisNumeroAnalisis: false, // Default: false; pyf: true
  showCultivosBajoAguaFields: false,
  showCampos: false, // Máñez y Lozano: true
  showVisitasCamposPersonalizados: false,
  showVisitasAgenda: true, // Hernandorena: false
  showVisitasPorSemana: false, // Hernandorena: true
  showVisitasVariedadMes: false, // Default: true; Oliverfutur: false
  showVisitasConsultas: false, // Default: false; Agromainsa: true, Fitogest: true
  showBackup: false, // Ebro: true
  showAllCultivosPrecedentes: false,
  hiddenVisitasCerezo: false,
  hiddenVisitasContador: false,
  showVisitasFormAbonosFolques: false,
  showVisitaCarenciasMultiple: false,
  showDocumentUploadVisitas: false, // AVA: true
  showEstadoFenologicoAlmendro: false,
  showEstadoFenologicoVid: false,

  // 1. Clientes
  hasClients: true,
  userCanSeeClientes: true,
  showClientesObservaciones: false, // Ebro: true
  showClientesNotas: false, // Don Jardin: true
  showClientesRegepa: false,
  hasClientesAliasField: false,
  showFieldsClientesEstadoReglas: false,
  // 1 Clientes > CRM
  showCrm: false,
  // 2. Fincas
  hasFincas: true,
  showSectores: true, // Máñez y Lozano: false
  showTipoExplotacion: true,
  showFincasRazonSocial: false, // Vicente Giner: true
  showFincasGenGml: false,
  showFincasCSV: false,
  showBotonPrintParcelas: false,
  showSiex: false,

  // 3. Ventas
  showVentas: true, // Hernandorena: false
  canAddVentas: true,
  canAddCompradores: true,
  forceHiddenVentas: false,
  showAlbaranes: false,

  // 4. Trabajadores / Aplicadores
  hasAplicadores: true, // Máñez y Lozano: false
  showTrabajadores: true, // Máñez y Lozano: false
  trabajadoresAsUsuarios: false, // Innovia: true
  showTrabajadoresFertilizantes: false, //AVA: true

  // 5. Tareas
  showTareasFitosanitarias: true, // Máñez y Lozano: false
  showTareasFertilizantes: true, // Savia Tropical: false
  showTareasFertilizantesTipoFertilizacion: false, // Only show Armentia, Prosanzcu y Pyf
  showTareasBio: false, // La Fast: true
  showTareasBioSectorMultiple: true,
  showTareasBioParcelaMultiple: true,
  showGenerateRecetaBio: false,
  showCalendarioAbonado: false, // Savia Tropical: true
  showControlRiego: false, // Vicente Giner: true
  showClimatologia: false, // Vicente Giner: true
  showBioMotivo: false,
  showBioOrden: false,
  showTareasFitoBetweenDates: false,
  showTareasPrioridad: false,
  showTareasAplicados: false,
  horaFechaFinActualPorDefecto: false,
  filterInputParcelas: false,

  showTareasMto: false,
  showCalendarioTareas: false,
  showAssignUserTasksFitosanitario: false,
  showAssignUserTasksFertilizante: false,
  showAssignUserTasksGenericas: false,
  showAssignUserInAllTasks: false, // AVA: true



  // 6. Productos
  hasProductos: true, // Máñez y Lozano: false
  showProductosSimple: false, // Máñez y Lozano: false
  showVademecumMenu: true,
  showVademecumActivity: false, // DQVademecum: true
  showWidthFertilizanteNombre: true, // Ancho nombre marca comercial en tabla
  showProductosMaxAplicacion: false,
  showFertiRoturaStock: false,
  showProductosBio: false,

  // 7. Tratamientos
  showTratamientosMenu: true,
  showTratamientosControl: false, // Hernandorena: true
  showTratamientosManezLozano: false, // Máñez y Lozano: true
  showTratamientosExportCsv: false, // Sequiador: true
  showUdsCuaderno: false,

  // 8. Visitas
  showGenerarRegistroVisita: false, // La Fast: true
  showGenerarRecetaVisita: true,
  showHorasVisitas: true, // Agromainsa: true
  showLocationLatLng: false, // Ver visita en mapa 

  //9. Subcontratos
  showSubcontrato: false,

  // X. Usuarios
  showUsuarios: false, // Máñez y Lozano: true

  // @. Varios componentes
  showEnviarWhatsapp: true, // Default: true; Fitogest: true
  showEnviarTelegram: true, // Default: true; Fitogest: true
  showEnviarCorreo: false, // Default: false; Fitogest: true
  showEnviarWhatsappVisitas: true, // Default: true; Fitogest: true
  showEnviarCorreoVisitas: false, // Default: false; Fitogest: true
  puedeImprimir: false, // Default: false; Agromainsa, Fitogest, Palomares: true


  /**
   * MOSTRAR / NO MOSTRAR
   * 
   * Para activar o desactivar características concretas.
   * 
   */

  // 0. Dashboard
  showMainMenu: true, // Citrusvol: false
  showSidebar: true, // DQVademecum: false
  showWatermark: true, // DQVademecum: false
  showPerfil: true, // Melià (basic): false
  showPerfilUnidades: false, // Máñez y Lozano: true
  showPerfilFincas: false, // Vicente Giner: true

  // 1. Clientes
  hasCodigoCliente: false, // La Fast: true
  showMostrarTodosClientes: false, /* Ebro: false ===> !IMPORTANT Esta variable no se utliza en ninguna parte del codigo 
    pero esta inicializada en algunas variables de algunos clientes */
  clientesMostrarTodosDefault: false, // Prosanzcu: true
  showClientesTecnico: true, // Ebro: false
  showClientesCooperativa: false, // Esp. Granada: true
  showClientesFirma: false, // Esp. Granada: true
  showClientesCsv: false,

  // 2. Fincas
  showCargaPAC: false,
  showCuadernoDeCampo: true, // Máñez y Lozano: false
  showCuadernoDeCampoPorCliente: false, // IngeniaAgroservicios: true
  showCuadernoPorSector: false, // Prosanzcu: true
  fincasNifRequired: false, // Ebro: true
  fincasNifAutocomplete: false, // Ebro: true
  showFincasEdad: false, // Savia Tropical: true
  showFincasProductos: false, // VGiner: true
  showFincasCodigo: false,
  showFincasInformeTratamientos: false,
  showFincasLibroFertilizantes: false,
  showNumeroFincas: false, // Cooperativas: true
  // 2. Fincas > Análisis
  showAnalisisParcelas: false, // Simat: true
  showAnalisisBoletin: false,  // LaPlana : true
  showAnalisisSectorParcelas: false,  // Ava, Fitogest : true
  showAnalisisM3Agua: false,  // Torrepacheco : true
  showAnalisisMgrl: false,  // Torrepacheco : true

  // 2. Fincas > Sectores
  showPartida: false, // Default: false; Oliverfutur: true
  showSectorPlantas: false, // Agrogest: false

  // 2. Fincas > Parcelas
  showMarcoPlantacion: true, // Default: true; Palomares, Prosanzcu: false
  showAnyoPlantacion: true, // Default: true; Palomares, Prosanzcu: false
  showPlantas: true, // Default: true; Palomares, Prosanzcu: false
  showPatron: true, // Default: true; Palomares, Prosanzcu: false
  showDatosMedioambientales: false, // LaPlana : true

  showParcelasFincasCol: true, // Simat: false
  showParcelasSector: true, // Simat: false
  showParcelasProvinciaCol: false, // Simat: false
  showParcelasMunicipioCol: false, // Simat: false
  showNombreParcela: true, // Simat: true
  variedadCanBeVarios: false, // Hernandorena: true
  showParcelasActivo: true, // Buitech, pero aplica a todos
  showParcelasActivoCol: true,
  showParcelasPartidasCol: false,

  showParcelasEstacion: false,
  showParcelasCodigoTrazabilidad: false,
  showParcelasPartida: false,
  showParcelasComercio: false,
  showParcelasPozos: false,
  showParcelasTurbo: false,
  showParcelasComercioDropdown: false,
  showParcelasLote: false,



  /**
   * Boton SigPac formulario parcela
   * 
   * Hace visible el boton SigPac del formulario.
   * 
   * Para CTL: false
   */
  showSigPacParcela: true, // CTL: false

  // Para Armentia: ellos sólo usan la superficie Sigpac.
  hasSuperficieCultivada: true,

  //Para PYF
  showAgrupacionEntidadEnParcela: false,



  /**
   * 2.1. Mapas
   */
  showMeteoBtn: false,        // Default: false; Mañez y Lozano: true
  showMapasSatelite: false,   // Default: false; Demo Fito y Agro: true
  showVentusky: false,        // Default: false; Demo Fito: true
  showMapaGraniot: false,     // Mostrar en mapa El mapa de Graniot
  showMapaLabels: true,      // Mostrar etiquetas encima de las parcelas del mapa. 
  /**
   * Listado de colores asociados a Parcelas con un Cultivo determinado (por defecto,
   * todos los mapas tienen un color verde claro).
   * 
   * @example customColorsList = {'MANGO': '#ff5733'}
   */
  customMapColorsList: {},

  // 3. Ventas
  showVentasLote: true, // Hernandorena: true
  showVentasComercio: true,
  showVentasComercioProveedor: false,

  // 4. Aplicadores
  filterAplicadoresByClient: false, // Prosanzcu: true
  showAplicadoresActivo: true, // Buitech, pero aplica a todos
  showTrabajadoresFirma: false, // Esp. Granada: true
  showTrabajadoresUserYPass: false, // Innovia: true
  showTrabajadoresCuadrillas: false, // Naturfrut: true
  showTrabajadoresNumSS: false, // Naturfrut: true
  showFichajesCargos: false, // Naturfrut: true
  showTrabajadoresMaquinaria: true, // Innovia: false
  showFichajesExportarCSV: false, // Naturfrut: true
  showFichajesUbicaciones: false, // Naturfrut: true
  showTrabajadoresClienteAsignado: false, // Innovia: true
  showMaquinariaMostrarTodos: false, // Máñez y Lozano: true
  showMaquinariaClienteAsignado: false,
  showMaquinariaPropietario: false,
  showMaquinariaAdjuntoPdf: false,
  precioHora: false,
  titleActivoMaqTra: 'Activo',

  // 5. Tareas > Fitosanitarios
  // Configuración del tractor
  showTractorMarcha: true,
  showTractorRPM: true,
  showTractorPresion: true,
  showTractorBoquillas: true,
  showTractorVelocidad: false, // Laplana: true
  showTractorKmRecorridos: false,
  showTipoPlaga: false, // Default: ???; Simat: true
  showMotivoFito: false,
  showTareasFitoObservaciones: true, // Armentia: false
  showTareasFincas: true, // Innovia: false
  showTareasSector: true, // Hernandorena: false
  showTareasFitoSectorMultiple: true,
  showTareasFitoHoras: false, // V. Giner: true
  showTareasParcela: true,
  showTareasMaquinaria: true, // Hernandorena: false
  showTareasMateriaActiva: false, // Hernandorena: true
  showTareasTratamientos: true, // Hernandorena: false
  tareasTratamientosLabel: 'Tratamiento(s)', // Hernandorena: 'Plagas'
  showDesplazamientoObra: false, // Default: false; Oliverfutur, Fitogest: true
  showAplicados: true, // Default: true; Agromainsa: false
  showAplicadoSwitch: true, // Default: true; Agromainsa: false
  showFertiNombreCuba: false, // Simat: true
  showTratamientosAllProductos: true, // |- Muestra también productos de tipo fertilizante -| Simat: true
  showFitoMomentoAplicacion: false, // P. Cabedo: true
  showFitoCosteTratamiento: false, // P. Cabedo: true
  showFitoTipoTratamiento: false, // P. Cabedo: true
  showFitoFechaReceta: true, // Hernandorena: false
  showFitoEficacia: true, // Hernandorena: false
  showFitoProductosNombres: false, // Hernandorena: true
  showFitoValidado: false,
  showTareasFitoLitrosAplicados: false, // VGiner: true
  showTareasFitoMotivoJustificacion: false,
  showTareasFitoIT: false,
  showTareasFitoLote: false,
  showTareasFitoMochila: false,
  showTareasFitoAplicacion: false,
  showTareasFitoPuntoKm: false,
  showCostesTareasFitoCsv: false,
  showCostesTareasFertiCsv: false,
  showCostesTareasOtrasCsv: false,
  showCostesProductosCsv: false,
  showCostesAll: true,

  //5. Tareas->Genericas
  betweenDatesGenericas: false,
  showTareasGenericasObservaciones: false,

  // 5. Tareas > Fertilizantes
  showAbonado: false, // Default: false; Agromainsa, Fitogest: true
  showTareasFertiHoras: false,
  showTareasFertiSectorMultiple: true,

  // 5. Tareas > Ecorregimes
  showEcorregimenes: false,

  // 6. Productos
  showProductosMostrarTodos: false, // Máñez y Lozano: true

  // 6. Productos > Fitosanitarios
  showProductosNumReg: true, // MyL: false
  showNPK: true, // Default: true; Prosanzcu: false
  showProductosFitoDosis: false,

  // 6. Productos > Fertilizantes
  showDescripcion: true, // Default: true; Prosanzcu: false
  showFertisComposicion: true,
  showFertisComposicionCol: true,
  showFertisOtrosCompuestosCol: true,
  showPrecio: true, // Default: true; Prosanzcu: false
  showFertilizantesNPK: true,
  showFertilizantesCa: false, // Folqués, Vginer: true
  showFertilizantesS: false,
  showFertilizantesMg: false,
  showFertilizantesSubtipo: false, // Savia Tropical: true
  fertilizantesNombreRequired: false, // MyL: true
  fertilizantesUdsMedidaRequired: false, // MyL: true
  showFertilizantesAlbaranFactura: true,
  showProductosFeritilizantes: true,

  // 6. Productos > Ganadería
  showGanaderia: false,

  // 6. Productos > Vademecum
  showExepcionales: true, // Agrogest: false
  showOtrasDenominaciones: true, // Agrogest: false
  showInfoCambios: true, // Buitech: false
  showVademecumMenuBotones: true, // Citrusvol: false
  /** Vademecum: Muestra u oculta el botón para ver el histórico de fichas vigentes. */
  showHistoricoFT: true,
  /**
   * Vademecum: Añade un botón al menú del usuario.
   * 
   * Permite al usuario administrador crear un listado de productos y exportarlos en formato CSV.
   */
  showVademecumListadoProductos: false, // DQVademecum: true
  showOrdenAplicacionProductos: false, // Fitosgest true

  // 7. Tratamientos
  showTratamientosUnidades: false, // Ebro: true
  showTratamientosActivo: true, // Buitech, pero aplica a todos
  showTratamientosRecetaSwitch: false,
  mostrarTodosTratamientos: false,
  showBtnTratamiento: false,


  // 8. Visitas
  showVisitasConsultasObservaciones: false, // Ebro: true
  showVisitasHora: true, // Espárragos de Granada: false
  hasVisitasHoraInicioFin: false, // Espárragos de Granada: true
  showVisitasUploadPhotos: true, // Savia Tropical: true
  showVisitasPascualCabedo: false, // P. Cabedo: true
  showVisitasPlagas: true, // Previlsa: false
  showVisitasARealizar: true, // Espárragos de Granada: false
  showMoviendo: true, // Espárragos de Granada: false
  showCosecha: true, // Espárragos de Granada: false
  showNumTallos: false, // Espárragos de Granada: true
  showVisitasMostrarTodas: false, // Agromainsa
  showVisitasContadorAgua: false,
  showVisitasFisiopatias: false,
  showVisitasCultivos: false,
  showVisitasVariedades: true,
  showVisitasParcelas: false,
  showVisitasTrasiento10Plagas: false,


  // X. Stock
  showStockProveedor: false,
  showStockReto: false,
  showStockMotivo: false,
  showStockUbicacion: false,
  showStockProveedorClienteDropdown: false,
  showStockCifProveedor: true,
  persisteIdComercio: false,
  hasStockParcelas: false, //CTL: true
  showStockUbicacionDestino: true, //CTL: false
  showStockProductsColsDistribution: 4, // General
  showStockSemillasColsDistribution: 4,
  showStockAdditionalFields: false,
  // X. Usuarios
  showUsuariosFirma: false, // Esp. Granada: true

  // XI. Reto

  showReto: false,

  // XIII. Recogidas
  showRecogidas: false,

  /**
   * CAMBIAR DE VISTA
   * 
   * Para funciones compartidas por varios clientes, pero que se muestran diferentes 
   * según el cliente.
   * 
   */

  // 1. Clientes
  canAddClients: true, // Ebro, SDI: false
  canEditClients: true, // SDI: false
  canDeleteClients: true, // Ebro, SDI: false
  showMultiselectAplicadores: false,
  // 2. Fincas
  cuadernoOnlyByYear: false,
  canAddFincas: true,
  canEditFincas: true,
  canDeleteFincas: true,
  canAddSectores: true,
  canEditSectores: true,
  canDeleteSectores: true,
  canAddParcelas: true,
  canEditParcelas: true,
  canDeleteParcelas: true,
  canDuplicateFinca: false,

  // 4. Aplicadores
  showAplicadoresMenu: true, // Máñez y Lozano: false
  showMaquinariaMenu: false, // Máñez y Lozano: true
  showMaquinariaGps: false, // Máñez y Lozano: true
  canEditTrabajadores: true, // Naturfrut: false
  showFotoTrajador: false,
  showConsultaRopo: false,

  // 5. Tareas > Fitosanitarios
  showPrescripcionTratamiento: false, // Fitogest, Oliverfutur
  showRecetaTratamiento: false, // Agromainsa: true
  showFichaTratamiento: false, // Simat: true
  showRegistroTratamiento: false, // La Fast: true
  showRegistroTratamientoShort: false, // La Fast: true
  showRegistroHernandorena: false, // Hernandorena: true
  showRecetaPascualCabedo: true, // Pascual Cabedo: true
  showRecetaFertiPascualCabedo: false, // Pascual Cabedo: true
  showRecetaBuitech: false, // Buitech: true
  showRecetaAgrogest: false, // Agrogest: true
  showRecetaFertiAgrogest: false, // Agrogest: true
  showRecetaLaplana: false, // Laplana: true
  showRecetaFirmas: false, // Espárragos de Granada: true
  showRecetaVGiner: false, // V. Giner: true
  showRecetaGlobalGAP: false, // V. Giner: true
  tareasFitoFilterBySector: true, // Hernandorena: false
  showPhCaldo: false,
  showTareasFitoSoleado: false, // Espárragos de Granada: true
  showTareasFitoCarretera: false, // Innovia: true
  showTareasFitoPuntoKM: false, // Innovia: true
  showTareasFitoDuracion: false, // Innovia: true
  showFitoLocales: false,
  hiddeDosisAplicada: false,
  showTareasProcura: false,
  showMomentoAplicacion: false,
  showTareasFitosanitariasCampaign: false, // Armentia añadir balances

  // 5. Tareas > Fertilizantes y Abonado
  showFertilizantesFormShort: true,
  showFertilizantesSectores: true, // Default: true; Prosanzcu: false
  showFertilizantesParcelas: true,
  showKilosHectarea: false, // Default: false; Prosanzcu: true
  showTareasFertiDias: true, // Pascual Cabedo: false
  showTareasFertiObservaciones: false, // Pascual Cabedo: true
  showTareasFertiConsumoAgua: false, // Espárragos de Granada: true
  showTareasFertiGramos: true,
  showRecetaAgrogestFerti: false,
  showControlSanitario: false,
  showTareasFertilizantesCampaign: false, // Armentia añadir balances
  showUnidadesFertilizantesByParcela: true,

  // 5. Tareas > Otras
  showRecetaAgrogestOtras: false,
  showTareasOtrasProducto: false,
  showTareasOtrasCantidad: false,
  showTareasOtrasCampaign: false,

  // 5. Tareas > Bio
  showTareasBioExportCsv: true,
  showTareasBioCampaign: false,


  // 5. Tareas > Siembra
  showTareasSiembra: false,
  showTareasSiembraProductos: false, // Prosanzcu
  showTareasSiembraMaquinarias: false, // Cartagena Fresh
  showTareasSiembraCampaign: false,

  // Tareas Agroambiental
  showTareasAgroambiental: false,
  showTareasAgroambientalCampaign: false,

  //Tareas Varias
  timeStartStopTareas: true,
  tareasHorasTotales: true,
  canAddTareas: true,
  canDuplicateTareas: true,

  // 6. Productos
  canAddProductos: true, // Ebro, SDI: false
  canEditProductos: true, // Ebro, SDI: false
  canDeleteProductos: true, // Ebro, SDI: false

  // 7. Tratamientos
  canAddTratamientos: true,
  canDuplicateTratamientos: true,
  showTratamientosMaxProductos: false,

  // 8. Visitas
  plagasInput: false, // Default: false; Agromainsa: true
  showMotivo: false,
  showContadorLectura: false,

  /**
   * GENERAL
   * 
   * Para variables globales que se muestran en toda la aplicación.
   * 
   */

  /** Nombre, en singular, que se usará para denominar a los Clientes. */
  clienteName: 'Cliente',
  /** Nombre, en plural, que se usará para denominar a los Clientes. */
  clientesName: 'Clientes',

  /** Nombre, en singular, que se usará para denominar a las Fincas. */
  fincaName: 'Finca', // Hernandorena: 'Invernadero(s)' , Gardengest: 'Parque(s)', Clientes de VIVEROSGEST y AGROGEST: Viveros'
  /** Nombre, en plural, que se usará para denominar a las Fincas. */
  fincasName: 'Fincas',

  /** Nombre, en singular, que se usará para denominar a las Sectores. */
  sectorName: 'Sector', // La Fast: 'Parcela(s)'
  /** Nombre, en plural, que se usará para denominar a las Sectores. */
  sectoresName: 'Sectores',
  textoSuperficie: 'Ha',

  /** Nombre, en singular, que se usará para denominar a las Parcelas. */
  parcelaName: 'Parcela', // Agromainsa: 'Variedade(s)'; La Fast: 'Huerta(s)'; Gardengest: 'Jardines(s)'
  /** Nombre, en plural, que se usará para denominar a las Parcelas. */
  parcelasName: 'Parcelas',

  // Activa las peticiones hacia la base de datos del SIEX para la recogida de cultivos, variedades del modulo finca parcerlas y mapas
  activateSIEX: true,

  /** Nombre, en singular, que se usará para denominar a los Trabajadores. */
  aplicadorName: 'Trabajador', // Agromainsa: 'Profesional'
  /** Nombre, en plural, que se usará para denominar a los Trabajadores. */
  aplicadoresName: 'Trabajadores', // Agromainsa: 'Profesionales'

  /** Nombre, en singular, que se usará para denominar a las Ventas. */
  ventaName: 'Venta',
  /** Nombre, en plural, que se usará para denominar a las Ventas. */
  ventasName: 'Ventas',

  /** Nombre de la empresa que figura en algunos modelos de receta fitosanitaria. */
  empresaName: '', // Default: 'Locatec Aplicaciones Informáticas'; Agromainsa: 'A G R O M A I N S A, S.L.'
  empresaCIF: '',
  empresaCCC: '',
  empresaLocation: '',
  cunyoUrl: '',

  /** 
   * Lista que contiene las URLs de acceso a la aplicación prohibidas.
   * 
   * Si se accede a la aplicación desde cualquiera de estas URLs, se redirigirá a la dirección
   * especificada en `urlRedirect`. Si ésta no está definida, `urlBlackList` será ignorado.
   */
  urlBlackList: [''],
  /** Dirección a la que redirigir cuando la aplicación sea accedida por una URL prohibida. */
  urlRedirect: '',


  /*************** AGROGEST ***************/

  // 4. Trabajadores
  showAplicadoresFichajes: false,
  allowFichajesRandomizer: false, // Naturfrut: true
  showAplicadoresPartes: false,
  showTrabajadoresCargos: false,
  showFormatHourMinute: false, // Fichajes y partes con formato horario

  // 5. Tareas
  showTareasOtros: false,
  showTareasOtrosMultiple: true,
  showTareasOtrosDuracion: false,
  showTareasOtrosImagen: false,
  showTareasOrdenTrabajo: false,
  // 5.2 Tareas/Ferti
  showOnlyFertiTotalHours: false,

  // 9. Costes
  userCanSeeCostes: true,
  showCostes: false,
  showCostesFitosanitarios: false,
  showCostesFertilizantes: false,
  showCostesOtros: true,
  showCostesProductos: false,
  // show MULTISELECT tareas fito
  showTareasFitoMaquinarias: true,

  // X. Stock
  showStock: false, // Brio, Agrogest, Hernandorena?: true
  showStockage: false, // Vista personalizada de Vicente Giner
  showStockEntradaProducto2: true,
  showStockEntradaProducto3: true,
  /**
   * Al activar esta variable se debe desactivar la variable 'showStockTipoDeDato' 
   * esto evitara que aparezcan casillas que no se pueden rellenar
   * Si fuese el caso de tener activo las dos variables, la llamada 'showStockTipoDeDato' sera la que tomara mas importancia.
   */
  showStockMarcaComercial: false,
  showStockEnvase: false,
  /**
   * Tipo de unidad del Stock. En la app por defecto, esto serían Productos (fitosanitarios y fertilizantes).
   * Si `showStockTipo = true`, se muestra un desplegable con las opciones `'Fitosanitario', 'Fertilizante', 'Semilla'`
   * en el formulario.
   * 
   * No confundir con la propiedad `tipo` (Entrada/Salida).
   */
  showStockTipoDeDato: false,
  showStockSemilla: false,


  /*************** INTEGRACIÓN CON SDI ***************/
  sdiModuleActive: false, // FitogestA3SDI
  showSdiClientes: false, // FitogestA3SDI
  showSdiProductos: false, // FitogestA3SDI

  /******* CALCULO ROPO,REGEPA,ROMA *****************/
  showRopoRomaRegepa: false,
  showCalculoRopoByDni: false,
  showCalculoRegepaByDni: false,
  showCalculoRomaByDni: false

};
