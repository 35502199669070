import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileUploadAnswer } from '../../../utils';
import { catchError, map, of } from 'rxjs';
import { Image } from './interface/image.interface';


@Component({
    selector: 'app-reactive-upload-images',
    standalone: false,
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.scss']
})
export class ReactiveUploadImagesComponent<T> implements OnChanges {

    @Input() formModel = {} as T;
    @Input() formType = 0;
    @Input() imageName: string | null = null;
    @Input() imageFolder = '';

    @Output() sendImageName: EventEmitter<string> = new EventEmitter<string>();

    public imageModel: Image = { imagen: '', image_after: ''};

    constructor(private http: HttpClient) {}

    /**
     * Display the image icon on the form if one exists
     * @param changes Images currently uploaded to the form
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formModel'] && changes['formModel'].currentValue) {
            this.imageModel = changes['formModel'].currentValue as Image;
        }// if();
    }// ();

    /**
     * Uploads an image to the server
     * @param file File submitted by the user
     */
    public uploadFile(file: File) {
        if (!file) {
            return;
        }// if();

        const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? '';
        const supportedFileExtensions = new Set(['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff']);
    
        if (!supportedFileExtensions.has(fileExtension)) {
            alert('Formato de imagen no válido.');
            return;
        }// if();
    
        const formData = new FormData();
        formData.append('uploadFile', file, file.name);
        formData.append('folder', this.imageFolder);
    
        this.http.post<FileUploadAnswer>(
            `${environment.serverUrl}ws/upload/upload_file_generic.php`, 
            formData, 
            { headers: new HttpHeaders({ 'Accept': 'application/json' }) }
        )
            .pipe(
                map(data => this.sendImageName.emit(data.fileName)),
                catchError(error => {
                    console.error('Error al subir la imagen:', error);
                    return of(null);
                })
            )
            .subscribe();
    }// ();

    /**
     * Displays uploaded image in a new window
     */
    public viewImage() {
        /* 
            According to GPT, setting the URL after opening a blank window leads to fewer security issues than doing it on a single line.
            The alternative is to use the noreferrer, noopener window features, but that requires some manual configuration by the user
            which Salva probably wouldn't like.
        */
        const myWindow = window.open('', '_blank', 'width=600,height=600');

        if (myWindow) {
            myWindow.location.href = `${environment.serverUrl}mto/${this.imageFolder}/${this.imageModel[this.imageName ?? '']}`;
            myWindow.focus();
        } else {
            alert('El navegador ha bloqueado la ventana emergente. Permite pop-ups para este sitio.');
        }// if();
    }// ();
}// class;