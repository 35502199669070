import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormCustomValidators {   
   
    constructor() {}

    /**
     * 
     * @returns Time Validator
     */
    get timeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
    
            if (!value) {
                return null;
            }// if();
    
            const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!timePattern.test(value) || value.startsWith('-')) {
                return { invalidTime: 'no es un tiempo válido (HH:mm) o es negativo.' };
            }// if();
    
            return null;
        };
    }// ();
}// class;