import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SpeechRecognition } from './speech-recognition.interface';

@Injectable({
    providedIn: 'root',
})
export class SpeechRecognitionService {
    public recognition: SpeechRecognition | null = null;
    public speechTranscripted = '';
    public transcriptionError = '';
    public transcriptionInfo = '';
    public transcriptedSpeechObserver = new Subject<string>();

    private recognizedSpeech = '';

    /**
     * Checks the browser's compatibility with the service.
     */
    constructor() {
        const speechRecognitionConstructor =
        (window.SpeechRecognition || window.webkitSpeechRecognition) as
          | (new () => SpeechRecognition)
          | undefined;
  
        if (speechRecognitionConstructor) {
            this.recognition = new speechRecognitionConstructor();
        } else {
            this.transcriptionError = 'El reconocimiento de voz es solo compatible con Google Chrome';
        }// if();
    }// constructor();
  
    /**
     * Initializes the speech recognition service.
     * @returns void
     */
    public init() {
        if (!this.recognition) {
            console.error('El reconocimiento de voz no está inicializado.');
            return;
        }
  
        this.recognition.interimResults = false;
        this.recognition.lang = 'es-ES';
          
        this.setEventHandlers();
    }// ();
  
    /**
     * Concatenates the recognized speech with the full transcription.
     */
    public wordConcat() {
        this.speechTranscripted = this.speechTranscripted + ' ' + this.recognizedSpeech + '.';
        this.recognizedSpeech = '';
    }// ();


    /**
     * Handles all possible events triggered during speech recognition, 
     * to make them transparent to the user.
     * @returns void
     */
    private setEventHandlers() {
        if (!this.recognition) {
            console.error('El reconocimiento de voz no está inicializado.');
            return;
        }// if();

        this.recognition.onstart = () => {
            this.transcriptionInfo = 'Reconocimiento de voz inicializado.';
        };
        
        this.recognition.onaudiostart = () => {
            this.transcriptionInfo = 'Captura de audio inicializada.';
        };
        
        this.recognition.onspeechstart = () => {
            this.transcriptionInfo = 'Transcribiendo...';
        };
        
        this.recognition.onspeechend = () => {
            this.transcriptionInfo = 'Transcripción finalizada.';
        };
        
        this.recognition.onerror = (event) => {
            this.transcriptionError = 'Error durante la transcripcion: ' + event.error;
        };
                
        this.recognition.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map((result) => (result as SpeechRecognitionResult)[0]?.transcript)
                .join('');

            this.recognizedSpeech = transcript;
            this.transcriptedSpeechObserver.next(transcript);
        };
        
        this.recognition.onend = () => {
            this.transcriptionInfo = '';
            this.wordConcat();
        };
    }// ();
}// class;

declare global {
  interface Window {
    SpeechRecognition?: new () => SpeechRecognition;
    webkitSpeechRecognition?: new () => SpeechRecognition;
  }
}