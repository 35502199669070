import { Component } from '@angular/core';
import { DashboardService } from '../../../service/events/dashboard.service';
import { RequestHandler } from '../../../service/OffService/request-handler';
import { Utils } from '../../../common/utils';
import { RequestButtonTypes } from '../../../common/components/request-button/request-button.component';
import { InputType } from '../../../common/components/form-builder/form-builder.component';
import { BaseView } from '../../base-view';

import { ConsultasApiService } from 'src/app/service/api/consultas-api.service';

import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { LogModel } from 'src/app/models/log.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-consultas-logs',
    standalone: false,
    templateUrl: './consultas-logs.component.html',
    styleUrls: ['./consultas-logs.component.scss']
})
export class ConsultasLogsComponent extends BaseView<LogModel> {

    public getRequest: RequestHandler<LogModel[]> = this.consultasApi.logs.GET;
    public deleteRequest: RequestHandler<LogModel> = this.consultasApi.logs.DELETE;
    public consultas: RequestHandler<LogModel[]> = this.consultasApi.logs.GET;

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: LogModel = {usuario: ''};


    public formFields: ItemInterface<object>[] = [
        { 
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'hora', 
            label: 'Hora', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'usuario', 
            label: 'Usuario', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'ip_origen', 
            label: 'IP Origen', 
            inputType: {type: InputType.TEXT}
        },
        { 
            field: 'user_agent', 
            label: 'Navegador', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora', header: 'Hora', filter: ''},
        {field: 'usuario', header: 'Usuario', filter: ''},
        {field: 'ip_origen', header: 'IP', filter: ''},
        {field: 'user_agent', header: 'Navegador', filter: ''},
    ];

    constructor(dashboard: DashboardService,
        private consultasApi: ConsultasApiService) {
        super(dashboard, consultasApi.oscae.GET, undefined);

    }
  
    public globalVisualTransform(value: string){
        return value;
    }

    public globalConditionalStyle(value: string, col: { field: string; }): ColStylesInterface {

        if (Utils.isNumber(value) && col.field === 'rgseaa' ) {
            return {
                textAlign: 'right',
                paddingRight: '70px',
            };
        }
      
        else{
            return {textAlign: 'center'};
        }
    }

    edit(){

    }

    public override show(modal: string, data: LogModel) {
    
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {usuario: ''};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }
}
