import { Component } from '@angular/core';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { BaseView } from '../../../../view/base-view';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { environment } from '../../../../../environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { CRMApiService } from '../../../../service/api/crm.service';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { CrmModel } from 'src/app/models/crm/crm.model';

@Component({
    selector: 'app-crm-consultas',
    standalone: false,
    templateUrl: './crm-consultas.component.html',
    styleUrls: ['./crm-consultas.component.scss']
})
export class CRMConsultasComponent extends BaseView<CrmModel> implements OnInit {

    public showVisitasConsultasObservaciones = environment.features.showVisitasConsultasObservaciones;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showVisitasHora = environment.features.showVisitasHora;
    public hasVisitasHoraInicioFin = environment.features.hasVisitasHoraInicioFin;
    public clienteName = environment.features.clienteName;
    public showMotivo = environment.features.showMotivo;

    public getRequest: RequestHandler<CrmModel[]> = this.cmrApi.CRM.GET;
    public menuItemId = 'crm-consultas';

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Cliente',
            field: 'id_cliente',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Fecha Acción Comercial',
            field: 'fecha',
            inputType: {type: InputType.TEXT},
        
        },
        {
            label: 'Tarea comercial',
            field: 'tarea_comercial',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Interlocutor',
            field: 'interlocutor',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Gerente',
            field: 'gerente',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Responsable de Calidad',
            field: 'responsable_calidad',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Estado presupuesto',
            field: 'estado_presupuesto',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Realizado',
            field: 'realizado',
            inputType: {type: InputType.RO_SWITCH},
        },
        {
            label: 'Fecha Próxima Acción Comercial',
            field: 'fecha_proxima_accion_comercial',
            inputType: {type: InputType.TEXT},
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {type: InputType.TEXTAREA}
        },
        {
            label: 'Productos',
            field: 'productos',
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Venta Cruzada',
            field: 'venta_cruzada',
            inputType: {type: InputType.TEXT}
        }
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha'},
        {field: 'cliente_nombre', header: 'Cliente'},
        {field: 'tarea_comercial', header: 'Tarea Comercial'},
        {field: 'gerente', header: 'Gerente'},
        {field: 'responsable_calidad', header: 'Responsable de Calidad'},
        {field: 'estado_presupuesto', header: 'Estado Presupuesto'},
        {field: 'fecha_proxima_accion_comercial', header: 'Fecha Próxima Accion Comercial'},
        {field: 'productos', header: 'Productos'},
        {field: 'venta_cruzada', header: 'Venta Cruzada'},
        {field: 'realizado', header: 'Realizado'},
        {field: 'observaciones', header: 'Observaciones'}
    ];

    constructor(private dashboard: DashboardService, private cmrApi: CRMApiService) {
        super(dashboard, cmrApi.CRM.GET);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
    }

    public globalConditionalStyle(_value: string, cell: ColInterface) {
        if (cell.header === 'Fecha'
        || cell.header === 'Fecha Próxima Acción Comercial'
        || cell.header === 'Cliente'
        || cell.header === 'Realizado') {

            return {
                textAlign: 'center'
            };
        }
        else {
            return {
                marginLeft: '40px;'
            };
        }
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (col.field === 'realizado') {
            return (value === '1') ? 'Sí' : 'No';
        } else {
            return value;
        }
    }
}
