import { Injectable } from "@angular/core";
import { SyncService } from "../OffService/sync.service";
import {
  ApiRequestService,
  HttpRequestType,
} from "../OffService/api-request.service";
import { RequestHandler } from "../OffService/request-handler";
import { AlbaranModel } from "src/app/models/ventas/albaran.model";

@Injectable({
  providedIn: "root",
})
export class AlbaranApiService {
  public albaranesGET = new RequestHandler<AlbaranModel[]>();
  public albaranesPUT = new RequestHandler<AlbaranModel[]>();
  public albaranesPOST = new RequestHandler<AlbaranModel[]>();
  public albaranesDELETE = new RequestHandler<AlbaranModel[]>();
  public enviarRecetaCaptura = new RequestHandler<AlbaranModel>();
  constructor(
    private syncServiceReceta: SyncService<AlbaranModel>,
    private syncServiceAlbaranes: SyncService<AlbaranModel[]>,
    private apiReceta: ApiRequestService<AlbaranModel>,
    private apiAlbaran: ApiRequestService<AlbaranModel[]>
  ) {
    this.defineAlbaranesGET();
    this.defineAlbaranesPOST();
    this.defineAlbaranesPUT();
    this.defineAlbaranesDELETE();
    this.defineEnviarRecetaCaptura();
  }

  private defineAlbaranesGET() {
    this.albaranesGET
      .fetch(HttpRequestType.GET, 'albaran/albaran');

    this.apiAlbaran
      .registerRequest(this.albaranesGET);

    this.syncServiceAlbaranes
      .register(this.syncServiceAlbaranes.baseSyncPolicy(this.albaranesGET));

  }

  private defineAlbaranesPOST() {
    this.albaranesPOST
      .fetch(HttpRequestType.POST, 'albaran/albaran');

    this.apiAlbaran
      .registerRequest(this.albaranesPOST);

    this.syncServiceAlbaranes
      .register(this.syncServiceAlbaranes.baseSyncPolicy(this.albaranesPOST));

  }

  private defineAlbaranesPUT() {
    this.albaranesPUT
      .fetch(HttpRequestType.PUT, 'albaran/albaran');

    this.apiAlbaran
      .registerRequest(this.albaranesPUT);

    this.syncServiceAlbaranes
      .register(this.syncServiceAlbaranes.baseSyncPolicy(this.albaranesPUT));

  }

  private defineAlbaranesDELETE() {
    this.albaranesDELETE
      .fetch(HttpRequestType.DELETE, 'albaran/albaran');

    this.apiAlbaran
      .registerRequest(this.albaranesDELETE);

    this.syncServiceAlbaranes
      .register(this.syncServiceAlbaranes.baseSyncPolicy(this.albaranesDELETE));

  }

  private defineEnviarRecetaCaptura() {
    this.enviarRecetaCaptura.fetch(HttpRequestType.POST, 'albaran/enviar_receta_captura');
    this.apiReceta.registerRequest(this.enviarRecetaCaptura);
    this.syncServiceReceta.register(this.syncServiceReceta.baseSyncPolicy(this.enviarRecetaCaptura));
  }
}
