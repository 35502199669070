import { Filtering } from '../../../../service/filtering/filtering';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { TratamientosFormInitData } from './form-control/form-initdata';
import { TratamientosFormDefinition } from './form-control/form-definition';
import { AppFormRequest } from '../../../app-common/form-request/app-form-request';
import { BaseForm } from '../../../base-form';
import { TratamientosApiService } from '../../../../service/api/tratamientos-api.service';
import { FormRequestTypes } from '../../../../common/classes/form-request';
import { ProductosApiService } from '../../../../service/api/productos-api';
import { ClientesApiService } from '../../../../service/api/clientes-api.service';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { StorageManager } from '../../../../common/storage-manager.class';
import { environment } from '../../../../../environments/environment';
import { list } from '../../../../common/classes/request-builder';
import { RequestButtonComponent } from '../../../../common/components/request-button/request-button.component';

import type { OnInit } from '@angular/core';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ProductRowInterface } from './form-control/product-row.interface';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { Uso } from 'src/app/models/productos/uso.model';

@Component({
    selector: 'app-formulario-tratamientos',
    standalone: false,
    templateUrl: './tratamientos-from.component.html',
    styleUrls: ['./tratamientos-from.component.scss']
})
export class TratamientosFromComponent extends BaseForm<TratamientosModel> implements OnInit {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TratamientosModel>();

    public rol = StorageManager.getUser().rol;

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public enableLegalMode = environment.features.enableLegalMode;
    public showUdsCuaderno = environment.features.showUdsCuaderno;
    public showTratamientosAllProductos = environment.features.showTratamientosAllProductos;
    public showTratamientosUnidades = environment.features.showTratamientosUnidades;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (this.rol === 'admin');
    public clienteName = environment.features.clienteName;
    public showTratamientosRecetaSwitch = environment.features.showTratamientosRecetaSwitch;

    public isPopup = (window.name === '_blank, popup');

    public override model: TratamientosModel = {
        nombre: '',
        producto: {nombre: '', materia_activa: ''},
        cantidad: '',
        fecha_act: '',
        finca: '',
        activo: true
    };

    public formRequest = new AppFormRequest<TratamientosModel>();
    public form: TratamientosFormDefinition = new TratamientosFormDefinition();
    public data: TratamientosFormInitData = new TratamientosFormInitData();
    public agentesAll: TypeAheadInterface<Uso> = list();
    public hiddeDosisAplicada = true;
    public hiddeDosisCuaderno = true;
    
    constructor(public dashboardEvents: DashboardService,
        public formApi: FormCommonApiService,
        public productosApi: ProductosApiService,
        public tratamientosApi: TratamientosApiService,
        public clientesApi: ClientesApiService,
        public override router: Router,
        public override route: ActivatedRoute) {
        super(
            route,
            router,
            dashboardEvents,
            tratamientosApi.tratamientosPUT,
            tratamientosApi.tratamientosPOST,
            'tratamientos',
            'Actualizar Tratamiento',
            'Crear Tratamiento',
            'Duplicar Tratamiento'
        );
    }

    public static createNavigation(router: Router) {
        router.navigate(['register']);
    }

    ngOnInit() {
        this.hiddeDosisAplicada = StorageManager.checkHiddenDosisAplicada();
        this.hiddeDosisCuaderno = StorageManager.checkHiddenDosisCuaderno();
        this.getDistinctAgentes();
        this.expandFormFields();

        const fieldsToSend = [
            'id',
            'id_usuario',
            'nombre',
            'estado',
            'ids_productos',
            'uds_medida',
            'ids_usos',
            'nombres_usos',
            'ids_agentes',
            'nombres_agentes',
            'cantidades_cuaderno',
            'cantidades_real',
            'activo'
        ];

        if (this.showUdsCuaderno) {
            fieldsToSend.push('uds_medida_cuaderno');
        }

        if (this.hasClients && this.userCanSeeClientes) {
            fieldsToSend.push('id_cliente');
        }

        if (this.showTratamientosRecetaSwitch) {
            fieldsToSend.push('ocultar_en_receta');
        }

        this.formRequest
            .isGeneric(true)
            .setRegisterId(this.getRegisterId())
            .setType(this.getType())
            .setFormFields(this.form.formFields)
            .setModel(this.model)
            .setPutRequest(this.tratamientosApi.tratamientosPUT)
            .setPostRequest(this.tratamientosApi.tratamientosPOST)
            .setGetRequest(this.tratamientosApi.tratamientosGET)
            .setFieldsToSend(fieldsToSend);

        this.expandFormRequest();

        this.formRequest.load();
        this.softInit(this.getType());

    }

    public submit() {
        let isValid = true;

        delete this.model.cantidad;
        delete this.model.finca;
        
        this.form.productRows.forEach(row => {
            if (!row.uds_medida && row.agentes.selected) {
                row.uds_medida = row.agentes.selected.unidad_medida ?? '';
            }

            if (row.productos.selected && !row.uds_medida) {
                isValid = false;
            }
        });

        if (!isValid) {
            this.requestButton.error = 'Hay campos obligatorios';
            setTimeout(() => {
                this.requestButton.error = null;
            }, 2000);
        } else {
            this.formRequest.beforeSend(resolve => {
                const clientId = 
                    StorageManager.getUser().tipo === 'comunero' ? 
                        (StorageManager.getUser() || {}).id_cliente : 
                        (StorageManager.getClient() || {}).id;
                const clientIdFromForm = this.model.id_cliente;

                this.model.id_cliente =
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

                if (this.applicationType === 'cropgest' && this.model.id_cliente === '') {
                    delete this.model.id_cliente;
                }

                const userId = (StorageManager.getUser() as UserModel).id;
                const userIdFromForm = this.model.id_usuario;
                
                this.model.id_usuario =
                userIdFromForm ? userIdFromForm :
                    userId ? userId : '';
                

                this.model.ids_productos = this.form.productRows
                    .filter(it => it.productos.selected)
                    .map(it => (it.productos.selected as ProductosModel).id)
                    .join(';');

                this.model.uds_medida = this.form.productRows
                    .filter(it => it.uds_medida)
                    .map(it => it.uds_medida)
                    .join(';');
                

                if (this.showUdsCuaderno) {
                    this.model.uds_medida_cuaderno = this.form.productRows
                        .filter(it => it.uds_medida_cuaderno)
                        .map(it => it.uds_medida_cuaderno)
                        .join(';');
                }

                this.model.ids_usos = this.form.productRows
                    .filter(it => it.usos.selected)
                    .map(it => it.usos.selected?.id || '')
                    .join(';');

                this.model.ids_agentes = this.form.productRows
                    .filter(it => it.agentes.selected)
                    .map(it => it.agentes.selected?.id || '')
                    .join(';');

                this.model.nombres_usos = this.form.productRows
                    .filter(it => it.usos.selected)
                    .map(it => it.usos.selected?.uso || '')
                    .join(';');

                this.model.nombres_agentes = this.form.productRows
                    .filter(it => it.agentes.selected)
                    .map(it => it.agentes.selected?.agente || '')
                    .join(';');

                if (this.model.uds_medida) {
                    this.model.uds_medida = this.model.uds_medida.replace('undefined', '');
                }

                this.model.cantidades_cuaderno = this.form.values.cantidadCuaderno.join(';');
                this.model.cantidades_real = this.form.values.cantidadReal.join(';');


                if (this.showTratamientosRecetaSwitch) {
                    this.model.ocultar_en_receta = this.form.productRows
                        .map(it => it.ocultar_en_receta || false)
                        .join(';');
                }

                resolve(true);
            });

            this.formRequest.afterSend(resolve => {
                if (this.formRequest.type === FormRequestTypes.CREATE) {
                    this.form.productRows.forEach(item => {
                        item.agentes.selected = null;
                        item.productos.selected = null;
                        item.usos.selected = null;
                    });

                    this.form.values.cantidadReal = [];
                    this.form.values.cantidadCuaderno = [];
                }
                if (this.isPopup) {
                    window.close();
                }
                resolve(true);
            });

            this.formRequest.send();
        }

    }

    public applyFilters(from?: string, concrete?: number) {
        if (from === 'productos' && concrete !== undefined) {  
            (this.form.productRows[concrete] as ProductRowInterface).usos.selected = null;
            (this.form.productRows[concrete] as ProductRowInterface).agentes.selected = null;
            (this.form.productRows[concrete] as ProductRowInterface).usosFilter.filter();
            if (
                this.showTratamientosAllProductos &&
                (this.form.productRows[concrete] as ProductRowInterface).productos.selected &&
                (((this.form.productRows[concrete] as ProductRowInterface).productos.selected as ProductosModel).tipo === 'fertilizante')
            ) {
                if ((this.form.productRows[concrete] as ProductRowInterface).agentes.filtered[0]?.label !== '-') {
                    (this.form.productRows[concrete] as ProductRowInterface).agentes = this.agentesAll;
                    (this.form.productRows[concrete] as ProductRowInterface).agentesFilter.filtersToApply = [];
                }
            }
        } else if ((from === 'usos') && (concrete !== undefined) && this.form.productRows[concrete]) {
            (this.form.productRows[concrete] as ProductRowInterface).agentes.selected = null;
            (this.form.productRows[concrete] as ProductRowInterface).agentesFilter.filter();
        }

        if ((concrete !== undefined) && this.form.values.cantidadCuaderno[concrete]) {
            this.checkDosis(concrete);
        }
    }

    public checkDosis(index: number) {
        if (this.form.productRows[index]) {
            const uso = this.form.productRows[index]?.usos.selected;
            const agente = this.form.productRows[index]?.agentes.selected;

            if (uso && agente) {
                if (agente.maxDosis) {
                    if (
                        parseFloat(this.form.values.cantidadCuaderno[index] ?? '') >= (agente?.minDosis ?? 0) &&
                        parseFloat(this.form.values.cantidadCuaderno[index] ?? '') <= (agente?.maxDosis ?? 0)) {
                        this.form.values.status[index] = 'valid';
                    } else {
                        this.form.values.status[index] = 'invalid';
                    }
                } else if (agente.minDosis) {
                    if (parseFloat(this.form.values.cantidadCuaderno[index] ?? '') >= (agente.minDosis ?? 0)) {
                        this.form.values.status[index] = 'valid';
                    } else {
                        this.form.values.status[index] = 'invalid';
                    }
                }
            } else {
                this.form.values.status[index] = 'unknown';
            }

        } else {
            this.form.values.status[index] = 'unknown';
        }

    }

    public addNormalFilters() {
        this.form.productRows.forEach((row) => {
            row.usosFilter.filtersToApply = [];
            row.agentesFilter.filtersToApply = [];

            row.usosFilter.addFilter(() => {
                if (row.productos.selected) {
                    if ((row.productos.selected as ProductosModel).num_registro) {

                        row.usos.filtered = [
                            { label: 'Cargando...', value: null }
                        ];

                        this.getMagramaDetalleProducto(row);
                    } else {
                        row.usos.filtered = [{ label: '-', value: null }];
                        row.usos.selected = {} as Uso;
                        row.agentes.filtered = [{ label: '-', value: null }];
                        row.agentes.selected = {} as Uso;
                    }
                } else {
                    row.usos.filtered = [{ label: 'Uso...', value: null }];
                }
            });
            row.agentesFilter.addFilter(() => {
                if (row.usos.selected) {
                    row.agentes.filtered = row.agentes.values
                        .filter(agente =>
                            (agente.value?.uso || '').trim().toLowerCase()
                            === (row.usos.selected?.uso || '').trim().toLowerCase()
                            && agente.value?.num_registro === (row.productos.selected as ProductosModel).num_registro
                        )
                        .filter(
                            Filtering.distinctBy.bind({ field: 'label' })
                        );

                    row.agentes.filtered.unshift({ label: 'Agente...', value: null });
                } else {
                    row.agentes.filtered = [{ label: 'Agente...', value: null }];
                }
            });
        });
    }

    public getDistinctAgentes() {
        this.formApi.mgAgentes.response(data => {
            const agenteslist = list();
            data.map((agente: Uso) => {
                agenteslist.filtered.push({ label: agente.agente, value: agente });
            });
            this.agentesAll = agenteslist;
        });
        this.formApi.mgAgentes.perform({ getall: true } as never);
    }

  
    getMesureValue(value: ProductRowInterface | undefined, field: string){
        if (value) {
            const unidad: string = (value?.productos?.selected as ProductosModel).unidades?.toLowerCase() === 'litros' ? 'litros' : 'kilos';
            
            if (field === 'uds_medida_cuaderno') {
                value.uds_medida_cuaderno = unidad;
            } else {
                value.uds_medida = unidad;
            }
            
            return unidad;
        }
        return '';
    }

    private expandFormFields() {
        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFields.push({
                field: 'id_cliente',
                label: this.clienteName,
                inputType: {type: InputType.MULTISELECT},
                values: this.form.clientes,
                valuePrimaryKey: 'id',
                multiSelect: true,
                canSelectAll: true,
                required: true
            });
        }
    }

    private expandFormRequest() {
        this.formRequest.beforeLoad(resolve => {
            this.data.start(
                this.form.clientes,
                this.form.productos,
                this.clientesApi,
                this.productosApi
            ).then(() => {
                this.sortAlphabetically(this.form.productos.filtered, 'nombre');
                this.form.productos.filtered.unshift({ label: 'Producto...', value: null });
                this.makeObjectCopy();
                this.addNormalFilters();
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en dataStart: ' + e);
            }
            );
        });

        // LOAD DATA: PRODUCTOS
        this.formRequest.afterLoad(resolve => {
            setTimeout(() => {
                for (const dosis of this.form.values['cantidadCuaderno']) {
                    this.checkDosis(this.form.values['cantidadCuaderno'].indexOf(dosis));
                }
            }, 500);

            if (this.formRequest.type !== FormRequestTypes.CREATE) {
                
                const productos = (this.model.ids_productos || '').split(';');
                const udsMedida = (this.model.uds_medida || '').split(';');
                const udsMedidaCuaderno = (this.model.uds_medida_cuaderno || '').split(';');

                let ocultarEnReceta: string[] = [];
                if (this.showTratamientosRecetaSwitch) {
                    ocultarEnReceta = (this.model.ocultar_en_receta || '').split(';');
                }

                this.form.values.cantidadReal = (this.model.cantidades_real || '').split(';');
                this.form.values.cantidadCuaderno = (this.model.cantidades_cuaderno || '').split(';');

                this.form.productRows.forEach((item, i) => {
                    item.productos.selected = ((item.productos.values
                        .find(it => (it.value || {} as ProductosModel).id === productos[i]))?.value) as ProductosModel;
                    item.uds_medida = udsMedida[i] ?? '';
                    item.uds_medida_cuaderno = udsMedidaCuaderno[i] ?? '';
                    if (this.showTratamientosRecetaSwitch) {
                        item.ocultar_en_receta = [1, '1', true, 'true'].includes(ocultarEnReceta[i] ?? '');
                    }
                });
                
                this.formRequest.update();

                this.getMagramaDetalleProducto(this.form.productRows[0] as ProductRowInterface)
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[1] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[2] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[3] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[4] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[5] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[6] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[7] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[8] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[9] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[10] as ProductRowInterface))
                    .then(this.getMagramaDetalleProducto.bind(this, this.form.productRows[11] as ProductRowInterface))
                    .then(() => {
                        const usos = (this.model.ids_usos || '').split(';');
                        this.form.productRows.forEach((item, i) => {
                            item.usos.selected = ((item.usos.filtered
                                .find(it => (it.value || {} as Uso).id === usos[i]) || {}).value as Uso);

                            this.applyFilters('usos', i);
                            this.applyFilters('agentes', i);
                        });

                        const agentes = (this.model.ids_agentes || '').split(';');
                        this.form.productRows.forEach((item, i) => {
                            if (this.showTratamientosAllProductos && item.productos.selected &&
                                ((item.productos.selected as ProductosModel).tipo === 'fertilizante')) {
                                item.usos.filtered = [{ label: '-', value: null }];
                                item.usos.selected = {} as Uso;
                                
                                item.agentes = list();
                                this.agentesAll.filtered.forEach((agente) => {
                                    item.agentes.filtered.push(agente);
                                });
                                item.agentesFilter.filtersToApply = [];
                            }

                            item.agentes.selected = ((item.agentes.filtered
                                .find(it => (it.value || {} as Uso).id === agentes[i]) || {}).value as Uso);
                        });

                        if (this.appName === 'hernandorena') {
                            this.form.productRows = this.form.productRows.slice(0, 3);
                        }
                        this.formRequest.update();
                        return;
                    }).catch (e => {
                        console.log('catch en getPosition: ' + e);
                    }
                    );
                resolve(true);                    
            } else {
                this.formRequest.update();
                resolve(true);
            }
        });
    }

    private getMagramaDetalleProducto(row: ProductRowInterface): Promise<boolean> {
        return new Promise((resolve) => {
            if (row && row.productos && row.productos.selected) {
                this.formApi.magramaDetalleProducto.response((resp) => {
                    row.usos.filtered = resp
                        .map((it: Uso) => ({
                            label: it.uso,
                            value: it
                        }))
                        .filter(
                            Filtering.distinctBy.bind({ field: 'label' })
                        );

                    row.usos.filtered.unshift({ label: 'Uso...', value: null });

                    row.agentes.values = resp
                        .map((it: Uso) => ({
                            label: it.agente,
                            value: it
                        }));

                    (row.agentes.values as []).forEach((agente: { value: Uso }) => {
                        agente.value.minDosis = agente.value.dosis1 ?? 0;
                        agente.value.maxDosis = agente.value.dosis2 ?? 0;

                        agente.value.dosis_min = agente.value.dosis1 ?? 0;
                        agente.value.dosis_max = agente.value.dosis2 ?? 0;

                        agente.value.isInvalid = false;
                    });

                    this.formApi.magramaDetalleProducto.unsuscribe();
                    this.formRequest.update();
                    resolve(true);
                });
                this.formApi.magramaDetalleProducto.safePerform(
                    { referencia: '\'' + (row.productos.selected as ProductosModel).num_registro + '\'' } as never);
            } else {
                this.formRequest.update();
                resolve(true);
            }
        });
    }

    private makeObjectCopy() {
        this.form.productRows = [];
        let numProductos = 7; // Productos por defecto
        if (this.appName === 'ava') {
            // Se cambia por defecto el número (iteraciones o repeticiones) de productos
            numProductos = 9;
        }
        if (environment.features.showTratamientosMaxProductos) {
            numProductos = 12;
        }
        for (let i = 0; i < numProductos; i++) {
            const copyProd = JSON.parse(JSON.stringify(this.form.productos));
            const copyUsos = JSON.parse(JSON.stringify(this.form.usos));
            const copyAgentes = JSON.parse(JSON.stringify(this.form.agentes));
            const row: ProductRowInterface = {
                productos: copyProd,
                usos: copyUsos,
                agentes: copyAgentes,
                usosFilter: new Filtering<TypeAheadInterface<Uso>>().setValue(copyUsos.values),
                agentesFilter: new Filtering<TypeAheadInterface<Uso>>().setValue(copyAgentes.values),
            };

            if (this.showTratamientosRecetaSwitch) {
                row.ocultar_en_receta = false;
            }

            this.form.productRows.push({
                productos: copyProd,
                usos: copyUsos,
                agentes: copyAgentes,
                usosFilter: new Filtering<TypeAheadInterface<Uso>>().setValue(copyUsos.values),
                agentesFilter: new Filtering<TypeAheadInterface<Uso>>().setValue(copyAgentes.values)
            });
        }

    }

    private sortAlphabetically(collection: { value: ProductosModel | null; label: string; unidades?: number; }[], field: string) {
        collection.sort(function (a, b) {
            let nameA, nameB;
            if (a && b && a.value && b.value) {
                nameA = ((a.value[field] as string) || '').toUpperCase(); // ignore upper and lowercase
                nameB = ((b.value[field] as string) || '').toUpperCase(); // ignore upper and lowercase
            } else {
                return 0;
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }
}