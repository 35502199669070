import { Component, ViewChild } from '@angular/core';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import {
    InputType,
} from '../../../../../common/components/form-builder/form-builder.component';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '../../../../base-form';
import {
    list,
    stack,
    build,
} from '../../../../../common/classes/request-builder';
import { environment } from '../../../../../../environments/environment';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { AnalisisModel } from '../../../../../models/analisis/analisis.model';
import { AnalisisApiService } from '../../../../../service/api/analisis-api.service';
import { Filtering } from '../../../../../service/filtering/filtering';
import moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { FileUploadAnswer, Utils } from '../../../../../common/utils';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { buildFieldsForms } from './fields/formfields';
import { features } from '../../../../../../environments/features';

import type { OnInit } from '@angular/core';
import type { 
    FilteredValuesInterface, 
    ItemInterface, 
    TypeAheadInterface 
} from '../../../../../common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { catchError, map, of } from 'rxjs';

@Component({
    selector: 'app-analisis-form',
    standalone: false,
    templateUrl: './analisis-form.component.html',
    styleUrls: ['./analisis-form.component.scss'],
})
export class AnalisisFormComponent extends BaseForm<AnalisisModel> implements OnInit {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<AnalisisModel>();

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public showAnalisisParcelas = environment.features.showAnalisisParcelas;
    public showAnalisisBoletin = environment.features.showAnalisisBoletin;
    public showAnalisisNumeroAnalisis = environment.features.showAnalisisNumeroAnalisis;
    public clienteName = environment.features.clienteName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public textoSuperficie = environment.features.textoSuperficie;

    public shouldCreateNextAnalisis = true;
    public enableFormChanges = true;

    public override model = {} as AnalisisModel;
    public formRequest = new AppFormRequest<AnalisisModel>();
    public type: FormRequestTypes = this.getType();
    public id_finca = '';

    // public analisisProximo: any;
    public programarSiguiente = false;

    public analisisImg: string | ArrayBuffer | null | undefined = '';
    public serverUrl = environment.serverUrl;
    public showAnalisisSectorParcelas = features.showAnalisisSectorParcelas;

    public clienteActual = StorageManager.getClient();

    // ANÁLISIS
    public tiposAnalisis = list(
        ['...', null],
        'Agua - Microbiológico',
        'Agua - Químico',
        'Foliar',
        'Fruta - Clementina',
        'Fruta - Fresón',
        'Fruta - Mandarina',
        'Fruta - Naranja',
        'Fruta - Satsuma',
        'Residuos',
        'Savia',
        'Solución nutritiva',
        'Suelo'
    );

    public tiposAnalisisViverogest = list(
        ['...', null],
        'Agua - Microbiológico',
        'Agua - Químico',
        'Foliar',
        'Fruta - Clementina',
        'Fruta - Fresón',
        'Fruta - Mandarina',
        'Fruta - Naranja',
        'Fruta - Satsuma',
        'Residuos',
        'Savia',
        'Solución nutritiva',
        'Suelo',
        'Suelo - pH',
        'Suelo - Conductividad',
        'Suelo - Nitratos',
        'Suelo - Sulfatos'
    );

    public tiposAnalisisCovidai = list(
        ['...', null],
        'Agua - Microbiológico',
        'Agua - Químico',
        'Foliar',
        'Planta Hongo',
        'Planta Virus',
        'Planta Bacterias',
        'Residuos',
        'Savia',
        'Solución nutritiva',
        'Suelo'
    );

    public tiposAnalisisTp = list(
        ['...', null],
        'Agua - Microbiológico',
        'Agua - Químico',
        'Foliar',
        'Fruta - Clementina',
        'Fruta - Fresón',
        'Fruta - Mandarina',
        'Fruta - Naranja',
        'Fruta - Satsuma',
        'Residuos',
        'Savia',
        'Solución nutritiva',
        'Suelo',
        'Suelo - nematodos'
    );

    public tiposAnalisisFast = list(
        ['...', null],
        'Agua',
        'Hoja',
        'Nematodos',
        'Suelo'
    );

    public tiposAnalisisArmentia = list(
        ['...', null],
        'Agua',
        'Planta',
        'Residuos',
        'Suelo'
    );

    public tiposAnalisisEsparragos = list(
        ['...', null],
        'Agua - Microbiológico',
        'Agua - Químico',
        'Producto - Microbiológico',
        'Producto - Multirresiduos',
        'Producto - Metales pesados',
        'Producto - Mercurio',
        'Producto - Amonios cuaternarios',
        'Producto - Fosfonatos',
        'Producto - Fosetil AI',
        'Producto - Cloratos y percloratos'
    );

    public frecuenciasFast = list('1 año');
    public frecuenciasArmentia = list(['...', null], '1 año', '2 años', '3 años');
    public frecuenciasEsparragos = list('Semanal');

    public analisis: AnalisisModel = new AnalisisModel;
    public clientes: TypeAheadInterface<ClienteModel | ClienteModel[]> = list();
    public fincas: TypeAheadInterface<FincasModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel> = list();
    public frecuencias: TypeAheadInterface<string> = list(
        ['...', null],
        '1 mes',
        '2 meses',
        '3 meses',
        '4 meses',
        '6 meses',
        '1 año',
        '2 años',
        '3 años',
        '4 años',
        '5 años'
    );
    public lista_analisis: TypeAheadInterface<string> = list(
        ['...', null],
        'Control rutinario',
        'Programas de vigilancia',
        'Otros'
    );

    public frecuenciasSuelo: TypeAheadInterface<string> = list(
        ['...', null],
        '3 años',
        '4 años',
        '5 años',
    );
    public frecuenciasFoliar: TypeAheadInterface<string> = list(
        ['...', null],
        '1 año',
        '2 años'
    );
    public frecuenciasFruta: TypeAheadInterface<string> = list(
        ['...', null],
        '1 mes',
        '2 meses',
        '3 meses',
        '4 meses',
        '6 meses',
        '1 año',
        '2 años',
        '3 años',
        '4 años',
        '5 años'
    );
    public frecuenciasAguaM: TypeAheadInterface<string> = list(
        ['...', null],
        '1 mes',
        '2 meses',
        '3 meses',
        '4 meses',
        '1 año'
    );
    public frecuenciasAguaQ: TypeAheadInterface<string> = list(
        ['...', null],
        '1 año',
        '2 años',
        '3 años'
    );
    public frecuenciasFreson: TypeAheadInterface<string> = list(['...', null], '1 mes');
    public frecuenciasResiduos: TypeAheadInterface<string> = list(
        ['...', null],
        'Quincena',
        '1 año'
    );
    public frecuenciasSavia: TypeAheadInterface<string> = list(['...', null], '1 mes');

    public estados: TypeAheadInterface<string> = list(
        'Programada',
        'Solicitada',
        'Realizada'
    );

    public showExtraFields = false;

    public sectores: TypeAheadInterface<SectorModel> = list();
    public lista_parcelas: TypeAheadInterface<ParcelasModel> = list();

    public formFields: ItemInterface<object>[] = [
    /*  {
            label: this.clienteName,
            field: 'id_cliente',
            inputType: { type: InputType.DROPDOWN },
            required: true,
            values: this.clientes,
            valuePrimaryKey: 'id',
            filter: (it) => {
                return this.clienteActual ? ((it.value || {}).id === this.clienteActual.id) : it;
            },
            visible: this.hasClients && (StorageManager.getUser().rol === 'admin')
        },
        {
            label: this.fincaName,
            field: 'id_finca',
            inputType: { type: InputType.DROPDOWN },
            required: true,
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it) => {
                if (this.hasClients && (StorageManager.getUser().rol === 'admin')) {
                    return it.value.id_cliente === (this.clientes.selected || {}).id;
                } else {
                    return it;
                }
            }
        },
        {
            label: this.parcelasName,
            field: 'id_parcela',
            inputType: { type: InputType.DROPDOWN },
            required: true,
            values: this.parcelas,
            valuePrimaryKey: 'id',
            filter: (it) => {
                if (this.fincas.selected) {
                    return it.value.id_finca === (this.fincas.selected || {}).id;
                } else if (this.clientes.selected) {
                    return it.value.id_cliente === (this.clientes.selected || {}).id;
                } else if (StorageManager.getClient()) {
                    return it.value.id_cliente === StorageManager.getClient().id;
                }
                return true;
            },
            visible: this.showAnalisisParcelas
        },
        {
            label: 'Tipo de análisis',
            field: 'tipo_analisis',
            inputType: { type: InputType.DROPDOWN },
            required: true,
            values: this.tiposAnalisis
        },
        {
            label: 'Fecha',
            field: 'fecha',
            inputType: { type: InputType.CALENDAR },
            required: true
        },
        {
            label: 'Muestra',
            field: 'muestra',
            inputType: { type: InputType.EDIT_TEXT },

        },
        {
            label: 'Frecuencia',
            field: 'frecuencia',
            inputType: { type: InputType.DROPDOWN },
            values: this.frecuencias,
            disabled: this.appName === 'lafast'
        },
        {
            label: 'Objeto del análisis',
            field: 'objeto_analisis',
            inputType: { type: InputType.DROPDOWN },
            values: this.lista_analisis,
            disabled: this.appName === 'lafast'
        },

        {
            label: 'Nº boletín',
            field: 'boletin',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showAnalisisBoletin
        },
        {
            label: 'Laboratorio',
            field: 'laboratorio',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showAnalisisBoletin
        },
        {
            label: 'Sustancia activa detectada',
            field: 'sustancia_activa',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.showAnalisisBoletin
        },
        {
            label: 'Estado',
            field: 'estado',
            inputType: { type: InputType.DROPDOWN },
            values: this.estados
        },
        {
            label: this.appName === 'laplana' ? 'Notas (cultivo)' : 'Notas',
            field: 'materia_activa',
            inputType: { type: InputType.EDIT_TEXT }
        },
        {
            label: 'Volumen (kg)',
            field: 'volumen_kg',
            inputType: { type: InputType.EDIT_TEXT }
        },
        {
            label: '',
            field: 'adjunto',
            inputType: { type: InputType.EMPTY }
        },
        {
            label: '',
            field: 'fechaSiguiente',
            inputType: { type: InputType.EMPTY },
            visible: false
        }, */
    ];

    public formFieldsResultadosAnalisis: ItemInterface<object>[] = [
        {
            label: 'pH',
            field: 'pH',
            inputType: { type: InputType.EDIT_TEXT },
        },
        {
            label: 'Conductividad',
            field: 'conductividad',
            inputType: { type: InputType.EDIT_TEXT },
        },
        {
            label: 'Sulfatos',
            field: 'sulfatos',
            inputType: { type: InputType.EDIT_TEXT },
        },
        {
            label: 'Nitratos',
            field: 'nitratos',
            inputType: { type: InputType.EDIT_TEXT },
        },
        {
            label: 'Valoración',
            field: 'valoracion',
            inputType: { type: InputType.DROPDOWN },
            values: list(['...', null], 'Alta', 'Adecuada', 'Baja')
        },
    ];
  
    public tipoSuperficieParcelas: string = environment.features.hasSuperficieCultivada
        ? 'S.\xa0C.'
        : 'S.\xa0Sigpac';
    public oldSector = 0;
  

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public formApi: FormCommonApiService,
        public override dashboard: DashboardService,
        public analisisApi: AnalisisApiService,
        public http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            analisisApi.analisis.PUT,
            analisisApi.analisis.POST,
            'analisis',
            'Editar Recordatorio de Análisis',
            'Crear Recordatorio de Análisis'
        );
        this.formFields = buildFieldsForms(
            this.clienteName,
            this.clientes,
            this.clienteActual,
            this.hasClients,
            this.fincaName,
            this.fincas,
            this.parcelasName,
            this.parcelas,
            this.showAnalisisParcelas,
            this.tiposAnalisis,
            this.frecuencias,
            this.appName,
            this.applicationType,
            this.lista_analisis,
            this.showAnalisisNumeroAnalisis,
            this.estados,
            this.sectores,
            this.lista_parcelas,
            this.showAnalisisBoletin
        );


    }

    ngOnInit() {
        this.initFormData();
        setTimeout(() => {
            this.expandFormRequest();
        }, 9000);
    
        /* this.fincasApi.sectoresGET.toPromise().then( (sectores:any) => {
      for (let index = 0; index < sectores.length; index++) {
        const element =  { value:sectores[index].id ,label: sectores[index].nombre };
        this.sectores.filtered.push(element);
        if (index + 1 >= sectores.length) {
          console.log(this.sectores);
        }
      }
    });
     */
        const fieldsToSend = (['id', 'id_usuario']).concat(
            this.formFields.map((it) => it.field ?? '')
        );

        if (this.appName === 'viverospereira' ) {
            fieldsToSend.push(
                ...this.formFieldsResultadosAnalisis.map(it => it.field ?? '')
            );
        }

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.formatForm();

        this.formRequest
            .setType(this.getType())
            .isGeneric(false)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.analisisApi.analisis.GET)
            .setPutRequest(this.analisisApi.analisis.PUT)
            .setPostRequest(this.analisisApi.analisis.POST)
            .setFormFields(Array.prototype
                .concat(
                    this.formFields,
                    this.formFieldsResultadosAnalisis.map(it => it.field),
                )
            )
            .setFieldsToSend(fieldsToSend);
        this.formRequest.load();
        this.softInit(this.getType());
    }

    public submit() {
        delete this.model.parcelas_superficie;
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {
   
        if (tag === 'id_sector' && this.showAnalisisSectorParcelas) {
            this.model.parcelas_superficie = 0;
            this.model.ids_parcelas = '';
            this.countSuperficieParcelas();
      
        } else {
            if ( this.showAnalisisSectorParcelas ) {
                this.countSuperficieParcelas();

            }
        }
   
        this.requestButton.error = '';
        if (this.enableFormChanges) {
            this.showExtraFields = false;
            const idxFrecuencias = this.getItemPosition(
                this.formFields,
                'frecuencia'
            );
            const idxMuestra = this.getItemPosition(this.formFields, 'muestra');
            const muestra = this.formFields[idxMuestra];
            const frecuencia = this.formFields[idxFrecuencias];
            if (muestra) {
                muestra.label =
                this.model.tipo_analisis === 'Agua' ? 'Procedencia muestra' : 'Muestra';
                //this.formFields[5].label = 'Procedencia muestra' ;
            }

            if (tag === 'tipo_analisis' && frecuencia) {
                this.tiposAnalisis.selected = this.model.tipo_analisis;
                if (this.tiposAnalisis.selected.includes('Suelo')) {
                    if (this.tiposAnalisis.selected === 'Suelo - pH' || this.tiposAnalisis.selected === 'Suelo - Conductividad') {
                        frecuencia.values = this.frecuenciasEsparragos;
                        this.model.frecuencia = 'Semanal';
                        this.frecuencias.selected = this.model.frecuencia;
                        this.formChanges('frecuencia');
                    } else {
                        frecuencia.values = this.frecuenciasSuelo;
                    }
                } else if (this.tiposAnalisis.selected === 'Foliar') {
                    frecuencia.values = this.frecuenciasFoliar;
                } else if (
                    this.tiposAnalisis.selected === 'Fruta - Naranja' ||
          this.tiposAnalisis.selected === 'Fruta - Mandarina' ||
          this.tiposAnalisis.selected === 'Fruta - Clementina' ||
          this.tiposAnalisis.selected === 'Fruta - Satsuma'
                ) {
                    this.showExtraFields = true;
                    frecuencia.values = this.frecuenciasFruta;
                } else if (this.tiposAnalisis.selected === 'Fruta - Fresón') {
                    frecuencia.values = this.frecuenciasFreson;
                } else if (this.tiposAnalisis.selected === 'Residuos') {
                    frecuencia.values = this.frecuenciasResiduos;
                } else if (this.tiposAnalisis.selected === 'Agua - Microbiológico') {
                    frecuencia.values = this.frecuenciasAguaM;
                } else if (this.tiposAnalisis.selected === 'Agua - Químico') {
                    frecuencia.values = this.frecuenciasAguaQ;
                } else if (this.tiposAnalisis.selected === 'Savia') {
                    frecuencia.values = this.frecuenciasSavia;
                }
            }
            if (tag === 'fecha' || tag === 'frecuencia') {
                this.getNextDate();
            }
        }

    }

    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.analisisImg = e.target?.result ?? '';
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post<FileUploadAnswer>
            (environment.serverUrl + 'ws/analisis/upload_file.php', this.analisisImg).subscribe(res => {
                const file = res;
                this.model.adjunto = file.fileName;
                r.unsubscribe();

                r.unsubscribe();
            });
        }
    }


    public fileChange(event: Event ) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                this.http.post<FileUploadAnswer>(
                    `${environment.serverUrl}ws/analisis/upload_file.php`, 
                    formData, 
                    {headers: headers}
                ).pipe(
                    map(data => {
                        const res = data;
                        this.model.adjunto = res.fileName;
                    }),
                    catchError(error => {
                        console.log(error);
                        return of(null);
                    })
                ).subscribe();
            }
        }
    }

    public verAdjunto() {
        const myWindow = window.open(
            'about:blank',
            '_blank',
            'width=600, height=600'
        );
        myWindow?.location.assign(
            environment.serverUrl + 'analisis/' + this.model.adjunto
        );
        myWindow?.focus();
    }


    public setNextDate(
        date: Date | string,
        period: number,
        timelapse: moment.unitOfTime.DurationConstructor
    ) {
        if (!date || !period || !timelapse) {
            this.model.fechaSiguiente = null;
            return;
        }
        if (typeof date === 'string') {
            date = moment(date, 'DD/MM/YYYY').toDate();
        }
        const auxDate = moment(date).add(period, timelapse);

        this.model.fechaSiguiente = auxDate.format('DD/MM/YYYY');
    }

    public getNextDate() {
        let period = 0;
        let timelapse: moment.unitOfTime.DurationConstructor = 'year';
        switch (this.model.frecuencia) {
        case 'Semanal':
            period = 7;
            timelapse = 'day';
            break;
        case 'Quincena':
            period = 15;
            timelapse = 'day';
            break;
        case '1 mes':
            period = 1;
            timelapse = 'month';
            break;
        case '2 meses':
            period = 2;
            timelapse = 'month';
            break;
        case '3 meses':
            period = 3;
            timelapse = 'month';
            break;
        case '4 meses':
            period = 4;
            timelapse = 'month';
            break;
        case '6 meses':
            period = 6;
            timelapse = 'month';
            break;
        case '1 año':
            period = 1;
            timelapse = 'year';
            break;
        case '2 años':
            period = 2;
            timelapse = 'year';
            break;
        case '3 años':
            period = 3;
            timelapse = 'year';
            break;
        case '4 años':
            period = 4;
            timelapse = 'year';
            break;
        case '5 años':
            period = 5;
            timelapse = 'year';
            break;

        default:
            break;
        }
        this.setNextDate(this.model.fecha ?? '', period, timelapse);
    }

    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }


    changeSectores() {
    // console.log(this.model.id_finca);

        const builds = [];
        if ( this.showAnalisisSectorParcelas ) {
            builds.push(
                build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true),
                build(this.fincasApi.parcelasGET, this.lista_parcelas, 'nombre', true),
            );

            stack(builds).then(() => {
                this.countSuperficieParcelas();
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;
        const idxParcelas = this.getItemPosition(this.formFields, 'ids_parcelas');
        const parcelaAnalisis = this.formFields[idxParcelas];
        // console.log(this.formFields[idxParcelas]);

        ((parcelaAnalisis?.values as TypeAheadInterface<ParcelasModel>).filtered || []
        ).forEach((parcela: FilteredValuesInterface<ParcelasModel>) => {
            if (parcela && parcela.value && ids.includes(parcela.value.id) && this.model.parcelas_superficie !== undefined) {
                this.model.parcelas_superficie += parseFloat((parcela.value.superficie_cultivada || '').replace(',', '.') || '0.00');
            }
        });
        this.model.parcelas_superficie = +Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);


        if (parcelaAnalisis) {
            parcelaAnalisis.label = this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
    }

    private formatForm() {
        const idxAnalisis = this.formFields
            .map((it) => it.field)
            .indexOf('tipo_analisis');
        const idxFrecuencia = this.formFields
            .map((it) => it.field)
            .indexOf('frecuencia');

        const analisis = this.formFields[idxAnalisis];
        const frecuencia = this.formFields[idxFrecuencia];
        if (analisis && frecuencia) {
            if (this.appName === 'lafast') {
                analisis.values = this.tiposAnalisisFast;
                frecuencia.values = this.frecuenciasFast;
                this.model.frecuencia = '1 año';
                this.enableFormChanges = false;
            }
            else if (this.appName === 'armentia') {
                analisis.values = this.tiposAnalisisArmentia;
                frecuencia.values = this.frecuenciasArmentia;
            }
            else if (this.appName === 'torrepacheco') {  
                analisis.values = this.tiposAnalisisTp;
            }
            else if (this.appName === 'esparragosgranada') {
                analisis.values = this.tiposAnalisisEsparragos;
                frecuencia.values = this.frecuenciasEsparragos;
            }
            else if (this.appName === 'fitogest') {

                //this.formFields[idxAnalisis].values = this.tiposAnalisisArmentia;
                frecuencia.values = this.frecuencias;
            }else if (this.appName === 'covidai') {
                analisis.values = this.tiposAnalisisCovidai;
            } else if (this.applicationType === 'viverogest') {
                analisis.values = this.tiposAnalisisViverogest;
            }
        }
        
        if ( this.showAnalisisSectorParcelas ) {

            const intervalo = setInterval(() => {
                if (this.model.ids_parcelas == null) {
                    clearInterval(intervalo);
                } else if (this.model.ids_parcelas.length === 0) {
                    clearInterval(intervalo);
                } else {
          
                    this.countSuperficieParcelas();
                    clearInterval(intervalo);
                }
            }, 1000);
        }

    }

    private expandFormRequest() {
    // NO CHANGE STACK BY POOL IN THAT CASE!!
        const builds = [
            build(this.clientesApi.clientesGET, this.clientes, 'nombre'),
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', true)

        ];

        this.formRequest.beforeLoad((resolve) => {
            stack(builds).then(() => {
                this.formRequest.update();
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        });

        this.formRequest.afterLoad((resolve) => {
            if (this.getType() !== FormRequestTypes.CREATE) {
                this.tiposAnalisis.selected = this.model.tipo_analisis;
                this.formChanges('tipo_analisis');  
            }
            const fincaInterval = setInterval(() => {
                if (this.fincas.filtered[1] === undefined ){

                } else {
        
                    if ( this.fincas.filtered.length === 2  && this.getType() === 0 ){
                        this.model.id_finca = this.fincas.filtered[1].value?.id ?? '';
                        clearInterval(fincaInterval);
                    }
                }
            }, 1000);

            if (this.model.fecha && this.model.frecuencia) {
                this.getNextDate();
            }
            resolve(true);
        });

        this.formRequest.beforeSend((resolve) => {

            this.model.fecha =
        this.model.fecha
            ? Utils.formatDate(this.model.fecha)
            : this.model.fecha ?? '';

            if (!this.model.estado) {
                this.model.estado = 'Programada';
            }
            console.log(this.model);
            if (!this.model.id_cliente) {
                this.model.id_cliente = (
                    this.fincas.selected || ({})
                ).id_cliente ?? '';
            }
            console.log(this.model);
            if (
                !(
                    this.model.fecha &&
          this.model.frecuencia &&
          this.shouldCreateNextAnalisis &&
          (this.type === FormRequestTypes.CREATE || this.type === FormRequestTypes.DUPLICATE)
                )
            ) {
                delete this.model.fechaSiguiente;
            }
            resolve(true);
        });
    }

    private initFormData() {
        const builds = [
            build(this.clientesApi.clientesGET, this.clientes, 'nombre', true),
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', true),
        ];

        if (this.showAnalisisParcelas) {
            builds.push(
                build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true)
            );
        }

        stack(builds).then(() => {
            this.changeSectores();
            const userId = StorageManager.getUser().id;

            this.clientes.filtered = (this.clientes.filtered || [])
                .filter((it) => it && it.value)
                .map((it) => it.value)
                .filter((it) =>
                    [(it as ClienteModel).id_usuario_asignado, (it as ClienteModel).id_usuario_asignado2].includes(userId)
                )
                .map((it) => ({
                    label: (it as ClienteModel).razon_social || (it as ClienteModel).nombre,
                    value: it,
                }))
                .sort(Filtering.sort('label', 1));

            this.clientes.filtered.unshift({
                label: '...',
                value: null,
            });

            if (this.clienteActual) {
                this.model.id_cliente = this.clienteActual.id;
            }

            this.formRequest.update();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }
}