<div [formGroup]="model" [ngClass]="getEditedClass(field)">
    @if(field.label) {
        <label [attr.for]="field.name" class="field-label">
          
          @if(isFieldEdited(field)) {
            <i
              pTooltip="Deshacer ({{ originalModel[field.name] }})"
              tooltipPosition="top"
              (click)="undoField(field)"
              class="fa fa-undo"
              aria-hidden="true"
              style="cursor: pointer">
            </i>
          }

          {{ field.label }}

          @if(field.input.dropdownConfiguration?.summation) {
            <ng-container>{{ sum(field) }}</ng-container>
          }

          @if(field.conditions.isRequired) {
            <span class="required-asterisk"> *</span>
          }
        </label>
    }
    @switch (field.input.type) {
    
        @case (INPUT.ONLY_TEXT) {
            <p [style]="'background-color: transparent;' + field.input.style">
                {{model.get(field.name)?.value || '-'}}
            </p>       
        }
            
        @case (INPUT.NUMERIC) {
            <input 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name" 
                placeholder="..." 
                pInputText 
                type="number"/>
        }
            
        @case (INPUT.TEXTAREA) {
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <textarea 
                        [formControlName]="field.name" 
                        rows="{{field.input.textAreaConfiguration?.rows}}" 
                        cols="{{field.input.textAreaConfiguration?.cols}}" 
                        [readonly]="!isEditable()" 
                        class="w-100"
                        placeholder="..." 
                        pTextarea>
                    </textarea>   
                        @if (field.input.textAreaConfiguration?.hasVoiceTranscription === true && isEditable()) {
                            <app-reactive-dictado 
                                transcriptionLabel="Grabar {{field.label}}" 
                                [speechTranscription]="model.get(field.name)?.value ?? ''" 
                                (sendTranscription)="getAudio(field.name, $event)">
                            </app-reactive-dictado>
                        }
                </div>
                @if (field.input.textAreaConfiguration?.canTakePhoto === true) {
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <app-reactive-upload-images
                            [formModel]="model.getRawValue()"
                            [imageFolder]="form.name"
                            [formType]="form.type"
                            [imageName]="field.input.textAreaConfiguration?.photoField ?? null"
                            (sendImageName)="updateImageModel($event, field.input.textAreaConfiguration?.photoField ?? '')">
                        </app-reactive-upload-images>
                    </div>
                }
            </div>
        }
            
        @case (INPUT.TEXT) {
            <input
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name"
                placeholder="..."
                pInputText
                type="text"
                [maxlength]="field.input.maxLength ?? ''"
            />
        }
            
        @case (INPUT.PASSWORD) {
            <input 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name"  
                pInputText 
                type="password" 
            />
        }
            
        @case (INPUT.TEXT_MASK) {
            <p-inputMask 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name" 
                [mask]="field.input.textMaskConfiguration?.mask" 
                [placeholder]="field.input.textMaskConfiguration?.mask">
            </p-inputMask>
        }
            
        @case (INPUT.TWO_FIELDS_CONCAT) {
            <div *ngIf="isEditable(); else readOnly" class="mt-0 d-flex justify-content-between">
                <input [formControlName]="field.input.twoFieldsConcatConfiguration?.subField1 ?? ''" placeholder="..." pInputText type="number" />
                <span class="mx-3">{{field.input.twoFieldsConcatConfiguration?.separator || 'x'}}</span>
                <input [formControlName]="field.input.twoFieldsConcatConfiguration?.subField2 ?? ''" placeholder="..." pInputText type="number" />
            </div>
        }
            
        @case (INPUT.DROPDOWN) {
            <p-select
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name"
                [options]="filteredOptions"
            ></p-select>
        }
            
        @case (INPUT.DROPDOWN_SEARCH) {
            <p-select 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name"  
                [virtualScrollItemSize]="38" 
                [options]="filteredOptions" 
                optionLabel="label" 
                optionValue="value" 
                [filter]="true" 
                filterBy="label">
            </p-select>
        }
            
        @case (INPUT.MULTISELECT) {
            <p-listbox 
                *ngIf="isEditable(); else readOnly"
                [filter]="filterInputParcelas"  
                [multiple]="true" 
                [metaKeySelection]="false" 
                [checkbox]="true" 
                [showToggleAll]="false"  
                [listStyle]="{'max-height' : field.input.dropdownConfiguration?.hasMaxHeight ? '100%' : '15rem','min-height':'18px'}" 
                [formControlName]="field.name"  
                [options]="filteredOptions">
            </p-listbox>
        }
            
        @case (INPUT.MULTISELECT_SEARCH) {
            <p-multiselect 
                *ngIf="isEditable(); else readOnly"
                [options]="filteredOptions"
                [formControlName]="field.name"
                optionLabel="label" 
                optionValue="value"
                [filter]="true" 
                filterBy="label" 
                [showToggleAll]="true"
                placeholder="Seleccionar"
                [maxSelectedLabels]="3" 
                styleClass="w-full md:w-80" />
        }
            
        @case (INPUT.CALENDAR) {
            <p-datePicker 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name" 
                [firstDayOfWeek] = "1" 
                dateFormat="dd/mm/yy"
                dataType="string" 
                placeholder="..." 
                appendTo="body" 
                [view]="field.input.calendarConfiguration?.calendarView || 'date'">
            </p-datePicker>
        }
            
        @case (INPUT.TIME) {
            <p-datePicker 
                *ngIf="isEditable(); else readOnly"
                [formControlName]="field.name" 
                [timeOnly]="true" 
                placeholder="..."
                dateFormat="hh:mm"
                dataType="string">
            </p-datePicker>
        }
            
        @case (INPUT.EMPTY) {
            <div></div>
        }
            
        @case (INPUT.SWITCH) {
            @if (!isEditable()) {
                <div style="pointer-events:none;" >
                    <p-toggleswitch 
                        [formControlName]="field.name" 
                        [trueValue]="'1'" 
                        [falseValue]="'0'">
                    </p-toggleswitch>
                </div> 
            } @else {
                <p-toggleswitch 
                    [formControlName]="field.name"  
                    [trueValue]="'1'" 
                    [falseValue]="'0'">
                </p-toggleswitch>
            }
        }
            
        @case (INPUT.SELECT_BUTTON) {
            <p-selectButton
                (onOptionClick)="updateModel(field)"
                [options]="filteredOptions" 
                [multiple]="field.input.selectButtonConfiguration?.isMultiSelect"
                [disabled]="field.input.selectButtonConfiguration?.isDisabled ?? false">
            </p-selectButton>
        }
            
        @case (INPUT.EXTERNAL) {
            <ng-template dynamic-container></ng-template>
        }
            
        @default {
            <input 
                [hidden]="true" 
                [formControlName]="field.name" 
                placeholder="..." 
                pInputText 
                type="text" 
                maxlength={{field.input.maxLength}}/>
        }
    }
    
    <!-- Visualization an Deletion Form... -->
    <ng-template #readOnly>
        <p class="read-only">{{ model.get(field.name)?.value || '-' }}</p>
    </ng-template>
    <!--@if (!isValid) {<div class="errorMessage">{{ field.label }} is required</div>}-->
</div>