import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {ProductosApiService} from '../../../../service/api/productos-api';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {Utils} from '../../../../common/utils';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';

import {environment} from '../../../../../environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-producto-bio',
    standalone: false,
    templateUrl: './producto-bio.component.html',
    styleUrls: ['./producto-bio.component.css']
})
export class ProductosBioComponent extends BaseView<ProductosModel> implements OnInit, OnDestroy {
    public tableValues = new RequestHandler();
    public menuItemId = 'productos-bio';
    public appName = environment.appName;
    public showPrecio = environment.features.showPrecio;
    public showFertisComposicion = environment.features.showFertisComposicion;
    public canAddProductos = environment.features.canAddProductos;
    public canEditProductos = environment.features.canEditProductos;
    public canDeleteProductos = environment.features.canDeleteProductos;

    public getRequest: RequestHandler<ProductosModel[]> = this.productosApi.productos.GET;
    public deleteRequest: RequestHandler<ProductosModel> = this.productosApi.productos.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;
    public buttonText = 'Añadir Bio';

    public topFormFields: ItemInterface<object>[] = [
        {
            label: this.appName === 'vginer' ? 'Fertilizante' : 'Nombre o Marca Comercial', 
            field: 'nombre',
            inputType: { type: InputType.TEXT }
        },
        {
            label: 'Composición', 
            field: 'composicion', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showFertisComposicion 
        },
        {
            label: 'Precio', 
            field: 'precio', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showPrecio 
        },
        { 
            label: 'Ud Medida', 
            field: 'unidades', 
            inputType: { type: InputType.TEXT } 
        },
    ];


    public cols: ColInterface[] = [
        {
            field: 'nombre', 
            header: this.appName === 'vginer' ? 'Fertilizante' : 'Nombre o Marca Comercial', 
            filter: '',
        },
        { field: 'composicion', header: 'Composición', visible: this.showFertisComposicion },
        { field: 'precio', header: 'Precio', filter: '', width: '80px', visible: this.showPrecio },
        { field: 'unidades', header: 'Ud Medida', filter: '', width: '120px' },
    ];

    constructor(public productosApi: ProductosApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(dashboardEvents, productosApi.productos.GET);
    }

    

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem(this.menuItemId);
        this.tableValues.isLoading = true;
        this.productosApi.productos.GET.safePerform();
        this.productosApi.productos.GET.response(data => {
            this.tableValues.emitResponse(data.filter((item: ProductosModel) => {
                this.tableValues.isLoading = false;
                return item.tipo === 'bio';
            }));
        });
    }
    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {

        if ( col.field === 'nombre') {
            return{
                textAlign: 'center'
            };
        }
        if ( col.field === 'composicion') {
            return{
                textAlign: 'center'
            };
        }

        if (col.field === 'observaciones') {
            return {
                textAlign: 'left',
                paddingLeft: '35px',
            };

        }

        if (Utils.isNumber(value) || col.field === 'precio') {
            return {
                textAlign: 'right',
                paddingRight: '30px',
            };

        }

        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return {
                textAlign: 'right',
            };
        }

        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (Utils.isNumber(value) && col.field !== 'descripcion') {
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }

        if (col.field === 'descripcion') {
            return (value?.length > 25) ? value.slice(0, 25 - 1) + '…' : value;
        }
        return value;
    }

    public addProducto() {
        this.router.navigate(['dashboard', 'crear-producto-bio']);
    }

    public edit(data: ProductosModel) {
        this.router.navigate(['dashboard', 'editar-producto-bio', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: ProductosModel) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {} as ProductosModel;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public goBack() {
        this.router.navigate(['dashboard', 'productos-bio']);
    }
}
