import { Component, Input } from '@angular/core';
import { RequestHandler } from 'src/app/service/OffService/request-handler';

import type { OnInit } from '@angular/core';
@Component({
    selector: 'app-common-chart',
    standalone: false,
    templateUrl: './common-chart.component.html',
    styleUrls: ['./common-chart.component.scss']
})
export class CommonChartComponent<T> implements OnInit {
  
    @Input() request = new RequestHandler<T>();
    @Input() title = '';
    @Input() type: any;
    @Input() options: {
        field: string;
        label: string;
        extraFields?: string[];
        fieldIsArray: boolean;
        multipleFields: boolean;
    } = {field: '', label: '', fieldIsArray: false, multipleFields: false};

    public dataAll: any[] = [];
    public data: ChartData = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: []
        }]
    };
    public _noData: ChartData = {
        labels: ['Cargando...'],
        datasets: [{
            data: [1],
            backgroundColor: ['lightgray']
        }]
    };

    public yearsDropdownOptions: { label: string, value: string | number }[] = [];
    public filteredData: any[] = [];
    public selectedYear: string | number = 'All';
    public chartOptions: any = {};

    public colorPalette: string[] = [
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#bcbd22',
        '#17becf'
    ];

    constructor() { }

    ngOnInit() {
        if (this.type === 'line'){
            this.request.toPromise().then(data => {
                this.dataAll = (data as T[]).filter((item: any) => item.fecha_inicio != null && item.fecha_inicio !== undefined);    
                this.populateYearsDropdown();
                this.processLineChartData();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }else if(this.type === 'bar'){
            this.request.toPromise().then(data => {
                console.log(data);
                this.dataAll = (data as T[]).filter((item: any) => item.finca != null);
                this.processStackedBarChartData();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }else{
            this.request.toPromise().then(data => this.dataAll = data as T[]).then(() => this.formatData()).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }
    }
    
    populateYearsDropdown() {
        const uniqueYears = Array.from(new Set(this.dataAll
            .map(item => {
                if (item.fecha_inicio) {
                    try {
                        const cleanedDate = item.fecha_inicio.split('/').reverse().join('-');
                        const year = new Date(cleanedDate).getFullYear();
                        if (!isNaN(year)) {
                            return year;
                        }
                    } catch (error) {
                        console.error('Error procesando la fecha:', item.fecha_inicio, error);
                    }
                }
                return null;
            })
            .filter(year => year !== null)
        ));
    
        this.yearsDropdownOptions = [
            { label: 'TODOS', value: 'All' },
            ...uniqueYears.map(year => ({ label: year!.toString(), value: year! }))
        ];

    }
    
    

    filterDataByYear(year: string | number) {
        this.selectedYear = year;
        if (year === 'All') {
            this.filteredData = this.dataAll;
        } else {
            this.filteredData = this.dataAll.filter(item => {
                if (item.fecha_inicio) {
                    const cleanedDate = item.fecha_inicio.split('/').reverse().join('-');
                    const itemYear = new Date(cleanedDate).getFullYear();
                    
                    return itemYear === +year;
                }
                return false;
            });
        }
        // console.log('Datos filtrados por fecha: ', this.filteredData);
        this.processLineChartData();
    }
    
    

    processLineChartData() {
        const chartData = (this.selectedYear === 'All' ? this.dataAll : this.filteredData)
            .sort((a, b) => new Date(a.fecha_inicio).getTime() - new Date(b.fecha_inicio).getTime());
    
        if (chartData && chartData.length > 0) {
            const labels = chartData.map(item => item.fecha_inicio);
    
            const datasetPrimary = chartData.map(item => {
                if (this.options.field === 'dosis_total') {
                    return this.sumDosis(item);
                } else {
                    return this.getFieldValue(item, this.options.field);
                }
            });
    
            let primaryLabel = 'Dosis Total';
            if (this.title === 'Consumo de Fertilizantes') {
                primaryLabel = 'Dosis Total';
            } else if (this.title === 'Fitosanitarias') {
                primaryLabel = 'Cantidad';
            }

            const datasets = [{
                label: primaryLabel,
                data: datasetPrimary,
                borderColor: '#42A5F5',
                fill: false
            }];
    
            if (this.options.extraFields) {
                this.options.extraFields.forEach((extraField, index) => {
                    const datasetExtra = chartData.map(item => this.getFieldValue(item, extraField));

                    let extraLabel = extraField;
                    if (this.title === 'Consumo de Fertilizantes' && extraField === 'sup_cultivada') {
                        extraLabel = 'Superficie Cultivada';
                    }
                    datasets.push({
                        label: extraLabel,
                        data: datasetExtra,
                        borderColor: index % 2 === 0 ? '#66BB6A' : '#FFA726',
                        fill: false
                    });
                });
            }
    
            this.data = {
                labels: labels.reverse(),
                datasets: datasets
            };
        } else {
            this.data = this._noData;
        }
    }

    processStackedBarChartData() {
        const groupedData: any = {};

        const filteredData = this.dataAll.filter((item: any) => item.finca && item.comprador);

        filteredData.forEach((item: any) => {
            const finca = item.finca;
            const comprador = item.comprador;
            const cantidad = parseFloat(item.cantidad);
    
            if (!groupedData[finca]) {
                groupedData[finca] = {};
            }
            if (!groupedData[finca][comprador]) {
                groupedData[finca][comprador] = 0;
            }
            groupedData[finca][comprador] += isNaN(cantidad) ? 0 : cantidad;
        });
    
        const fincas = Object.keys(groupedData);
        const compradores: string[] = [];
        fincas.forEach(finca => {
            Object.keys(groupedData[finca]).forEach(comprador => {
                if (!compradores.includes(comprador)) {
                    compradores.push(comprador);
                }
            });
        });

        console.log(compradores);
    
        const datasets = compradores.map((comprador, index) => ({
            label: comprador,
            data: fincas.map(finca => groupedData[finca][comprador] || 0),
            backgroundColor: this.colorPalette[index % this.colorPalette.length] || '#000000',
        }));
    
        this.data = {
            labels: fincas,
            datasets: datasets
        };
    
        this.chartOptions = {
            scales: {
                x: {
                    stacked: true
                },
                y: {
                    stacked: true
                }
            }
        };
    }
    
    sumDosis(item: any): number {
        const dosisKeys = ['dosis1', 'dosis2', 'dosis3', 'dosis4', 'dosis5', 'dosis6', 'dosis7'];
        return dosisKeys.reduce((total, key) => {
            const value = parseFloat(item[key]);
            return total + (isNaN(value) ? 0 : value);
        }, 0);
    }

    getFieldValue(item: any, field: string): number {
        const value = parseFloat(item[field]);
        return isNaN(value) ? 0 : value;
    }

    formatData() {

        // console.log(this.dataAll);
        if (this.options.field === 'id_tratamiento') {
            const values: any[] =  this.dataAll
                .filter(it => it && it.tratamiento && it.tratamiento.linea)
                .map(it => it.tratamiento.linea);

            let productos: any = [];
            values.forEach(value => {
                value.forEach((subValue: { producto: { num_registro: any; nombre: any; }; cantidad_real: any; }) => {
                    if (subValue.producto && subValue.producto.nombre) {
                        if (!productos.map((it: { num_registro: any; }) => it.num_registro).includes(subValue.producto.num_registro)) {
                            productos.push({
                                num_registro: subValue.producto.num_registro,
                                nombre: (subValue.producto.nombre || '').trim(),
                                cantidad: parseFloat(subValue.cantidad_real || '0'),
                                color: '#' + Math.floor(Math.random() * 16777215).toString(16)
                            });
                        } else {
                            const idx = productos.map((it: { num_registro: any; }) => it.num_registro).indexOf(subValue.producto.num_registro);
                            productos[idx].cantidad += parseFloat(subValue.cantidad_real || '0');
                        }
                    }
                });
            });

            productos = productos.sort((a: { cantidad: number; }, b: { cantidad: number; }) => b.cantidad - a.cantidad).slice(0, 5);

            this.data = {
                labels: productos.map((it: { nombre: any; }) => it.nombre),
                datasets: [{
                    data: productos.map((it: { cantidad: any; }) => it.cantidad),
                    backgroundColor: productos.map((_: any, index: number) => this.colorPalette[index % this.colorPalette.length] || '#000000')
                }]
            };
        } else {
            const values: any[] =  this.dataAll
                .filter(it => it && it[this.options.field] && it[this.options.label])
                .map(it => ({
                    [this.options.label]: it[this.options.label],
                    [this.options.field]: it[this.options.field],
                    color: '#' + Math.floor(Math.random() * 16777215).toString(16)
                }));
      
            const partialData: any = [];
            values.forEach(value => {
                if (partialData.map((it: Record<string, any>) => it[this.options.label]).includes(value[this.options.label])) {
                    const idx = partialData.map((it: Record<string, any>) => it[this.options.label]).indexOf(value[this.options.label]);
                    partialData[idx][this.options.field] += parseFloat(value[this.options.field] || '0');
                } else {
                    partialData.push({
                        [this.options.label]: value[this.options.label],
                        [this.options.field]: parseFloat(value[this.options.field] || '0'),
                        color: '#' + Math.floor(Math.random() * 16777215).toString(16)
                    });
                }
            });

            // @ts-ignore
            const data = partialData.sort((a: Record<string, number>, b: Record<string, number>) => (b[this.options.field]) - a[this.options.field]).slice(0, 5);

            this.data = {
                labels: data.map((it: Record<string, any>) => it[this.options.label]),
                datasets: [{
                    data: data.map((it: Record<string, any>) => it[this.options.field]),
                    backgroundColor: data.map((_:any, index:number) => this.colorPalette[index % this.colorPalette.length]) // Asignar colores del array
                }]
            };

        }
    }
}
export interface ChartData {
    labels: string[];
    datasets: {
        label?: string;
        data: number[];
        backgroundColor?: string | string[];
        borderColor?: string;
        fill?: boolean;
    }[];
}

