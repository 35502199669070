import { Input } from './input.interface';


export interface Form<T> {
    name: string,
    type: FormType,
    sections: FormSection<T>[],
    table: FormTable | null
    signed: boolean
}

export interface FormSection<T> {
    name: string,
    fields: FormField<T>[],
    width: number,
    isVisible: boolean,
    order: number
}

export interface FormField<T> {
    [key: string]: 
        ((it: FormField<T>) => T | string | null | undefined) |
        ((it: FormField<T>) => void) |
        Input<T> |
        FormFieldConditions |
        string[] | 
        string | 
        number | 
        boolean | 
        undefined;
    name: string;
    vinculatedFields: string[] | undefined;
    label: string;
    input: Input<T>;
    width: number,
    conditions: FormFieldConditions;
    order: number;    
    turnVisible?: (it: FormField<T>) => void;
    updateValue?: (it: FormField<T>) => T | string | null | undefined;
}

export interface FormTable {
    data: string[] | string[][] | undefined, 
    style: string[] | string[][] | undefined
}

export interface FormFieldConditions {
    isRememberedOnDuplicate: boolean;
    isRequired: boolean;
    isRetained: boolean;
    hasUndoEnabled: boolean;
    isVisible: boolean;
}

export enum FormType {
    CREATION,
    EDITION,
    DUPLICATION,
    DELETION,
    INFORMATIVE,
    EXPORT
}