import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { ImageService } from 'src/app/service/images/images.service';

import type { AfterViewInit, ElementRef } from '@angular/core';

@Component({
    selector: 'app-reactive-camera',
    standalone: false,
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss']
})
export class ReactiveCameraComponent implements AfterViewInit {
    @ViewChild('imageTag') imageElement!: ElementRef<HTMLImageElement>;
    @Output() sendCameraFile: EventEmitter<File> = new EventEmitter<File>();

    public photoIsDisplayed = false;
    public cameraIsVisible = false;
    public cameraIsLoading = true;

    constructor(private imageService: ImageService) {}

    /**
     * Starts video capture from camera
     */
    ngAfterViewInit() {
        this.getStream();
        this.cameraIsLoading = false;    
    }// ();

    /**
     * Starts video capture from camera
     * @returns void
     */
    getStream() {
        this.photoIsDisplayed = false;
    
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.error('User Media API no soportada.');
            return;
        }
    
        const constraints: MediaStreamConstraints = {
            video: {
                facingMode: { ideal: 'environment' }
            },
            audio: false
        };
    
        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
                const mediaControl = document.querySelector('video') as HTMLVideoElement;
                if (mediaControl) {
                    mediaControl.srcObject = stream;
                    mediaControl.onloadeddata = () => {
                        mediaControl.play();
                    };
                }// if();
                return;
            })
            .catch((err) => {
                console.error('Error al obtener el stream:', err);
                if (err.name === 'NotAllowedError') {
                    alert('Permiso de cámara denegado. Habilítalo en la configuración del navegador.');
                } else if (err.name === 'NotFoundError') {
                    alert('No se encontró ninguna cámara conectada.');
                } else if (err.name === 'AbortError') {
                    alert('La cámara está en uso o no puede ser iniciada.');
                } else {
                    alert('Error: ' + err.message);
                }// if();
            });
    }// ();
    
    /**
     * Takes a photo from the recording
     * @returns true: the photo was taken without any problem | false: There was a problem when taking the photo
     */
    async takePhoto(): Promise<boolean> {    
        const videoElement = document.querySelector('video') as HTMLVideoElement;
    
        // Capture image from video
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const canvasContext = canvas.getContext('2d');
    
        if (!canvasContext) {
            console.error('⚠️ Error al obtener el contexto del canvas.');
            return false;
        }// if();
    
        // Convert image to base64 format
        canvasContext.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        const imageBase64 = canvas.toDataURL('image/png');    
        if (!imageBase64.startsWith('data:image/png;base64,')) {
            console.error('⚠️ Base64 generado no válido:', imageBase64);
            return false;
        }// if();
    
        // Makes sure the DOM is fully renderized before appending the image url
        requestAnimationFrame(() => {
            if (this.imageElement?.nativeElement) {
                this.imageElement.nativeElement.src = imageBase64;
                console.log('✅ Imagen asignada correctamente con requestAnimationFrame.');
            } else {
                console.warn('⚠️ No se encontró la etiqueta <img> para asignar la imagen.');
            }
        });        
        this.photoIsDisplayed = true;

        // Convert Base64 to file and emits it to the upload-images component to upload it to server
        try {
            const file = this.imageService.dataURLtoFile(imageBase64, 'photo.png');
            this.sendCameraFile.emit(file);
            return true;
        } catch (error) {
            console.error('❌ Error al convertir la imagen a archivo:', error);
            return false;
        }// try/catch();
    }// ();    
}// class;