import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SpeechRecognitionService } from './speech-recognition.service';

@Component({
    selector: 'app-reactive-dictado',
    standalone: false,
    templateUrl: './dictado.component.html',
    styleUrls: ['./dictado.component.scss'],
})

export class ReactiveDictadoComponent implements OnInit {
    @Input() transcriptionLabel = '';
    @Input() speechTranscription = '';
    @Output() sendTranscription: EventEmitter<string> = new EventEmitter<string>();
  
    public isRecording = false;

    constructor(public speechRecognitionService: SpeechRecognitionService) {}
  
    /**
     * Initializes speech recognition service
     */
    ngOnInit() {
        this.speechRecognitionService.init();
  
        this.speechRecognitionService.transcriptedSpeechObserver.subscribe((transcriptedSpeech: string) => {
            this.speechTranscription += transcriptedSpeech;
            this.sendTranscription.emit(this.speechTranscription);
        });
    }// ngOnInit();
  
    /**
     * Starts speech recognition service if supported by the browser
     * @returns void
     */
    public start() {
        if (!this.speechRecognitionService.recognition) {
            console.error('El reconocimiento de voz no está inicializado.');
            return;
        }

        this.speechRecognitionService.speechTranscripted = '';
        this.speechRecognitionService.transcriptionInfo = 'Escuchando...';
        this.speechRecognitionService.recognition.start();
        this.isRecording = true;
    }// ()
  
    /**
     * Stops speech recognition service
     * @returns void
     */
    public stop() {
        if (!this.speechRecognitionService.recognition) {
            console.error('El reconocimiento de voz no está inicializado.');
            return;
        }

        this.speechRecognitionService.recognition.stop();
        this.isRecording = false;
        this.speechRecognitionService.wordConcat();
    }// ()
  
    /**
     * Clears the transcripted text from the textarea 
     */
    public deleteTranscription() {
        this.speechTranscription = '';
        this.sendTranscription.emit(this.speechTranscription);
        this.isRecording = false;
    }// ()
}// class;