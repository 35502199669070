<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex; gap:10px; ">
        <h4>Datos del albaran</h4>
        <div *ngIf="loadingData">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
            </p-progressSpinner>
        </div>
    </div>
    
    <div class="row">
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="this.getType() === 2 ? 4 : 3"
            [fields]="form.formFieldsLugar">
        </app-form-builder>
    </div>
    <div class="row">
       
        <app-form-builder
            [model]="model"
            (formChanges)="form.formChanges($event); formChanges($event)"
            [distributionColumns]="4"
            [fields]="form.formFieldsTratamiento">
        </app-form-builder>
    </div>

    <div class="row w-50">
      <div style="display: flex; width: 25%; max-width: 40%; margin-top: 25px; ">
        <div>
          <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
          <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
          <input type="button" class="btn btn-success"
            style='border-radius: 5px;
              font-size: 1em;
              padding: 6px 12px;
              font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;'
           value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center justify-content-center" style="max-width: 25%">
        <span>Contabilizado</span>
        <input type="checkbox" [ngModel]="form.model.contabilizado" (change)="changeChecked('contabilizado', $event)"/> 
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center" style="max-width: 25%">
        <span>Enviado por Correo</span>
        <input type="checkbox" [ngModel]="form.model.enviado" (change)="changeChecked('enviado', $event)"/> 
      </div>
    </div>

   <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
