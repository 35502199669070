<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [filtering]="filtering"
            [cols]="cols"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            actionButtonText="Añadir Poda"
            (emitSearchText)="updateFooter()"
            (actionButtonClick)="add()">
            <ng-template #header>
                <div style="width: 100%; margin-right: 15px" class="d-flex justify-content-start align-items-center">
                    <div style="margin-left: 20px;">
                        <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;  background-color: #16A2B7; color: white">
                            <i class="fa fa-download"></i>
                            Exportar listado
                        </button>
                    </div>
                    <div style="margin-left: 10px; margin-right: 10px">
                        <div style="font-size: 0.7em" class="btn-group">
                            <button (click)="filtrarRealizados('Realizada', 'prioridad'); sum();"
                                [ngClass]="prioridad.selected === 'Realizada' ? 'btn btn-primary' : 'btn btn-light'" style="
                                                height: 28px;
                                                width: 40px;
                                                border: 1px solid grey;
                                                border-right: 1px solid grey">
                                SÍ
                            </button>
                            <button (click)="filtrarRealizados('No-Realizada', 'prioridad'); sum();"
                                [ngClass]="prioridad.selected === 'No-Realizada' ? 'btn btn-primary' : 'btn btn-light'" style="
                                                height: 28px;
                                                width: 40px;
                                                border: 1px solid grey;
                                                border-right: 1px solid grey">
                                NO
                            </button>
                        </div>
                    </div>
                    <span style="color: white; margin-top: -0px;">
                        Realizados
                    </span>
                    <div style="margin-left: 20px ;display: flex;">
                        <app-datepicker (sendFecha)="getFechaInicio($event)">
                        </app-datepicker>
                        <p style="color:white; margin-left: 10px;margin-bottom: 0;">F.Inicio</p>
                    </div>
                    <div style="margin-left: 20px ;display: flex;">
                        <app-datepicker (sendFecha)="getFechaFin($event)">
                        </app-datepicker>
                        <p style="color:white; margin-left: 10px;margin-bottom: 0;">F.Fin</p>
                    </div>
                
                </div>
            </ng-template>
        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [hideDelete]="hiddenButtonDelete" [showDuplicate]="true"
                        (duplicateRowButtonEvent)="duplicate(data.data)"
                        (editRowButtonEvent)="edit(data.data)"
                        (deleteRowButtonEvent)="show('delete', data.data)"
                        (showRowButtonEvent)="show('info', data.data)">
                </app-action-buttons>
            </td>

        </ng-template>
        <ng-template #footer let-col>
            <div style="font-size: 0.75em; text-align: left" *ngIf="(['cliente_nombre'].includes(col.field))">
              TOTAL:
            </div>
            <div *ngIf="(['horas_totales'].includes(col.field))"
              style="font-size: 0.75em; text-align: right; font-weight: bold; padding-right: 34px">
              {{this.totalHoras  || 0}}
            </div>
          </ng-template>

    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <div style="display: flex;justify-content: space-between;">
            <h4>Datos de podas</h4>
            <app-upload-images 
            [model]="model" 
            folder="poda" 
            tipo="list" 
            ></app-upload-images>
        </div>
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="2"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <app-form-builder
                [fields]="formFieldsSimple"
                [distributionColumns]="2"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h4>Arbolado</h4>
        <app-form-builder
                [fields]="formFieldsArbolado"
                [distributionColumns]="3"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h4>Setos/Arbustos</h4>              
        <h5>Vallas</h5> 
        <app-form-builder
                [fields]="formFieldsValla"
                [distributionColumns]="3"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h5>Palmeras</h5>
        <app-form-builder
            [fields]="formFieldsPalmeras"
            [distributionColumns]="3"
            [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <h5>Acuchillado Tronco</h5>
        <app-form-builder
            [fields]="formFieldsAcuchillado"
            [distributionColumns]="3"
            [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <app-form-builder
        [fields]="formFieldsTrabajadores"
        [distributionColumns]="3"
        [distributionOrder]="[3]"
        [model]="model">
        </app-form-builder>
        <div class="row">
            <div class="col-lg-6">
                <app-form-builder
                [model]="model"
                [distributionColumns]="1" 
                [fields]="formFieldsObs">
            </app-form-builder>  
            </div>
            <div class="col-lg-6">
                <div style="display: flex;justify-content: space-between;">
                    <app-upload-images 
                    [model]="model" 
                    folder="poda" 
                    tipo="list" 
                    ></app-upload-images>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <app-form-builder
                [model]="model"
                [distributionColumns]="1" 
                [fields]="formFieldsOrdenTrabajo">
            </app-form-builder>  
            </div>
            <div class="col-lg-6">
                <div style="display: flex;justify-content: space-between;">
                    <app-upload-images 
                    [model]="model" 
                    folder="poda" 
                    tipo="list"
                    nombre_imagen = 'image_after'  
                    ></app-upload-images>
                </div>
            </div>
        </div>
        <br>
      
        <div>
            <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                <p>Firma</p>
                <img [src]="serverUrl + 'images/mto_poda/'+this.model.signature_img " class="info-firma-img" alt="Firma">
            </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="2"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <app-form-builder
                [fields]="formFieldsSimple"
                [distributionColumns]="2"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h4>Arbolado</h4>
        <app-form-builder
                [fields]="formFieldsArbolado"
                [distributionColumns]="3"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h4>Setos/Arbustos</h4>              
        <h5>Vallas</h5> 
        <app-form-builder
                [fields]="formFieldsValla"
                [distributionColumns]="3"
                [distributionOrder]="distributionOrder"
                [model]="model">
        </app-form-builder>
        <h5>Palmeras</h5>
        <app-form-builder
            [fields]="formFieldsPalmeras"
            [distributionColumns]="3"
            [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <h5>Acuchillado Tronco</h5>
        <app-form-builder
            [fields]="formFieldsAcuchillado"
            [distributionColumns]="3"
            [distributionOrder]="distributionOrder"
            [model]="model">
        </app-form-builder>
        <app-form-builder
            [fields]="formFieldsTrabajadores"
            [distributionColumns]="3"
            [distributionOrder]="[3]"
            [model]="model">
        </app-form-builder>
        <div class="row">
            <div class="col-lg-6">
                <app-form-builder
                [model]="model"
                [distributionColumns]="1" 
                [fields]="formFieldsObs">
            </app-form-builder>  
            </div>
            <div class="col-lg-6">
                <div style="display: flex;justify-content: space-between;">
                    <app-upload-images 
                    [model]="model" 
                    folder="poda" 
                    tipo="list" 
                    ></app-upload-images>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <app-form-builder
                [model]="model"
                [distributionColumns]="1" 
                [fields]="formFieldsOrdenTrabajo">
            </app-form-builder>  
            </div>
            <div class="col-lg-6">
                <div style="display: flex;justify-content: space-between;">
                    <app-upload-images 
                    [model]="model" 
                    folder="poda" 
                    tipo="list"
                    nombre_imagen = 'image_after'  
                    ></app-upload-images>
                </div>
            </div>
        </div>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>