import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import { TareasAlquilerMaquinariaFormDefinition } from './form-control/form-definition';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { pool, buildParams, build } from 'src/app/common/classes/request-builder';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { FileUploadAnswer, Utils } from 'src/app/common/utils';
import { environment } from 'src/environments/environment';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import moment from 'moment';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import type { OnDestroy, OnInit } from '@angular/core';
import { TareaAlquilerMaquinariaModel } from 'src/app/models/tareas/tarea-alquiler-maquinaria.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-tareas-alquiler-maquinaria-form',
    standalone: false,
    templateUrl: './tareas-alquiler-maquinaria-form.component.html',
    styleUrls: ['./tareas-alquiler-maquinaria-form.component.scss']
})
export class TareasAlquilerMaquinariaFormComponent extends BaseForm<TareaAlquilerMaquinariaModel> implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TareaAlquilerMaquinariaModel>();

    public hasClients = environment.features.hasClients;
    public serverUrl = environment.serverUrl;
    public clienteName = environment.features.clienteName;
    public appName = environment.appName;

    public signatureFile: File = new File([], '');
    public form: TareasAlquilerMaquinariaFormDefinition = new TareasAlquilerMaquinariaFormDefinition();
    public formRequest = new AppFormRequest<TareaAlquilerMaquinariaModel>();
    public override model: TareaAlquilerMaquinariaModel = {};  

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public tareasApi: TareasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public override router: Router,
        private http: HttpClient) {
        super(
            route,
            router,
            dashboard,
            tareasApi.alquilerMaquinaria.PUT,
            tareasApi.alquilerMaquinaria.POST,
            'alquiler-maquinaria',
            'Actualizar Tarea Alquiler Maquinaria',
            'Realizar Tarea Alquiler Maquinaria',
            'Duplicar Tarea Alquiler Maquinaria'
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

        const fieldsToSend = (['id', 'id_usuario', 'imagen', 'image_after', 'signature_img']).concat(
            this.form.formFields.map(it => it.field ?? ''),
            this.form.formFieldsObs.map(it => it.field ?? ''),
            this.form.formFieldsSimple.map(it => it.field ?? ''),
            this.form.formFieldsTrabajadores.map(it => it.field ?? ''),
            this.form.formFieldsOrdenTrabajo.map(it => it.field ?? ''),
  
        );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        if (this.appName === 'donjardin') {
            fieldsToSend.push('id_ref_usuario');
        }
    
        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tareasApi.alquilerMaquinaria.GET)
            .setPostRequest(this.tareasApi.alquilerMaquinaria.POST)
            .setPutRequest(this.tareasApi.alquilerMaquinaria.PUT)    
            .setFormFields(this.form.formFields
                .concat(this.form.formFieldsObs)
                .concat(this.form.formFieldsSimple)
                .concat(this.form.formFieldsTrabajadores)
                .concat(this.form.formFieldsOrdenTrabajo)
            ).setFieldsToSend(fieldsToSend);

        this.formRequest.load();
        
        this.softInit(this.getType());       
        // Añadir fecha actual solo cuando se crea el registro 
        if ( this.getType() === FormRequestTypes.CREATE ){
            this.model.fecha = new Date();
        }
    }


    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {    
        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': +h, 'minutes': +m})
            .format('HH:mm');


        // console.log({str});
        return newTime;
    }
    getObservaciones( event: string ) {
        this.model.observaciones = event;
    }

    updateImageModel( event: string  ) {
        this.model.imagen = event;
    }

    updateAfterImageModel( event: string  ) {
        this.model.image_after = event;
    }
 
    getOrdenTrabajo( event: string ) {
        this.model.orden_trabajo = event;
    }

    getSignature( event: File ) {
        this.signatureFile = event;
    }
  
    updateFirma(event: string) {
        this.model.signature_img = event;  
        this.formRequest.update();
    }

    public formChanges(tag: string) {
        if (environment.features.showTareasMto){
            if (this.model.hora_inicio != null && this.model.hora_fin != null ) {
 
                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin.toString();
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model.horas_totales = diferencia;
   
            } else {
                this.model.horas_totales = '';
            }
      
        }
        if (tag === 'mantenimiento')
        {

            if ( TRUTHY_VALUES.includes(this.model[tag]?.toString() ?? '' )  ){
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Creación';
            
                    }
                });
            } else {
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Mantenimiento';
            
                    }
                });
            }

        }
        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
           
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en pool: ' + e);
            }
            );
        }
    }
  
    public async submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            return;
        }   

        const horaFin = moment(this.model.hora_fin, 'HH:mm');
        const horaInicio = moment(this.model.hora_inicio, 'HH:mm');
        const diferenciaEntreHoras = horaFin.diff(horaInicio);

        if (diferenciaEntreHoras < 0){
            this.requestButton.error = 'La hora de inicio es mayor que la de fin.';
            return;
        }

        if ( this.signatureFile != null ) {
            const formData = new FormData();
            formData.append('fileToUpload', this.signatureFile);
            formData.append('database', 'tareas_alquiler_maquinaria');
            formData.append('folder', 'alquiler_maquinaria');
            formData.append('field', 'signature_img');
            const signature = await firstValueFrom(
                this.http.post<FileUploadAnswer>( this.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData)
            );
            this.model.signature_img = signature.target_file.replace('../../images/alquiler_maquinaria/', '');
        }
    
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    private initFormData(): Promise<boolean> {
        return pool([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.clientesApi.clientesGET, this.form.clientes, {or: ['razon_social', 'nombre']}),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || ''), true )
        ], () => {
            if (this.getType() === FormRequestTypes.CREATE){
                this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
             
                    if (resp_fincas.length > 0 ) {
                        this.model.id_finca = resp_fincas[0]?.id ?? '';
                    }
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }
            this.formRequest.update();
       
        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
      
   
      
            let userInfo = StorageManager.getUser();
            if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE){
                this.model.ids_trabajadores = userInfo.id.toString();
            }

            if (environment.features.showTareasMto){
                userInfo = StorageManager.getUser();
                if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE){
                    this.model.ids_trabajadores = userInfo.id.toString();
                }

                if (this.getType() === FormRequestTypes.DUPLICATE) {
                    delete this.model.hora_fin;
                    delete this.model.hora_inicio;
                    delete this.model.horas_totales;
                }
            }
            if (environment.features.showTareasMto) {
                if (this.getType() === FormRequestTypes.EDIT ){
                    this.tareasApi.alquilerMaquinaria.GET.response( (resp) => {
                        if ( resp[0]?.hora_fin == null || (resp[0].hora_fin as string).length === 0 ){
                            if ( StorageManager.getUser().rol === 'user'){
                                const date = new Date;
                                this.model.hora_fin = date;
                            }
                        }
                    });
                }

                if (this.getType() === FormRequestTypes.DUPLICATE || this.getType() === FormRequestTypes.CREATE){
    
                    if ( StorageManager.getUser().rol === 'user'){
                        const date = new Date;
                        this.model.hora_fin = date;
                    }
     
                }
            }

            if ( environment.features.showTareasMto ) {
                if ( StorageManager.getUser().rol === 'user'){
                    this.model.prioridad = 'Realizada';
                }
            }   
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            this.model.fecha = this.model.fecha instanceof Date
                ? Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';


            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
            clientIdFromFinca ? clientIdFromFinca :
                clientIdFromForm ? clientIdFromForm :
                    clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;

            if ( this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE){
                this.model.id_usuario = userId ? userId : '';
                const fechaActual = new Date;
                this.model.hora_fin = fechaActual;
            }// if();
        
            this.model.mantenimiento = this.model.mantenimiento === true ? 1 : 0;

            if ( this.model.hora_inicio != null ){
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                if (!this.model.hora_inicio.includes(':')) {
                    this.model.hora_inicio = null;
                }
            }

            if ( this.model.hora_fin != null ) {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                this.model.hora_fin = horaFin;
                if (!this.model.hora_fin.includes(':')) {
                    this.model.hora_fin = null;
                }
            }
        
            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  
}
