import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {BaseListView} from '../../../view/base-listview';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {Filtering} from '../../../service/filtering/filtering';
import {Utils} from '../../utils';
import { Table, TablePageEvent } from 'primeng/table';
import { DashboardService } from '../../../../app/service/events/dashboard.service';
import { environment } from '../../../../environments/environment';
import {StorageManager} from '../../../common/storage-manager.class';

import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import type {SortEvent, TreeNode, TreeTableNode} from 'primeng/api';
import { NgForOfContext } from '@angular/common';
import { ColInterface } from './col.interface';
import { ColStylesInterface } from './col-styles.interface';
import { TreeTableSortEvent } from 'primeng/treetable';

@Component({
    selector: 'app-common-list-table',
    standalone: false,
    templateUrl: './common-list-table.component.html',
    styleUrls: ['./common-list-table.component.scss']
})
export class CommonListTableComponent<T> extends BaseListView<T> implements OnInit, OnChanges {

    private static oldCurrentTitle = '';

    @Input()
    override request = new RequestHandler<any>();
    @Input()
    override searchOn: string[] = [];
    @Input()
    override searchPriority: string[] = [];
    @Input() enableFilter: (() => undefined) | undefined = undefined;

    @Input() minWidth = 750;
    @Input() maxWidth = 0;
    @Input() minHeight = 0;
    @Input() cols: ColInterface[] = [];
    @Input() actionButtonText = '';
    @Input() searchValue: {term: string} = {term: ''};

    @Input() override filtering = new Filtering<T>();
    @Input() selection: string | never[] = '';
    @Input() selectionMode: 'single' | 'multiple' | null | undefined = null;

    @Input()
        hideSearch = false;
    @Input()
        hidePaginator = false;
    @Input()
        showExportCsv = false;
    @Input()
        customExportCsv = false;
    @Input() first = 0;
    @Input() visible = true;
    @Input() showStockReto = false;

    @Input() editButtonsPosition = 'top';

    @Output() actionButtonClick: EventEmitter<object> = new EventEmitter<object>();
    @Output() saveButtonClick: EventEmitter<object> = new EventEmitter<object>();
    @Output() undoButtonClick: EventEmitter<object> = new EventEmitter<object>();
    @Output() emitSearchText: EventEmitter<string> = new EventEmitter<string>();
    @Output() filterPressed: EventEmitter<object> = new EventEmitter<object>();
    @Output() rowClick: EventEmitter<T> = new EventEmitter<T>();
    @Output() fieldEdit: EventEmitter<{ event: Event; data: TreeNode<T>; from: string; }> = 
        new EventEmitter<{ event: Event; data: TreeNode<T>; from: string; }>();
    @Output() exportCsvClick: EventEmitter<object> = new EventEmitter<object>();
    @Output() exportRetoClick: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ContentChild('thEnd', {read: TemplateRef, static: false}) thEnd: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('thStart', {read: TemplateRef, static: false}) thStart: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('tdEnd', {read: TemplateRef, static: false}) tdEnd: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('tdStart', {read: TemplateRef, static: false}) tdStart: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('header', {read: TemplateRef, static: false}) header: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('caption', {read: TemplateRef, static: false}) caption: TemplateRef<NgForOfContext<object, object[]>> | undefined;
    @ContentChild('footer', {read: TemplateRef, static: false}) footer: TemplateRef<NgForOfContext<object, object[]>> | undefined;

    @ViewChild('pTable', { read: Table, static: true })
        pTable: Table | undefined;

    public appName = environment.appName;
    public rol = StorageManager.getUser().rol;
    public selected = {} as T;
    public hasEditableFields = false;
    public currentTitle = '';

    public currentPage = {} as TablePageEvent;

    public selectedNode!: TreeNode | null;

    private cachedNodes: TreeNode<T>[] = [];
    private lastFilteringValue = '';


    constructor(private dashboard: DashboardService) {
        super();
    }

    
    get treeNodes(): TreeNode<T>[] {
        const currentFilteringValue = JSON.stringify(this.filtering.value);
    
        if (!this.cachedNodes.length || this.lastFilteringValue !== currentFilteringValue) {
            this.cachedNodes = this.transformToTreeNodes(this.filtering.value);
            this.lastFilteringValue = currentFilteringValue;
        }
    
        return this.cachedNodes;
    }
    

    @Input() customSort: ((event: TreeTableSortEvent) => void) = () => {};

    @Input() globalVisualTransform: (value: string, col: ColInterface, row: TreeNode<T>) => string = () => '';
    @Input() globalConditionalStyle: (
        value: string, 
        col: ColInterface, 
        colGroup: ColInterface[], 
        row: TreeNode<T>
    ) => ColStylesInterface = () => ({});
    

    ngOnChanges(changes: SimpleChanges) {
        if ((changes || {})['searchValue']) {
            this.setGlobalContentFilter(changes['searchValue']?.currentValue.term);
            this.searchValue = (this.searchValue || {term: ''});
        }

        if ((changes || {})['cols']) {
            this.cols = this.cols.filter((it: ColInterface) => it && it.visible !== false);
        }
    }


    ngOnInit() {
        this.currentTitle = this.appName + '_' + this.dashboard.headerTitle;
        this.initRequest(this.request);
        this.selection = (this.selection || []);
        this.searchValue = (this.searchValue || {term: ''});
        this.customSort = this.customSort || this.defaultSort;
        this.configureOnPageChange();

        if (this.checkOldTitle(this.currentTitle)) {
            this.removeTempData();
        } else {
            this.getTableState(this.request);
        }
    
        // this.dashboard.menuItemChanges$.subscribe(item => {
        //     if (item !== title) {
        //         this.removeTempData();
        //     }
        // });
        
        if (this.cols) {
            this.cols.forEach((col: ColInterface) => {
                switch (col.filter) {
                case 'dropdown':
                    this.setDropdownSelectOn('', col.field);
                    break;
                case 'multiselect':
                    this.setMultiSelectOn('', col.field);
                    break;
                case 'calendar':
                    this.setDateRangeOn();
                    break;
                }
                if (col.editable) {
                    this.hasEditableFields = true;
                }
            });
        }
    }

    
    transformToTreeNodes(data: TreeNode<T>[] | void): TreeNode<T>[] {
        if (data && Array.isArray(data)) {
            return data.map((item) => ({
                data: item as T, children: [], leaf: true
            }));
        } else {
            return [];
        }
    }

    checkOldTitle( new_title: string) {
        if (CommonListTableComponent.oldCurrentTitle == null || CommonListTableComponent.oldCurrentTitle.length === 0) {
            CommonListTableComponent.oldCurrentTitle = new_title;
            return false;
        }
        if (CommonListTableComponent.oldCurrentTitle.localeCompare(new_title) === 0) {
            return false;
        }
        if (CommonListTableComponent.oldCurrentTitle.localeCompare(new_title) !== 0) {
            if ( new_title.toLowerCase().includes('edit') ){
                return false;
            }
            CommonListTableComponent.oldCurrentTitle = new_title;
            return true;
        }
        return false;

    }
    
    exportReto(){
        this.exportRetoClick.emit(true);
    }

    showAddButton(){
        if ( StorageManager.getUser().rol === 'demo') {
            return false;
        }
        return true;
    }

    nodeSelect(event: TreeTableNode){
        this.rowClick.emit(event.node as T);
    }
    
    public getTableState(request: RequestHandler<T>) {
       
        if ( this.currentTitle.includes('Editar Cliente') ) {
            this.currentTitle = this.currentTitle.replace('Editar Cliente', 'Clientes');
            //CommonListTableComponent.oldCurrentTitle = this.currentTitle;
        }
        const localSearchValue = localStorage.getItem(this.currentTitle + '_TEMP_SEARCH_TEXT') || '';
        const localCurrentPage = JSON.parse(localStorage.getItem(this.currentTitle + '_TEMP_PAGE_NUMBER') ?? '{}') || {};

        if (request.value) {
            if (localCurrentPage && localCurrentPage.first && this.pTable) {
                this.pTable.first = localCurrentPage.first;
            }
            if (localSearchValue) {
                this.searchValue = {'term': localSearchValue};
                this.filters.global = localSearchValue;
            }

        } else {
            request.response(() => {
                if (localCurrentPage && localCurrentPage.first && this.pTable) {
                    this.pTable.first = localCurrentPage.first;
                }
                if (localSearchValue) {
                    this.searchValue = {'term': localSearchValue};
                    this.filters.global = localSearchValue;
                }

            });
        }

        this.setGlobalContentFilter(localSearchValue);
    }

    public setTableState() {
        if (this.searchValue && this.searchValue.term) {
            localStorage.setItem(this.currentTitle + '_TEMP_SEARCH_TEXT', this.searchValue.term);
        }
        if (this.currentPage) {
            localStorage.setItem(this.currentTitle + '_TEMP_PAGE_NUMBER', JSON.stringify(this.currentPage));
        } else if (this.pTable && this.pTable.first !== 0) {
            localStorage.setItem(this.currentTitle + '_TEMP_PAGE_NUMBER', JSON.stringify({
                first: this.pTable.first,
                rows: this.pTable.rows
            }));
        }
    }

    public removeTempData() {
        localStorage.removeItem(this.currentTitle + '_TEMP_SEARCH_TEXT');
        localStorage.removeItem(this.currentTitle + '_TEMP_PAGE_NUMBER');
    }

    public setSearchText(value: string | null) {
        if ( value == null || (value as string ).length === 0 ){
            this.removeTempData();
        } else {
            this.emitSearchText.emit(value);
            
            if (this.pTable) {
                this.pTable.first = 0;
            }
            
            localStorage.removeItem(this.currentTitle + '_TEMP_PAGE_NUMBER');
            this.setTableState();
        }
        
    }

    public editField(event: Event, data: TreeNode<T>, from: string) {
        this.fieldEdit.emit({event, data, from});
    }

    public exportCsv() {
        // Si el usuario ha definido una función personalizada para procesar los datos, simplemente los devuelve.
        // Si no, les aplica el proceso genérico para convertir a CSV: descargar la tabla tal cual está.
        if (this.customExportCsv) {
            
            this.exportCsvClick.emit({
                cols: this.cols,
                value: this.filtering.value
            });
            // return;
        }
        
        const charset = 'windows-1252';
        let csv = 
        'data:text/csv;charset=' + 
        charset + 
        ',\ufeff' + 
        (this.cols || []).map((it: { header: string; }) => it.header).join(';') + 
        '\n'; 
        
        (this.filtering.value || []).forEach(row => {
            this.cols.forEach((col: ColInterface) => {
                let str: string = this.processCellVisualTransform(row, col) ?? '';
                if ([undefined, 'undefined', null, 'null', NaN, 'NaN'].includes(str)) {
                    str = '';
                } else {
                    str = str.toString().replace('#', '');
                }
                csv += '"' + (str || '').replace(/"/g, '\\"') + '";';                
                //csv += str + ';';
            });
            csv = csv.slice(0, -1) + '\n';
        });

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;
        csvLink.download = this.currentTitle + '_exportado.csv';
        csvLink.href = encodeURI(csv);
        csvLink.click();
    }

    public processCellVisualTransform(data: TreeNode<T>, col: ColInterface) {
        let finalTransform;
        //AQUI RECOGIDA DE FIELDS PARA COLUMNA p-table
       
        if (col.visualTransform) {
            finalTransform = col.visualTransform;
        } else if (this.globalVisualTransform) {
            if (data.data) {
                finalTransform = this.globalVisualTransform((data.data as Record<string,string>)[col.field] ?? '', col, data);
            } else {
                finalTransform = this.globalVisualTransform((data as Record<string,string>)[col.field] ?? '', col, data);
            }
        } else {
            finalTransform = (data.data as Record<string,string>)[col.field];
        }
    
        return finalTransform;
    }

    public processCellConditionalStyle(data: TreeNode<T>, col: ColInterface): ColStylesInterface {
        let finalStyle: ColStylesInterface = {};

        if (col.style) {
            finalStyle = col.style;
        } else if (this.globalConditionalStyle) {
            finalStyle = this.globalConditionalStyle((data.data as Record<string,string>)[col.field ?? ''] ?? '', col, this.cols, data);

            if (finalStyle && finalStyle.style) {
                finalStyle = finalStyle.style;
            }
        }

        return finalStyle ? finalStyle : {};
    }

    public processRowConditionalStyle(data: TreeNode<T>) {
        let finalStyle;

        for (const columna of this.cols) {
            const col = columna;
            if (col.rowStyle) {
                finalStyle = col.rowStyle;
                break;
            } else if (this.globalConditionalStyle) {
                finalStyle = this.globalConditionalStyle((data.data as Record<string,string>)[col.field] ?? '', col, this.cols, data);

                if (finalStyle && finalStyle.rowStyle) {
                    finalStyle = finalStyle.rowStyle;
                    break;
                } else {
                    finalStyle = null;
                }
            }
        }

        return finalStyle ? finalStyle : {};
    }

    public defaultSort(event: SortEvent) {
        (event.data || []).sort((data1, data2) => {;
            let value1 = data1[event.field ?? 0];
            let value2 = data2[event.field ?? 0];
            let result = 0;

            const auxValue1 = Utils.toDate(data1[event.field ?? 0]);
            const auxValue2 = Utils.toDate(data2[event.field ?? 0]);

            if (auxValue1 && auxValue2) {
                value1 = auxValue1;
                value2 = auxValue2;
            } else if (Utils.isNumber(value1) && Utils.isNumber(value2)) {
                value1 = parseFloat(value1);
                value2 = parseFloat(value2);
            }

            if (value1 == null && value2 != null) {
                result = -1;
            } else if (value1 != null && value2 == null) {
                result = 1;
            } else if (value1 == null && value2 == null) {
                result = 0;
            } else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }

            return ((event.order ?? 0) * result);
        });
    }

    // public defaultSort(event: TreeTableSortEvent) {
    //     const field = event.field ?? ''; // Asignar un valor predeterminado si `field` es undefined
    //     const order = event.order ?? 1; // Asignar un valor predeterminado (1 para ascendente) si `order` es undefined
    //     const data = event.data ?? []; // Asignar un array vacío si `data` es undefined
    
    //     data.sort((node1, node2) => {
    //         const value1 = node1.data[field];
    //         const value2 = node2.data[field];
    //         let result = 0;
    
    //         if (value1 == null && value2 != null) {
    //             result = -1;
    //         } else if (value1 != null && value2 == null) {
    //             result = 1;
    //         } else if (value1 == null && value2 == null) {
    //             result = 0;
    //         } else if (typeof value1 === 'string' && typeof value2 === 'string') {
    //             result = value1.localeCompare(value2);
    //         } else {
    //             result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
    //         }
    
    //         return result * order; // Multiplica por `order` para aplicar ascendente/descendente
    //     });
    // }    

    private configureOnPageChange() {
        if (this.pTable) {
            this.pTable.onPageChange = (event => {
                this.currentPage = event;
    
                if (this.pTable) {
                    this.pTable.first = event.first;
                    this.pTable.rows = event.rows;
                    if (this.pTable.lazy) {
                        this.pTable.onLazyLoad.emit(this.pTable.createLazyLoadMetadata());
                    }
                    this.pTable.onPage.emit({
                        first: this.pTable.first,
                        rows: this.pTable.rows
                    });
                    this.pTable.tableService.onValueChange(this.pTable.value);
                }
            });   
        }
    }
}
