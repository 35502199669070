import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { environment } from '../../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { Filtering } from 'src/app/service/filtering/filtering';
import { list } from 'src/app/common/classes/request-builder';
export class AlbaranesFormDefinition {

  public appName = environment.appName;
  public lastFieldChanged: string = '';

  public requestType: any;
  public model: any = {};

  public chofers: TypeAheadInterface = list();
  public clientes: TypeAheadInterface = list();
  public obras: TypeAheadInterface = list();
  public maquinarias: TypeAheadInterface = list();
  public concepto1: TypeAheadInterface = list();
  public concepto2: TypeAheadInterface = list();
  public concepto3: TypeAheadInterface = list();
  public concepto4: TypeAheadInterface = list();



  public formFieldsLugar: ItemInterface<any>[] = [
    {
      field: 'albaran_id',
      label: 'Albarán',
      inputType: { type: InputType.EDIT_TEXT },
      required: true
    },
    {
      field: 'chofer',
      label: 'Chofer',
      inputType: { type: InputType.DROPDOWN_SEARCH },
      values: this.chofers,
      valuePrimaryKey: 'id',
      required: true,
    },
    {
      field: 'fecha',
      label: 'Fecha',
      inputType: { type: InputType.CALENDAR },
      required: true
    },
    {
      field: 'cliente',
      label: 'Cliente',
      values: this.clientes,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
      required: true,
    },
    {
      field: 'direccion',
      label: 'Dirección',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'cif',
      label: 'C.I.F.',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'poblacion',
      label: 'Población',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'cp',
      label: 'C.P.',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'telefono',
      label: 'Teléfono',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'correo',
      label: 'Correo',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'obra',
      label: 'Obra',
      values: this.obras,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
    },
    {
      field: 'maquinaria',
      label: 'Maquinaria',
      values: this.maquinarias,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
    },
  ];

  public formFieldsTratamiento: ItemInterface<any>[] = [
    {
      field: 'cantidad1',
      label: 'Cantidad',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'concepto1',
      label: 'Concepto',
      values: this.concepto1,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },

    },
    {
      field: 'precio1',
      label: 'Precio',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'total1',
      label: 'Total',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'cantidad2',
      label: '',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'concepto2',
      label: '',
      values: this.concepto2,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
    },
    {
      field: 'precio2',
      label: '',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'total2',
      label: '',
      inputType: { type: InputType.EDIT_TEXT },
    },
    {
      field: 'cantidad3',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'concepto3',
      values: this.concepto3,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },
    },
    {
      field: 'precio3',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'total3',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'cantidad4',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'concepto4',
      label: '',
      values: this.concepto4,
      valuePrimaryKey: 'id',
      inputType: { type: InputType.DROPDOWN_SEARCH },

    },
    {
      field: 'precio4',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'total4',
      label: '',
      inputType: { type: InputType.EDIT_NUMERIC },
    },
    {
      field: 'trabajos_realizados',
      label: 'Trabajos Realizados',
      inputType: { type: InputType.EDIT_TEXTAREA },
    },
    {
      field: 'conforme',
      label: 'Conforme',
      inputType: { type: InputType.EDIT_TEXTAREA },
    },
    {
      field: 'image',
    },
    {
      field: 'contabilizado',
    },
    {
      field: 'enviado',
    }
  ];

  public formChanges(tag: string) {
    this.lastFieldChanged = tag;
  }
}

interface TypeAheadInterface {
  selected: any;
  values: any[];
  filtered: any[];
  filterCallback?: Filtering<any>;
}
