export class FormRequest {
    public state = '';

    private events: Record<FormPhase, Record<EventPhase, EventCallback[]>> = {
        load: { before: [], on: [], after: [] },
        update: { before: [], on: [], after: [] },
        send: { before: [], on: [], after: [] },
        finish: { before: [], on: [], after: [] }
    };

    constructor() {}

    /**
     * Adds events to the corresponding phase and type
     * @param phase 
     * @param type 
     * @param callback 
     */
    public addEvent(phase: FormPhase, type: EventPhase, callback: EventCallback) {
        this.events[phase][type].push(callback);
    }// ();


    /**
     * Executes a complete phase (before → on → after)
     * @param phase 
     * @returns 
     */
    public async executePhase(phase: FormPhase) {
        try {
            this.state = `${phase}-before`;
            if (!(await this.executeQueue(this.events[phase].before))) {
                return;
            }// if();

            this.state = `${phase}-on`;
            if (!(await this.executeQueue(this.events[phase].on))) {
                return;
            }// if();

            this.state = `${phase}-after`;
            await this.executeQueue(this.events[phase].after);
        } catch (error) {
            console.error(`FormRequest @ Processing ${phase.toUpperCase()} Stack:`, error);
        }// try/catch()
    }// ();


    /**
     * Executes a queue of events sequentially
     * @param queue 
     * @returns true: if all events are executed successfully | false: if one of the events is not executed successfully
     */
    private async executeQueue(queue: EventCallback[]): Promise<boolean> {
        for (const callback of queue) {
            const result = await new Promise<boolean | null>(callback);

            if (result === false) {
                return false;
            }// if();
        }// for();

        return true;
    }// ();
}// class;


export enum FormPhase {
    LOAD = 'load',
    UPDATE = 'update',
    SEND = 'send',
    FINISH = 'finish'
}

export enum EventPhase {
    BEFORE = 'before',
    ON = 'on',
    AFTER = 'after'
}

export enum FormRequestTypes {
    CREATE, 
    EDIT, 
    DUPLICATE
}

type EventCallback = (resolve: (arg: boolean | null) => void) => void;