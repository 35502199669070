import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {TrabajadoresApiService} from '../../../../service/api/trabajadores-api.service';
import {BaseView} from '../../../base-view';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Filtering } from 'src/app/service/filtering/filtering';
import moment from 'moment';
import { Utils } from 'src/app/common/utils';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-maquinaria',
    standalone: false,
    templateUrl: './maquinaria.component.html',
    styleUrls: ['./maquinaria.component.scss']
})
export class MaquinariaComponent extends BaseView<MaquinariaModel> implements OnInit {

    public currentUser = StorageManager.getUser();

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;

    public serverUrl = environment.serverUrl;
    public showMaquinariaMenu = environment.features.showMaquinariaMenu;
    public showMaquinariaGps = environment.features.showMaquinariaGps;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public showMaquinariaMostrarTodos = environment.features.showMaquinariaMostrarTodos
        ? this.currentUser.rol === 'admin'
        : false;
    public showMaquinariaClienteAsignado = environment.features.showMaquinariaClienteAsignado;
    public showMaquinariaPropietario = environment.features.showMaquinariaPropietario;
    public clienteName = environment.features.clienteName;
    public titleActivo = environment.features.titleActivoMaqTra;

    public filter: Filtering<MaquinariaModel> = new Filtering<MaquinariaModel>();
    public showAll = false;
    public getRequest: RequestHandler<MaquinariaModel[]> = this.trabajadoresApi.maquinariaGET;
    public deleteRequest: RequestHandler<MaquinariaModel> = this.trabajadoresApi.maquinariaDELETE;
    public pathToForm = 'maquinaria-form';
    public menuItemId = 'maquinaria';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public sub$: Subscription = new Subscription;
    public clientes: ClienteModel[] = [];
    
    public override canShow: Record<string, boolean> = {};
    public override model: MaquinariaModel = {};

    public imagenAdjunto: string | null = null;
    public precioHora = environment.features.precioHora;


    public formFields: ItemInterface<object>[] = [
        {
            label: 'Nombre', 
            field: 'nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Tipo', 
            field: 'tipo', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Capacidad (Litros)', 
            field: 'capacidad', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'ROMA', 
            field: 'roma', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: this.appName === 'innovia' ? this.clienteName : 'Propietario', 
            field: 'cliente', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showMaquinariaClienteAsignado
        },
        {
            label: 'Fecha Adquisición', 
            field: 'fecha_adquisicion', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Fecha Última Inspección ITEAF', 
            field: 'fecha_ultima_inspeccion', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Fecha Caducidad ITEAF',
            field: 'fecha_caducidad',
            inputType: {type: InputType.ONLY_TEXT},
            style: 'color: black'
        },
        {
            label: 'Identificador GPS', 
            field: 'gps', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showMaquinariaGps
        },
        {
            label: 'IMEI', 
            field: 'imei', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showMaquinariaGps
        },
        {
            label: 'Boquillas', 
            field: 'boquillas_nombres', 
            inputType: {type: InputType.TEXT}, 
            visible: (this.appName === 'manezylozano')
        },
        {
            label: 'Precio x hora', 
            field: 'precio_hora', 
            inputType: {type: InputType.TEXT}, 
            visible: this.precioHora
        },
        {
            label: 'Proveedor',
            field: 'proveedor',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'sirga',
        },
        {
            label: this.titleActivo, 
            field: 'activo', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showAplicadoresActivo
        },
        {
            label: 'Recibir mail tratamientos', 
            field: 'mail_trat', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'manezylozano' 
        },
        {
            hasLabel: false, 
            field: 'adjunto', 
            inputType: {type: InputType.EMPTY}
        }
    ];

    public cols: ColInterface[] = [
        {header: 'Nombre', field: 'nombre'},
        {header: 'Tipo', field: 'tipo'},
        {header: 'Capacidad\xa0(l)', field: 'capacidad'},
        {header: 'ROMA', field: 'roma'},
        {header: 'Adquisición ', field: 'fecha_adquisicion'},
        {header: 'Última\xa0inspección\xa0ITEAF', field: 'fecha_ultima_inspeccion'},
        {header: 'Caducidad\xa0ITEAF', field: 'fecha_caducidad'},
        {header: 'Coste/hora (€)', field: 'precio_hora' , visible: this.precioHora},
        {header: this.titleActivo, field: 'activo', width: '60px'},
        {
            header: this.appName === 'innovia' ? this.clienteName : 'Propietario', 
            field: 'cliente', 
            visible: this.showMaquinariaClienteAsignado
        },
        {header: 'Usuario', field: 'usuario_nombre', visible: this.showMaquinariaMostrarTodos},
    ];

    constructor(
        public trabajadoresApi: TrabajadoresApiService,
        public clientesApi: ClientesApiService,
        private dashboard: DashboardService,
        private router: Router
    ) {
        super(dashboard, trabajadoresApi.maquinariaGET, trabajadoresApi.maquinariaDELETE);
    }

    ngOnInit() {
        this.showAll = Utils.initShowAll('showAllmaquinarias');
        this.initFilter();
        this.getClientes();
        if (this.showMaquinariaMenu) {
            this.dashboard.setMenuItem(this.menuItemId);
        } else {
            this.dashboard.setSubmenuItem(this.menuItemId);
        }
    }

    public changeShowAll(){
        sessionStorage.setItem('showAllmaquinarias', this.showAll === true ? 'true' : 'false' );
    }    

    public getClientes() {
        this.clientesApi.clientesGET.safePerform();
        this.clientesApi.clientesGET.response(clientes => {
            this.clientes = clientes;
        });
    }

    public add() {
        if (this.filterAplicadoresByClient && !StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-maquinaria']);
        }
    }

    public edit(data: MaquinariaModel) {
        this.router.navigate(['dashboard', 'editar-maquinaria', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: MaquinariaModel) {
        super.show(modal, data);

        this.model.activo = [1, '1', true, 'true'].includes(this.model.activo ?? '');

        if ( this.appName === 'manezylozano' ) {
            this.model.mail_trat = [1, '1', true, 'true'].includes(this.model.mail_trat ?? '');
        }
        
        this.getVisitImages();
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public verAdjunto() {
        if (this.model.adjunto) {
            const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
            myWindow?.location.assign(environment.serverUrl + 'fotos_maquinaria/' + this.model.adjunto);
            myWindow?.focus();
        } else {
            alert('Esta máquina no tiene fotos.');
        }
    }

    public globalConditionalStyle(
        value: string, col: ColInterface,  
        _colGroup: ColInterface[], 
        row: TreeNode<MaquinariaModel>
    ): ColStylesInterface {
        const maquinaria = row.data as MaquinariaModel;
        if (col.field === 'activo') {
            return {
                textAlign: 'center'
            };
        }
        if (col.field === 'nombre') {
            return {textAlign: 'left'};
        }
        
  
        if (col.field === 'fecha_caducidad') {
            
            const fechaUltimaInspeccion = maquinaria.fecha_ultima_inspeccion ?? '';
            let tiempoAntes = moment();
            
            if (this.appName === 'prodalbar') {
                tiempoAntes = moment(value, 'DD/MM/YYYY').subtract(1, 'years');
            } else {
                tiempoAntes = moment(value, 'DD/MM/YYYY').subtract(3, 'months');
            }
            
            const hoy = moment(moment().format('MM-DD-YYYY' + ' 00:00:00'));

            if (hoy.isAfter(moment(value, 'DD/MM/YYYY')))  {

                if (!fechaUltimaInspeccion) {
                    return {
                        rowStyle: {
                            backgroundColor: '#f44242',
                            textAlign: 'center'
                        },  
                    };
                } else {
                    let periodoAnyos = 0;
                    periodoAnyos = (moment(fechaUltimaInspeccion, 'DD/MM/YYYY').year() < 2020) ? 5 : 3;
                    const fecha = moment(fechaUltimaInspeccion, 'DD/MM/YYYY').add(periodoAnyos, 'years');
                    
                    tiempoAntes = moment();
                    
                    if (this.appName === 'prodalbar') {
                        tiempoAntes = moment(fechaUltimaInspeccion, 'DD/MM/YYYY').add(periodoAnyos, 'years').subtract(1, 'years');
                    } else {
                        tiempoAntes = moment(fechaUltimaInspeccion, 'DD/MM/YYYY').add(periodoAnyos, 'years').subtract(3, 'months');
                    }
                    if (hoy.isSameOrAfter(tiempoAntes) && hoy.isSameOrBefore(fecha)) {
                        return {
                            rowStyle: {
                                backgroundColor: '#ff9a02',
                                textAlign: 'center'
                            }
                        };
                    } else if (hoy.isAfter(fecha)) {
                        return {
                            rowStyle: {
                                backgroundColor: '#f44242',
                                textAlign: 'center'
                            },  
                        };
                    }
                }
            } else if (hoy.isSameOrAfter(tiempoAntes) && hoy.isSameOrBefore(moment(value, 'DD/MM/YYYY'))) {
                return {
                    rowStyle: {
                        backgroundColor: '#ff9a02',
                        textAlign: 'center'
                    }
                };
            }
            return {};
        }

        if (Utils.isNumber(value?.toString() ?? '') || col.field === 'capacidad' || col.field === 'roma' ) {
            return {
                textAlign: 'right',
                paddingRight: '40px'
                
            };
        }
        return{ textAlign: 'center'};
    }


    public globalVisualTransform(value: string | number | boolean, col: ColInterface): string {
        console.log('Hola');
        console.log(col.field);
        if (col.field === 'activo') {
            console.log(value);
            return ([1, '1', true, 'true', 'Sí'].includes(value)) ? 'Sí' : 'No';
        } else {
            return value.toString();
        }
    }

    public getVisitImages() {
        if (this.model.adjunto) {
            fetch(environment.serverUrl + 'fotos_maquinaria/' + (this.model.adjunto || {}))
                .then((r) => {
                    if (r.status === 200) {
                        this.imagenAdjunto = environment.serverUrl + 'fotos_maquinaria/' + (this.model.adjunto  || {});
                    }
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        } else {
            this.imagenAdjunto = null;
        }
    }

    private initFilter() {
        this.filter.addFilter((data: TreeNode<MaquinariaModel>[] | void) => (data || [])
            .filter((it: TreeNode<MaquinariaModel>) => it)
            .filter((it: TreeNode<MaquinariaModel>) => {
                const maquinaria = it as MaquinariaModel;

                if (this.currentUser.rol === 'user' && this.appName === 'manezylozano') {
                    return this.currentUser.id === maquinaria.id_usuario;
                } 
                else if (this.showMaquinariaMostrarTodos && !this.showAll) {
                    return this.currentUser.id === maquinaria.id_usuario;
                }
                else if (this.appName === 'innovia'  && this.currentUser.rol === 'user'){
                    return this.currentUser.id_cliente === maquinaria.id_cliente;
                };
                return true;
            })
        );
    }
}
