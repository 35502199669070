import { Component } from '@angular/core';
import { BaseView } from '../../../base-view';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import { Filtering } from 'src/app/service/filtering/filtering';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import { TareasApiService } from '../../../../service/api/tareas-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { Router } from '@angular/router';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { Utils } from 'src/app/common/utils';
import moment from 'moment';
import { environment } from 'src/environments/environment';

import type { OnInit, OnDestroy } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { FitoLocalesModel } from 'src/app/models/tareas/fito-locales.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-fito-locales',
    standalone: false,
    templateUrl: './fito-locales.component.html',
    styleUrls: ['./fito-locales.component.scss']
})
export class FitoLocalesComponent extends BaseView<FitoLocalesModel> implements OnInit, OnDestroy {

    public getRequest: RequestHandler<FitoLocalesModel[]> = this.tareasApi.fitoLocales.GET;
    public deleteRequest: RequestHandler<FitoLocalesModel> = this.tareasApi.fitoLocales.DELETE;

    public override filtering: Filtering<FitoLocalesModel> = new Filtering<FitoLocalesModel>(); 
  
    public distributionOrder = [3];  

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: FitoLocalesModel = {};  

    public hasClients = environment.features.hasClients;
    public showTareasFincas = environment.features.showTareasFincas;
    public fincaName = environment.features.fincaName;  

    public cols: ColInterface[] = [
        {field: 'id', header: 'Nº\xa0Ficha', filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},  
        {field: 'finca', header: 'Finca' , filter: '', visible: this.showTareasFincas},     
        {field: 'cliente', header: 'Cliente' , filter: '', visible: this.hasClients},     
        {field: 'local', header: 'Local' , filter: ''},     
        {field: 'producto', header: 'Producto' , filter: ''},     
        {field: 'num_registro', header: 'Nº registro' , filter: ''},     
        {field: 'materia_activa', header: 'Materia activa' , filter: ''},     
        {field: 'superficie', header: 'Superficie\xa0tratada' , filter: ''},     
        {field: 'motivo', header: 'Motivo\xa0fitosanitario' , filter: ''}     
    ];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },  
        {
            field: 'finca', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}, 
            visible: this.showTareasFincas
        },    
        {
            field: 'local', 
            label: 'Local', 
            inputType: {type: InputType.TEXT}
        },      
        {
            field: 'producto', 
            label: 'Producto', 
            inputType: {type: InputType.TEXT}
        },      
        {
            field: 'superficie_tratada', 
            label: 'Superficie tratada', 
            inputType: {type: InputType.TEXT}
        },      
        {
            field: 'motivo', 
            label: 'Motivo fitosanitario', 
            inputType: {type: InputType.TEXT}
        },
    ];



    constructor(public tareasApi: TareasApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(
            dashboardEvents,
            tareasApi.controlSanitario.GET,
            tareasApi.controlSanitario.DELETE
        );
    }

    ngOnInit() {
        this.dashboardEvents.setSubmenuItem('fito-locales');    
    }

    override ngOnDestroy(){
        Utils.unsuscribe([
            this.tareasApi.fitoLocales.GET
        ]);
    }

    public globalVisualTransform(value: string) {
        return value;
    }

    public globalConditionalStyle(): ColStylesInterface {
        return {};
    }    

    public add() {
        this.router.navigate(['dashboard', 'fito-crear-locales']);
    }
    public edit(data: FitoLocalesModel) {
    
        this.router.navigate(['dashboard', 'fito-editar-locales', data.id]);
    }
    public duplicate(data: FitoLocalesModel) {
        this.router.navigate(['dashboard', 'fito-duplicar-locales', data.id]);
    }    

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: FitoLocalesModel) {
        let x;
        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }  

    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }   

}
