import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { StorageManager } from '../../../../common/storage-manager.class';
import { Filtering } from 'src/app/service/filtering/filtering';
import { BaseView } from 'src/app/view/base-view';
import { environment } from 'src/environments/environment';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { createCsv } from 'src/app/common/createCsv';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { AgroambientalModel } from 'src/app/models/tareas/agroambientales.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-agroambientales',
    standalone: false,
    templateUrl: './agroambientales.component.html',
    styleUrls: ['./agroambientales.component.scss']
})
export class AgroambientalesComponent extends BaseView<AgroambientalModel> {
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public rolUser = StorageManager.getUser().rol;
    public userCanSeeClientes = environment.features.userCanSeeClientes;
    public getRequest = this.tareasApi.agroambientales.GET;
    public filter: Filtering<AgroambientalModel> = new Filtering<AgroambientalModel>();
    public fincaName = environment.features.fincaName;
    public sectorName = environment.features.sectorName;
    public parcelaName = environment.features.parcelaName;

    public deleteRequest: RequestHandler<AgroambientalModel> = this.tareasApi.agroambientales.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;



    public pathToForm = 'tareas-crear-agroambiental';
    public pathToEdit = 'tareas-editar-agroambiental';
    public pathToDuplicate = 'tareas-duplicar-agroambiental';

    public formRequest = new AppFormRequest<AgroambientalModel>();

    public cols: ColInterface[] = [
        { field: 'parcelas', header: this.parcelaName },
        { field: 'fecha_entrada', header: 'Entrada Agua' },
        { field: 'fecha_salida', header: 'Salida Agua' },
        { field: 'fecha_seca_cultivo_agua', header: 'Inicio Inundación' },
        { field: 'fecha_fin_inundacion_invernal', header: 'Fin Inundación' },
        { field: 'fecha_seca_arroz', header: 'Seca Arroz' },
        { field: 'fecha_recoleccion_rastrojo', header: 'Recolección' },
        { field: 'operacion_paja', header: 'Operación' },
        { field: 'fecha_operacion', header: 'Fecha Operación' },
        { field: 'hora_inicio', header: 'Inicio Quema' },
        { field: 'hora_fin', header: 'Fin Quema' },

    ];

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'finca', 
            name: this.fincaName, 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'sector', 
            name: this.sectorName, 
            label: this.sectorName, 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'parcelas', 
            name: this.parcelaName, 
            label: this.parcelaName, 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'fecha_entrada', 
            name: 'Fecha Entrada', 
            label: 'Fecha Entrada', 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'fecha_salida', 
            name: 'Fecha Salida', 
            label: 'Fecha Salida', 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'fecha_seca_cultivo_agua', 
            name: 'Fecha Inundación', 
            label: 'Fecha Inundación', 
            inputType: {type: InputType.TEXT }
        },
        { 
            field: 'fecha_seca_arroz', 
            name: 'Fecha Seca Arroz', 
            label: 'Fecha Seca Arroz', 
            inputType: {type: InputType.TEXT} 
        }
    ];

    public formFieldsRastrojo: ItemInterface<object>[] = [
        { 
            field: 'fecha_recoleccion_rastrojo', 
            name: 'Fecha Recoleccción Rastrojo', 
            label: 'Fecha Recoleccción Rastrojo',
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'operacion_paja', 
            name: 'Operación Paja', 
            label: 'Operación Paja', 
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'fecha_operacion', 
            name: 'Fecha Operación', 
            label: 'Fecha Operación',
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'hora_inicio', 
            name: 'Hora Inicio', 
            label: 'Hora Inicio',
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'hora_fin', 
            name: 'Hora Fin', 
            label: 'Hora Fin',
            inputType: {type: InputType.TEXT} 
        },
        { 
            field: 'horas_totales', 
            name: 'Hora Totales', 
            label: 'Hora Totales',
            inputType: {type: InputType.TEXT},
            visible: this.applicationType !== 'cropgest' 
        },
    ];

    constructor(dashboard: DashboardService,
        private router: Router,
        private tareasApi: TareasApiService){
        super(
            dashboard,
            tareasApi.agroambientales.GET,
            tareasApi.agroambientales.DELETE
        );
    }

    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public edit(data: AgroambientalModel) {
        this.router.navigate(['dashboard', this.pathToEdit , data.id]);
    }

    public duplicate(data: AgroambientalModel) {
        this.router.navigate(['dashboard', this.pathToDuplicate , data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if ( col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }
  
        if (Utils.isNumber(value) && col.field !== 'finca') {
            return {
                textAlign: 'right',
            };
        }
        if (col.field === 'nombre_cuba') {
            return {
                textAlign: 'center',
            };
        }
        if (col.field === 'finca') {
            return {
                textAlign: 'left',
            };
        }
        
        return {};
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsRastrojo
        ];

        const csvContent = createCsv(arrayFormFields, this.filter.value, 'fecha_operacion');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Tareas Agroambientales.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TreeNode<AgroambientalModel>) {
        const agroambiental = row.data as AgroambientalModel;
        if (Utils.isNumber(value) && col.field !== 'finca') {
            // formato decimal y millar
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }

        if (col.field === 'parcelas') {
            const str = Utils.replaceAll(Utils.replaceAll((agroambiental[col.field] || ''), '- ', ''), '\n', '\n, ');
            value = str.substring(0, str.length - 2);
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }
    

        if (col.field === 'sectores_nombres') {
            const str = Utils.replaceAll(Utils.replaceAll((agroambiental[col.field] || '' ), '- ', ''), '\n', '\n, ');
            return str.substring(0, str.length - 2);
        
        }
        return value;
    }

}
