import {  Component } from '@angular/core';
import { Router } from '@angular/router';
import { StorageManager } from '../../../../common/storage-manager.class';
import { FileUploadModule } from 'primeng/fileupload';

import { list, stack } from 'src/app/common/classes/request-builder';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { Utils } from 'src/app/common/utils';



import { DashboardService } from 'src/app/service/events/dashboard.service';
import { Filtering } from 'src/app/service/filtering/filtering';
import { RequestHandler } from 'src/app/service/OffService/request-handler';

import { BaseView } from 'src/app/view/base-view';
import { environment } from 'src/environments/environment';
import { EntidadesApiService } from 'src/app/service/api/entidades-api.service';
import { getBackNameCountry } from 'src/app/common/country';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { EntidadModel } from 'src/app/models/reto/entidad.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-entidades',
    standalone: false,
    templateUrl: './entidades.component.html',
    styleUrls: ['./entidades.component.scss']
})
export class EntidadesComponent extends BaseView<EntidadModel> implements OnInit {
    
    public appName = environment.appName;
    public currentUser = StorageManager.getUser();
    public serverUrl = environment.serverUrl;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
    (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public clienteName = environment.features.clienteName;


    public FileUpload: FileUploadModule | undefined;
    public getRequest: RequestHandler<EntidadModel[]> = this.entidadesApi.EntidadesGET;
    public deleteRequest: RequestHandler<EntidadModel> = this.entidadesApi.EntidadesDELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public menuItemId = 'reto-entidades';
    public filter: Filtering<EntidadModel> = new Filtering<EntidadModel>();


    /* lista de años por defecto */
    public year = list();

    public deleteWarningMessage = '';
    public deleteWarning = '';

    public parcelasAux = null;

    public formFields: ItemInterface<object>[] = [      
        {
            field: 'nombre', 
            label: 'Nombre o Razón Social', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'apellidos', 
            label: 'Apellidos', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'nif', 
            label: 'NIF / CIF', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'pais', 
            label: 'País', 
            inputType: {type: InputType.TEXT},
     
        },
        {
            field: 'provincia_nombre', 
            label: 'Provincia', 
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'localidad', 
            label: 'Localidad (Código INE)', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public formFieldsDireccion: ItemInterface<object>[] = [    
        {
            field: 'cp', 
            label: 'Código postal ', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'direccion', 
            label: 'Dirección ', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public formFieldsDatos: ItemInterface<object>[] = [    
        {
            field: 'email', 
            label: 'Email', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'telefono', 
            label: 'Teléfono', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fax', 
            label: 'Fax', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'roposs', 
            label: 'ROPO SS', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'ropost', 
            label: 'ROPO ST', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'roposu', 
            label: 'ROPO SU', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'caducidadss', 
            label: 'Fecha caducidad SS', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'caducidadst', 
            label: 'Fecha caducidad ST', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'caducidadsu', 
            label: 'Fecha caducidad SU', 
            inputType: {type: InputType.TEXT}
        },
    ];


    public cols: ColInterface[] = [
        { field: 'nombre', header: 'Nombre ' , filter: '', width: '200px' },
        { field: 'provincia_nombre', header: 'Provincia', filter: '' },
        { field: 'localidad', header: 'Localidad (Código INE)', filter: '' },
        { field: 'telefono', header: 'Teléfono', filter: '' },
        { field: 'email', header: 'Email', filter: '', width: '150px' },
    ];
  
    public user = StorageManager.getUser();

    public canAddFincas = environment.features.canAddFincas;


    constructor(
        public entidadesApi: EntidadesApiService,
        public dashboard: DashboardService,
        private router: Router
    ) {
        super(dashboard, entidadesApi.EntidadesGET, entidadesApi.EntidadesDELETE);
   
    }


    async ngOnInit() {
 
    
        this.dashboard.setSubmenuItem(this.menuItemId);

        stack([

        ]).then(() => {
     
            this.filter.filter();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
 
        if (this.appName === 'innovia' && this.currentUser.rol === 'user'){
            this.filter.addFilter((data: TreeNode<EntidadModel>[] | void) => (data || [])
                .filter((it: TreeNode<EntidadModel>) => it)
                .filter((it) => this.currentUser.id_cliente === it.data?.id_cliente)
            );
        }// if();
    // this.filter.addFilter(data => {
    //   return (data || []).filter(finca => finca && finca.id);
    // });
    }


 

 

    public add() {
        /*     if (!StorageManager.getClient() && this.userCanSeeClientes) {
      this.dashboard.openClientDialog();
    } else { */
        this.router.navigate(['dashboard', 'reto-crear-entidades']);
        /*     } */
    }

    public edit(data: EntidadModel) {
        this.router.navigate(['dashboard', 'reto-editar-entidades', data.id]);
    }

    public duplicate(data: EntidadModel) {
        this.router.navigate(['dashboard', 'reto-duplicar-entidades', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            if (this.deleteWarning) {
                this.deleteWarning = '';
                this.deleteWarningMessage = '';

                this.deleteRequest.safePerform(this.model);
                this.deleteRequest.response(() => {
                    if (!this.deleteRequest.hasError) {
                        this.getRequest.safePerform();
                    }
                });
            } else {
                this.deleteWarningMessage =
          'Se borrará toda la información asociada';
                this.deleteWarning = '¡Entidad con datos! ¿Continuar?';
            }
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public globalConditionalStyle(
        value: string, 
        col: ColInterface, 
        _colGroup: ColInterface[], 
        row: TreeNode<EntidadModel>
    ): ColStylesInterface {
        if (Utils.isNumber(value) || col.field === 'telefono') {
            return {
                textAlign: 'center',
                marginRight: '60px'
            };
        }
        if ( col.field === 'provincia' || col.field === 'localidad'){
            return{
                textAlign: 'center'
            };
        }
   
        const entidad = row.data as EntidadModel;
        entidad.pais = (entidad.pais ?  getBackNameCountry(entidad.pais) : '') ?? '';
    
        return {};
    }

    public globalVisualTransform(value: string) {
        return value;  
    }
}

